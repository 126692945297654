import { useCallback } from "react";
import { Flex } from "@chakra-ui/react";
import produce, { Draft } from "immer";

import { JSONSchema7 } from "@svix/common/widgets/JsonSchema/types";

import SchemaExample from "./ExampleEditor";
import SchemaRoot from "./SchemaRoot";
import SchemaToolbar from "./Toolbar";
import { getDefaultSchema } from "./utils";

export interface ISchemaEditorProps {
  onChange: (schema: JSONSchema7 | undefined) => void;
  data: JSONSchema7 | undefined;
}

const DEFAULT_SCHEMA_STATE = Object.freeze(
  getDefaultSchema("object", true)
) as JSONSchema7;

export default function SchemaEditor(props: ISchemaEditorProps) {
  const { data, onChange } = props;
  const schema = data ?? DEFAULT_SCHEMA_STATE;

  const setSchema = useCallback(
    (mutations: (draft: Draft<JSONSchema7>) => void) => {
      const newSchema = produce(schema, mutations);
      onChange(newSchema);
    },
    [onChange, schema]
  );

  return (
    <Flex direction="column" mr="2em">
      <SchemaRoot schema={schema} setSchema={setSchema} />
      <SchemaToolbar clearSchema={() => onChange(undefined)} />
      <SchemaExample schema={schema} setSchema={setSchema} />
    </Flex>
  );
}
