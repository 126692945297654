import { Box, Stack, Skeleton, SkeletonText } from "@chakra-ui/react";

export default function LoadingScreenSkeleton() {
  return (
    <Stack spacing={16} mt={2}>
      <Box>
        <Skeleton width="10em" height="16px" fadeDuration={1} />
        <SkeletonText noOfLines={4} mt={8} />
      </Box>
      <Box>
        <Skeleton width="10em" height="16px" />
        <Skeleton height="20em" mt={8} />
      </Box>
    </Stack>
  );
}
