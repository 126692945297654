import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex } from "@chakra-ui/react";
import { Redirect } from "react-router";
import { Link as RouterLink } from "react-router-dom";

import * as C from "@svix/common/constants";
import { useSearch } from "@svix/common/hooks/search";
import Card from "@svix/common/widgets/Card";
import LoadingIndicator from "@svix/common/widgets/LoadingIndicator";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";

import { getApiConfiguration } from "src/api";
import { routeResolver } from "src/App";
import { SlackApi } from "src/generated/dashboard-openapi";
import { useLoading } from "src/utils";

export default function SlackAppRegistration() {
  const redirectUri = C.getSlackRedirectUri();
  const code = useSearch("code");

  const [slackApp] = useLoading(async () => {
    if (!code) {
      return undefined;
    }
    const config = await getApiConfiguration();
    const slackApi = new SlackApi(config);
    return slackApi.registerSlackAppSlackAppPost({ code, redirectUri });
  }, [code]);

  if (!code || slackApp) {
    return <Redirect to={routeResolver.getRoute("settings.slack")} />;
  }

  return (
    <>
      <MetaTitle path={["Register Slack Notifications"]} />
      <Flex mb={4}>
        <Breadcrumb fontSize="md">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routeResolver.getRoute("settings")}>
              Settings
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routeResolver.getRoute("settings.slack")}>
              Slack
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>Register</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Flex>
      <Card>
        <LoadingIndicator style={{ display: "block", margin: "40px auto" }} />
      </Card>
    </>
  );
}
