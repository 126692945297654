/**
 * Dashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { OrganizationIdAndEnvironment } from './OrganizationIdAndEnvironment';
import { Set } from './Set';
import { HttpFile } from '../http/http';

export class OrganizationGroupRow {
    'createdAt': Date;
    'deleted': boolean;
    'emails': Set<string>;
    'orgs': Array<OrganizationIdAndEnvironment>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "createdAt",
            "baseName": "createdAt",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "deleted",
            "baseName": "deleted",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "emails",
            "baseName": "emails",
            "type": "Set<string>",
            "format": ""
        },
        {
            "name": "orgs",
            "baseName": "orgs",
            "type": "Array<OrganizationIdAndEnvironment>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return OrganizationGroupRow.attributeTypeMap;
    }
    
    public constructor() {
    }
}

