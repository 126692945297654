/**
 * Dashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class QuestionnaireIn {
    'alreadySending': string;
    'defineSuccess': string;
    'howMany': string;
    'mainResponsibility': string;
    'mainResponsibilityOther': string;
    'referredBy': string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "alreadySending",
            "baseName": "alreadySending",
            "type": "string",
            "format": ""
        },
        {
            "name": "defineSuccess",
            "baseName": "defineSuccess",
            "type": "string",
            "format": ""
        },
        {
            "name": "howMany",
            "baseName": "howMany",
            "type": "string",
            "format": ""
        },
        {
            "name": "mainResponsibility",
            "baseName": "mainResponsibility",
            "type": "string",
            "format": ""
        },
        {
            "name": "mainResponsibilityOther",
            "baseName": "mainResponsibilityOther",
            "type": "string",
            "format": ""
        },
        {
            "name": "referredBy",
            "baseName": "referredBy",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return QuestionnaireIn.attributeTypeMap;
    }
    
    public constructor() {
    }
}

