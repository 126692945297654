import { useState } from "react";
import { useBoolean } from "@chakra-ui/hooks";
import {
  Stack,
  Divider,
  Heading,
  Grid,
  Box,
  Flex,
  HStack,
  IconButton,
  Tag,
  Text,
  Th,
  Tbody,
  Thead,
  Tr,
  SkeletonText,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
} from "@chakra-ui/react";
import { Edit, MoreVert } from "@material-ui/icons";
import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

import usePagination from "@svix/common/hooks/pagination";
import { formatDateTime, getAuth0ProviderName } from "@svix/common/utils";
import Card from "@svix/common/widgets/Card";
import ConfirmationDialog from "@svix/common/widgets/ConfirmationDialog";
import LoadingScreenSkeleton from "@svix/common/widgets/LoadingScreenSkeleton";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import Mono from "@svix/common/widgets/Mono";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
} from "@svix/common/widgets/PageToolbar";
import ResourceNotFound from "@svix/common/widgets/ResourceNotFound";
import SSOAccountTag from "@svix/common/widgets/SSOAccountTag";
import Stat from "@svix/common/widgets/Stat";
import Table from "@svix/common/widgets/Table";
import TableCell from "@svix/common/widgets/TableCell";

import { getApiConfiguration } from "src/api";
import { routeResolver } from "src/App";
import {
  OrganizationApi,
  OrganizationRole,
  OrganizationRoleOverride,
  OrganizationUser,
} from "src/generated/dashboard-openapi";
import { useAppSelector, useAppDispatch } from "src/hooks/store";
import { pushError } from "src/store/errors";
import { useIsAdmin } from "src/store/selectors";
import RoleTag from "./RoleTag";
import UpdateRoleModal, { UpdateRoleModalSelection } from "./UpdateRoleModal";

export default function MemberScreen() {
  const { userId } = useParams<{ userId: string }>();
  const loggedInUserId = useAppSelector((state) => state.auth.userId);
  const toast = useToast();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const isAdmin = useIsAdmin();
  const [showRemoveMember, setShowRemoveMember] = useBoolean();
  const [showUpdateRole, setShowUpdateRole] = useBoolean();
  const history = useHistory();

  const {
    data: member,
    error,
    isLoading,
  } = useQuery<OrganizationUser | undefined>(["members", userId], async () => {
    const config = await getApiConfiguration();
    const orgApi = new OrganizationApi(config);
    return orgApi.getUserOrganizationUsersUserIdGet(userId);
  });

  const [overrides, overridesCtx] = usePagination(
    ["members", userId, "overrides"],
    async () => {
      const config = await getApiConfiguration();
      const orgApi = new OrganizationApi(config);
      return orgApi.listOverridesOrganizationRoleUserIdOverridesGet(userId);
    }
  );

  const [updateRoleOpen, setUpdateRoleOpen] = useState<OrganizationRoleOverride | null>(
    null
  );

  const orgGroupRole = member?.orgGroupRole ?? ("Member" as OrganizationRole);
  const provider = member ? getAuth0ProviderName(member.providerUserId) : "Unknown";

  const settingsRoute = routeResolver.getRoute("settings");
  const membersRoute = routeResolver.getRoute("settings.organizationGroup.members");

  async function revokeAccess() {
    const api = new OrganizationApi(await getApiConfiguration());

    try {
      await api.revokeAccessOrganizationUsersRevokeAccessUserIdPost(userId);
      toast({
        title: "User removed from organization",
        description: member?.email,
        status: "info",
        duration: 3000,
        isClosable: true,
      });
      queryClient.invalidateQueries("members");
      setShowRemoveMember.off();
      history.push(membersRoute);
    } catch (error) {
      dispatch(pushError(error));
    }
  }

  async function roleOverrideChanged(role: UpdateRoleModalSelection) {
    const envId = updateRoleOpen?.orgId;
    if (!envId || !member) {
      return;
    }

    const config = await getApiConfiguration();
    const orgApi = new OrganizationApi(config);

    if (role === "Default") {
      await orgApi.deleteOverrideOrganizationRoleUserIdOverridesEnvIdDelete(
        envId,
        userId
      );
    } else {
      // FIXME - throwing an exception here doesn't do the right thing
      await orgApi.updateOverrideOrganizationRoleUserIdOverridesEnvIdPost(envId, userId, {
        role: role,
      });
    }
    setUpdateRoleOpen(null);
    overridesCtx.refetch();
  }

  if (error) {
    return <ResourceNotFound resourceName="member" to={membersRoute} />;
  }

  const isCurrentUser = member && member.userId === loggedInUserId;

  return (
    <>
      {(isLoading || !member) && <LoadingScreenSkeleton />}
      {member && (
        <>
          <MetaTitle path={["Organization Member View"]} />
          <PageToolbar>
            <Breadcrumbs>
              <BreadcrumbItem to={settingsRoute}>Settings</BreadcrumbItem>
              <BreadcrumbItem to={membersRoute}>Organization Members</BreadcrumbItem>
              <BreadcrumbItem>
                <Mono>{member.email}</Mono>
              </BreadcrumbItem>
            </Breadcrumbs>
            <Flex flexGrow={1} />
            {isAdmin && (
              <Box>
                <Menu placement="bottom-end">
                  <MenuButton as={IconButton} variant="rounded">
                    <MoreVert />
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={setShowUpdateRole.on}>
                      Change default role...
                    </MenuItem>
                    <Divider />
                    <MenuItem color="text.danger" onClick={setShowRemoveMember.on}>
                      Remove from organization
                    </MenuItem>

                    <UpdateRoleModal
                      member={member}
                      isOpen={showUpdateRole}
                      onClose={setShowUpdateRole.off}
                      title="Update Default Organization Role"
                    />
                    <ConfirmationDialog
                      title="Remove member from organization?"
                      isOpen={showRemoveMember}
                      colorScheme="red"
                      onCancel={setShowRemoveMember.off}
                      onOk={revokeAccess}
                      labelOk="Remove"
                    >
                      <Text>
                        Are you sure you would want to remove{" "}
                        <strong>{member.email}</strong> from your organization?
                      </Text>
                    </ConfirmationDialog>
                  </MenuList>
                </Menu>
              </Box>
            )}
          </PageToolbar>
          <Flex flexDirection="row" alignItems="center" mt={2}>
            <Heading as="h1" size="md" isTruncated>
              Member {member.email}
            </Heading>
            {isCurrentUser && (
              <Tag size="sm" ml={2} colorScheme="blue">
                You
              </Tag>
            )}
            <Box ml={2}>
              <SSOAccountTag provider={provider} />
            </Box>
          </Flex>
          <Grid
            gridTemplateColumns={{
              sm: "minmax(0, 1fr)",
              md: "minmax(0, 3fr) minmax(240px, 1fr)",
            }}
            gap={8}
            mt={6}
          >
            <Box mt={2}>
              <Card mb={8}>
                <HStack mb={1}>
                  <Text fontWeight="semibold" size="md">
                    Default Organization Role:
                  </Text>
                  <RoleTag role={orgGroupRole} inherit={false} />
                </HStack>
                <Text variant="caption">
                  This will be the default role for the user in new environments, unless
                  specified otherwise.
                </Text>
              </Card>
              <Heading as="h2" size="sm" mb={1}>
                User roles
              </Heading>
              <Text variant="caption" mb={4} mt={0}>
                Change the user access level in each environment.
              </Text>
              <Table
                response={overrides}
                requestElems={overridesCtx}
                showPagination={false}
              >
                <Thead>
                  <Tr>
                    <Th>Environment</Th>
                    <Th>Environment ID</Th>
                    <Th textAlign="right">Role</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {overrides?.data.map((override: OrganizationRoleOverride) => (
                    <Tr key={override.orgId}>
                      <TableCell>{override.orgName}</TableCell>
                      <TableCell>
                        <Mono>{override.orgId}</Mono>
                      </TableCell>
                      <TableCell>
                        <Flex w="100%" justifyContent="end" alignItems="center">
                          <RoleTag
                            role={override.role ?? orgGroupRole}
                            inherit={!override.role}
                          />
                          {!isCurrentUser && orgGroupRole !== "Admin" && (
                            <IconButton
                              aria-label="Edit"
                              variant="outline"
                              size="sm"
                              onClick={() => setUpdateRoleOpen(override)}
                              icon={<Edit />}
                              ml={1}
                              title="Edit role"
                            />
                          )}
                        </Flex>
                      </TableCell>
                    </Tr>
                  ))}
                  {overridesCtx.isLoading && (
                    <Tr key="loading">
                      <TableCell>
                        <SkeletonText noOfLines={1} />
                      </TableCell>
                      <TableCell>
                        <SkeletonText noOfLines={1} />
                      </TableCell>
                      <TableCell />
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </Box>

            <UpdateRoleModal
              orgName={updateRoleOpen?.orgName}
              member={member}
              isOpen={updateRoleOpen != null}
              onClose={() => setUpdateRoleOpen(null)}
              defaultRole={updateRoleOpen?.role || "Default"}
              updateRole={roleOverrideChanged}
              // Note - we don't allow overriding to 'Admin' currently,
              // as this would give the user permission in the org group
              showRoles={["Default", "NoAccess", "Viewer", "Member"]}
            />

            <Stack spacing={4} mb={6}>
              <Stat name="Invited On">{formatDateTime(member.createdAt)}</Stat>
              <Divider />
              <Stat name="Last Updated">{formatDateTime(member.updatedAt)}</Stat>
              <Divider />
              <Stat name="User ID">
                <Mono>{member.userId}</Mono>
              </Stat>
            </Stack>
          </Grid>
        </>
      )}
    </>
  );
}
