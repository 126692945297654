import { useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

import { setErrors } from "@svix/common/formUtils";
import Form, { GeneralFormErrors } from "@svix/common/widgets/Form";
import TextField from "@svix/common/widgets/form/TextField";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getApiConfiguration, getEEAdminApiConfiguration } from "src/api";
import {
  OrganizationEnvironment,
  OrganizationApi,
  OrganizationOut,
  OrganizationEEApi,
} from "src/generated/dashboard-openapi";
import { OrganizationIn } from "src/generated/dashboard-openapi/models/OrganizationIn";
import { OrganizationRegion } from "src/generated/dashboard-openapi/models/OrganizationRegion";
import { useAppDispatch, useAppSelector } from "src/hooks/store";
import { refreshLoginInfo } from "src/store/auth";
import { isEE } from "src/utils";
import EnvTagToggle from "./EnvTagRadio";
import RegionRadio from "./RegionRadio";
import { trackEvent } from "../../analytics";

const defaultValues = {
  orgName: "",
  orgEnv: "Test" as OrganizationEnvironment,
  region: "eu" as OrganizationRegion,
};

interface IAddEnvironmentModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAddEnvironment?: (env: OrganizationOut) => void;
}
export default function AddEnvironmentModal(props: IAddEnvironmentModalProps) {
  const { isOpen, onClose, onAddEnvironment } = props;
  const toast = useToast();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const hasMultiregionFeatureFlag = useAppSelector((state) =>
    state.auth.features.has("MultiRegion")
  );

  const formCtx = useForm({ defaultValues });
  const { control, reset, formState } = formCtx;

  useEffect(() => {
    if (isOpen) {
      reset(defaultValues);
    }
  }, [isOpen, reset]);

  async function onSubmit(form: OrganizationIn) {
    try {
      // FIXME: unify once the /organization endpoint is fully migrated to Rust
      let env;
      if (isEE) {
        const api = new OrganizationEEApi(await getEEAdminApiConfiguration());
        env = await api.createOrgEeOrganizationPost(form);
      } else {
        const region = form.region ?? "eu";
        const api = new OrganizationApi(await getApiConfiguration({ region }));
        env = await api.createOrgOrganizationPost(form);
      }

      toast({
        title: "New environment created",
        description: `${env.orgId}`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      queryClient.invalidateQueries("environments");
      refreshLoginInfo(dispatch);
      trackEvent("Create Environment");
      onClose();
      if (onAddEnvironment) {
        onAddEnvironment(env);
      }
    } catch (e) {
      setErrors(formCtx.setError, e.body);
    }
  }

  return (
    <Modal size="lg" onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent borderRadius="lg">
        <ModalHeader onClose={onClose}>New Environment</ModalHeader>
        <Form onSubmit={onSubmit} {...formCtx}>
          <ModalBody>
            <ModalCloseButton />
            <GeneralFormErrors />
            <Stack spacing={6}>
              <TextField
                control={control}
                name="orgName"
                label="Name"
                autoFocus
                required
                placeholder="e.g. Staging"
              />
              {hasMultiregionFeatureFlag && (
                <RegionRadio control={control} name="region" />
              )}
              <EnvTagToggle control={control} name="orgEnv" />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <SubmitButton isLoading={formState.isSubmitting}>Create</SubmitButton>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  );
}
