import { Tooltip, forwardRef, Tag, TagProps } from "@chakra-ui/react";

import { OrganizationRole } from "src/generated/dashboard-openapi";
import { ROLE_DESCRIPTION } from "./RoleToggle";

interface RoleTagProps {
  role: OrganizationRole;
  inherit: boolean;
}

const RoleTag = forwardRef<TagProps & RoleTagProps, "div">((props, ref) => {
  const { role, inherit = false, ...rest } = props;
  const name = roleNames[role as OrganizationRole];
  return (
    <Tooltip label={ROLE_DESCRIPTION[role as OrganizationRole]} placement="bottom">
      <Tag ref={ref} {...rest} fontWeight="semibold">
        {inherit ? `Default (${name})` : name}
      </Tag>
    </Tooltip>
  );
});

const roleNames = {
  Admin: "Admin",
  Member: "Member",
  Viewer: "Viewer",
  NoAccess: "No Access",
};

export default RoleTag;
