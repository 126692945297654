import { Box, Heading, Text, Code, Link } from "@chakra-ui/react";

import Card from "@svix/common/widgets/Card";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
} from "@svix/common/widgets/PageToolbar";

import { routeResolver } from "src/App";
import { useAppSelector } from "src/hooks/store";
import ProjectEnvironmentMapper from "./ProjectEnvironmentMapper";

export default function VercelConfigureScreen() {
  const environments = useAppSelector((state) => state.auth.environments);

  return (
    <>
      <MetaTitle path={["Vercel Integration"]} />
      <PageToolbar>
        <Breadcrumbs>
          <BreadcrumbItem to={routeResolver.getRoute("integrations")}>
            Integrations
          </BreadcrumbItem>
          <BreadcrumbItem>Vercel</BreadcrumbItem>
        </Breadcrumbs>
      </PageToolbar>

      <Heading as="h1" mt={2} size="lg" mb={8}>
        Vercel Integration
      </Heading>

      <Card title="Configure" maxW="60em">
        <Text>
          Map your Vercel Projects to Svix Environments. Svix will automatically configure
          your Vercel project with the <Code>SVIX_API_KEY</Code> and{" "}
          <Code>SVIX_ENV_ID</Code> environment variables. You can create additional Svix
          environments from the{" "}
          <Link href={routeResolver.getRoute("environments")} color="interactive.accent">
            Manage Environments
          </Link>{" "}
          page.
        </Text>

        <Box mt="2em" mb="1em">
          <ProjectEnvironmentMapper environments={environments} />
        </Box>
      </Card>
    </>
  );
}
