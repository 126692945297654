import { useToast } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { Redirect, useHistory, useLocation } from "react-router";

import LoadingIndicator from "@svix/common/widgets/LoadingIndicator";

import { getApiConfiguration } from "src/api";
import { routeResolver } from "src/App";
import { InvitationApi } from "src/generated/dashboard-openapi";
import { useAppSelector } from "src/hooks/store";
import JoinOrganizationModal from "./JoinOrganizationModal";

export default function OrganizationInviteScreen() {
  const { replace } = useHistory();
  const { hash } = useLocation();
  const toast = useToast();
  const inviteToken = hash.slice(1);
  const activeEnvId = useAppSelector((app) => app.auth.activeEnvId);

  const { data: incomingInvite, isLoading } = useQuery(
    ["incomingInvites", inviteToken],
    async () => {
      if (!inviteToken) {
        return null;
      }
      const config = await getApiConfiguration();
      const api = new InvitationApi(config);

      try {
        const invite = await api.findInviteByTokenInviteFindPost({
          token: inviteToken,
        });

        if (invite.status === "Accepted" || invite.status === "Denied") {
          toast({
            title: "Invite already used",
            description: `This invite has already been ${
              invite.status === "Accepted" ? "accepted" : "denied"
            }.`,
            status: "warning",
            duration: 5000,
            isClosable: true,
          });
        }

        return invite;
      } catch (e) {
        toast({
          title: "Invite not found",
          description: "The invite is invalid or has expired. Check your invite link.",
          status: "warning",
          duration: 5000,
          isClosable: true,
        });
        return null;
      }
    }
  );

  // When the user accepts an invite, the activeEnv will be cleared until the page reloads and gets the new org group.
  if (isLoading || !activeEnvId) {
    return <LoadingIndicator style={{ display: "block", margin: "40px auto" }} />;
  }

  if (inviteToken && incomingInvite?.status === "Pending") {
    return (
      <JoinOrganizationModal
        token={inviteToken}
        invite={incomingInvite}
        isOpen
        onClose={() => replace(routeResolver.getRoute("home"))}
      />
    );
  }

  return <Redirect to={routeResolver.getRoute("home")} />;
}
