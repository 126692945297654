import { Analytics } from "@segment/analytics-next";

import { logError } from "@svix/common/logger";

declare global {
  interface Window {
    analytics: Analytics | undefined;
  }
}

export function trackEvent(event: string, properties?: object) {
  try {
    window.analytics!.track(event, properties);
  } catch (e) {
    logError(e);
  }
}
