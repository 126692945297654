import { createTransform } from "redux-persist";

import { IAuth } from "./auth";

export const TransformFeaturesSet = createTransform(
  (inboundState: IAuth) =>
    ({
      ...inboundState,
      features: Array.from(inboundState.features),
    } as any),
  (outboundState: IAuth) => ({
    ...outboundState,
    isLoggedIn: false,
    features: new Set(outboundState.features),
  }),
  { whitelist: ["auth"] }
);

export const TransformFeaturesSetEE = createTransform(
  (inboundState: IAuth) =>
    ({
      ...inboundState,
      features: Array.from(inboundState.features),
    } as any),
  (outboundState: IAuth) => ({
    ...outboundState,
    features: new Set(outboundState.features),
  }),
  { whitelist: ["auth"] }
);
