import {
  Fade,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  Text,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

import { useSearch, setSearch } from "@svix/common/hooks/search";

import { routeResolver } from "src/App";
import { useAppSelector } from "src/hooks/store";
import NewSignupForm from "./NewSignupForm";
import OnboardingTasksDialog from "./OnboardingTasksDialog";
import WelcomeQuestionnaire from "./WelcomeQuestionnaire";

const OnboardingModal = () => {
  const signupStep = useSearch("signup-step");
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  const history = useHistory();

  if (!isLoggedIn || !signupStep) {
    return null;
  }

  return (
    <Modal isOpen onClose={() => setSearch({ "signup-step": "" })} size="xl">
      <ModalOverlay backdropFilter="auto" backdropBlur="2px" />
      <ModalContent borderRadius="lg">
        {/* FIXME: this is repeated in the OnboardingTasksDialog. Needs a small refactor. */}
        {signupStep !== "onboarding" && (
          <ModalHeader
            bg="blue.500"
            borderTopRadius="lg"
            color="white"
            pt="4em"
            pb="2em"
            fontSize="3xl"
          >
            <Text fontSize="3xl">Welcome to Svix</Text>
            <Text fontSize="lg">
              We need to know a few things from you before we get started.
            </Text>
          </ModalHeader>
        )}
        {signupStep === "newsignup" && (
          <Fade in>
            <NewSignupForm
              onNext={() => {
                history.push({
                  pathname: routeResolver.getRoute("getting-started"),
                  search: "signup-step=questionnaire",
                });
              }}
            />
          </Fade>
        )}
        {signupStep === "questionnaire" && (
          <Fade in>
            <WelcomeQuestionnaire
              onClose={() => {
                history.push({
                  pathname: routeResolver.getRoute("getting-started"),
                  search: "signup-step=onboarding",
                });
              }}
            />
          </Fade>
        )}
        {signupStep === "onboarding" && <OnboardingTasksDialog />}
      </ModalContent>
    </Modal>
  );
};

export default OnboardingModal;
