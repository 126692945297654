import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { PreloadedState } from "redux";
import logger from "redux-logger";
import { persistStore } from "redux-persist";
import persistReducer from "redux-persist/es/persistReducer";
import localStorage from "redux-persist/lib/storage";
import sessionStorage from "redux-persist/lib/storage/session";

import auth from "./auth";
import errors from "./errors";
import featureFlags from "./featureFlags";
import { TransformFeaturesSet, TransformFeaturesSetEE } from "./persistTransformers";
import settings from "./settings";

const isEE = import.meta.env.MODE === "ee";

const persistConfig = {
  key: "root",
  whitelist: ["auth", "featureFlags", "settings"],
  storage: isEE ? sessionStorage : localStorage,
  transforms: isEE ? [TransformFeaturesSetEE] : [TransformFeaturesSet],
};

const rootReducer = combineReducers({
  auth,
  errors,
  featureFlags,
  settings,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Export this to set up test store in tests
export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => {
      let middleware = getDefaultMiddleware({
        serializableCheck: false,
      });
      if (import.meta.env.NODE_ENV === "development") {
        middleware = middleware.concat(logger);
      }
      return middleware;
    },
    preloadedState,
  });
};

export const store = setupStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = ReturnType<typeof setupStore>;

export const persistor = persistStore(store);
