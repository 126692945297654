import { Box, Tag, Text, Tooltip, useBoolean } from "@chakra-ui/react";
import { Info } from "@material-ui/icons";
import { useQuery } from "react-query";
import { useParams } from "react-router";

import Button from "@svix/common/widgets/Button";
import Stat from "@svix/common/widgets/Stat";

import { getApiConfiguration } from "src/api";
import { StatisticsApi as DashboardStatisticsApi } from "src/generated/dashboard-openapi";
import { useAppSelector } from "src/hooks/store";

const NUM_DEFAULT_VISIBLE_ITEMS = 3;

export default function SubscribedEventTypes() {
  const activeEnvId = useAppSelector((store) => store.auth.activeEnvId) || "";
  const { appId } = useParams<{ appId: string }>();
  const [showAllEvents, setShowAllEvents] = useBoolean();

  const { data: eventTypes } = useQuery(
    ["enviornments", activeEnvId, appId, "eventTypes"],
    async () => {
      const config = await getApiConfiguration();
      const api = new DashboardStatisticsApi(config);
      return api.getAppEventTypesStatsAppAppIdEventTypesGet(appId);
    }
  );

  return (
    <Stat
      name={
        <Tooltip label="At least one endpoint is listening for the following events">
          <Box as="span">
            Subscribed Event Types
            <Info
              style={{
                marginBottom: "0.2rem",
                marginLeft: "0.4rem",
                fontSize: "1rem",
                opacity: 0.5,
              }}
            />
          </Box>
        </Tooltip>
      }
    >
      {eventTypes && (
        <Box>
          {eventTypes.hasCatchAllEndpoint ? (
            <Box mt={2}>
              <Tag>All event types</Tag>
            </Box>
          ) : (
            <>
              {eventTypes.explicitlySubscribedEventTypes
                .sort()
                .slice(0, showAllEvents ? undefined : NUM_DEFAULT_VISIBLE_ITEMS)
                .map((eventType) => (
                  <Box display="flex" alignItems="baseline" key={eventType}>
                    {eventType}
                  </Box>
                ))}
              {!showAllEvents &&
                eventTypes.explicitlySubscribedEventTypes.length >
                  NUM_DEFAULT_VISIBLE_ITEMS && (
                  <Button
                    size="xs"
                    colorScheme="blue"
                    variant="ghost"
                    onClick={setShowAllEvents.on}
                  >
                    +{" "}
                    {eventTypes.explicitlySubscribedEventTypes.length -
                      NUM_DEFAULT_VISIBLE_ITEMS}{" "}
                    More
                  </Button>
                )}
            </>
          )}
        </Box>
      )}
      {eventTypes &&
        !eventTypes.hasCatchAllEndpoint &&
        !eventTypes.explicitlySubscribedEventTypes.length && (
          <Text variant="caption">This app is not listening for any events</Text>
        )}
    </Stat>
  );
}
