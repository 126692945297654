import { Tag, Tooltip } from "@chakra-ui/react";

import { Auth0ProviderName } from "utils";

interface ISSOAccountTagProps {
  provider: Auth0ProviderName;
}

export default function SSOAccountTag({ provider }: ISSOAccountTagProps) {
  if (["Email", "Unknown"].includes(provider)) {
    return <></>;
  }

  return (
    <Tooltip label={`Using Single Sign-On with ${provider}`}>
      <Tag size="sm" variant="outline">
        {provider}
      </Tag>
    </Tooltip>
  );
}
