import { useEffect } from "react";
import {
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  Modal,
  ModalBody,
  Text,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  useDisclosure,
} from "@chakra-ui/react";
import BrushIcon from "@material-ui/icons/Brush";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { TransformationTemplateKind, TransformationTemplateApi } from "svix/dist/openapi";

import TextAreaField from "@svix/common/widgets/form/TextArea";

import { getSvix } from "src/api";

interface AIGenerateButtonProps {
  templateType: TransformationTemplateKind;
  eventTypes: string[];
  onGenerate: (code: string) => void;
}

interface AIFormProps {
  prompt: string;
}

function AIModalButton({ templateType, eventTypes, onGenerate }: AIGenerateButtonProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const formCtx = useForm<AIFormProps>({
    defaultValues: {
      prompt: getTransformationPrompt(templateType),
    },
  });
  const { control, watch, setValue } = formCtx;
  const prompt = watch("prompt");

  const generateMutation = useMutation(async () => {
    const svix = await getSvix();
    const api = new TransformationTemplateApi(svix._configuration);
    const res = await api.v1TransformationTemplateGenerate({
      generateIn: {
        prompt: getFinalPrompt(prompt, eventTypes),
      },
    });
    onGenerate(res.choices[0]?.message?.content);
    onClose();
  });

  useEffect(() => {
    setValue("prompt", getTransformationPrompt(templateType));
  }, [templateType, setValue]);

  return (
    <>
      <Button size="sm" onClick={onOpen} w="fit-content" rightIcon={<BrushIcon />}>
        Generate with AI
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <HStack>
              <Text>Write transformation using AI</Text>
              <Tag variant="subtle" colorScheme="cyan">
                Beta
              </Tag>
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isInvalid={generateMutation.isError}>
              <TextAreaField
                label="Create a transformation that..."
                placeholder="What do you want the transformation to do?"
                name="prompt"
                control={control}
                isDisabled={generateMutation.isLoading}
              />
              <Text mt={2} variant="caption">
                This is an experimental feature. AI can hallucinate and create invalid
                information. Make sure to test the generated code.
              </Text>
              <FormErrorMessage>
                Could not generate transformation. Please try again later.
              </FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="brand"
              rightIcon={<BrushIcon />}
              onClick={() => generateMutation.mutate()}
              isLoading={generateMutation.isLoading}
              loadingText="Generating..."
            >
              Generate
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

const getTransformationPrompt = (templateType: TransformationTemplateKind) => {
  switch (templateType) {
    case "Slack":
      return `Transforms the payload for Slack's incoming webhook API. Use advanced formatting if necessary.`;
    case "Discord":
      return `Transforms the payload for Discord's incoming webhook API. Use advanced formatting if necessary.`;
    case "Teams":
      return `Transforms the payload for Microsoft Teams's incoming webhook API. Use advanced formatting if necessary.`;
    case "Hubspot":
      return `Transforms the payload to connect to Hubspot's CRM API.`;
    default:
      return "";
  }
};

const getFinalPrompt = (prompt: string, eventTypes: string[]) => {
  if (eventTypes.length === 0) {
    return prompt;
  }

  return `${prompt}. The function may receive the following event types: ${eventTypes.join(
    ", "
  )}. Make sure you use understand what each event type means.`;
};

export default AIModalButton;
