import { useState } from "react";
import { Alert, Box, Text } from "@chakra-ui/react";

import Button from "@svix/common/widgets/Button";
import ConfirmationDialog from "@svix/common/widgets/ConfirmationDialog";

import { getApiConfiguration } from "src/api";
import { AuthenticationApi } from "src/generated/dashboard-openapi";
import { prettyError } from "src/utils";

interface FormErrors {
  general?: string;
}

export default function ChangePassword() {
  const [errors, setErrors] = useState<FormErrors>({});
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);

  async function resetUserPassword() {
    try {
      const config = await getApiConfiguration();
      const authApi = new AuthenticationApi(config);
      await authApi.changePasswordAuthenticationResetPasswordPost();
      setChangePasswordOpen(false);
    } catch (e) {
      setErrors({ general: prettyError(e) });
    }
  }

  return (
    <>
      <Button variant="outline" size="sm" onClick={() => setChangePasswordOpen(true)}>
        Change Password
      </Button>
      <ConfirmationDialog
        title="Change Password"
        isOpen={changePasswordOpen}
        onCancel={() => setChangePasswordOpen(false)}
        onOk={resetUserPassword}
        labelOk="Reset Password"
      >
        <Text>To change your password, please click "Reset Password" below.</Text>
        <Text mt={2}>You will receive an email with password reset instructions.</Text>
        {errors.general && (
          <Box mt={2}>
            <Alert status="error">{errors.general}</Alert>
          </Box>
        )}
      </ConfirmationDialog>
    </>
  );
}
