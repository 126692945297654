import { Input, FormControl, FormLabel, Stack, Select } from "@chakra-ui/react";
import { Draft } from "immer";

import { JSONSchema7 } from "@svix/common/widgets/JsonSchema/types";

import { DefaultValueField, DescriptionField, MinMaxLengthFields } from "../Fields";
import { STRING_FORMATS } from "../utils";

interface StringFieldSettingsProps {
  schema: JSONSchema7;
  setSchema: (mutations: (draft: Draft<JSONSchema7>) => void) => void;
}

export default function StringFieldSettings(props: StringFieldSettingsProps) {
  const { setSchema, schema } = props;

  return (
    <Stack spacing={5}>
      <FormControl>
        <FormLabel>Format</FormLabel>
        <Select
          variant="filled"
          value={schema.format}
          size="sm"
          margin={2}
          placeholder="Choose format"
          onChange={(evt) => {
            setSchema((draft) => {
              draft.format = evt.target.value;
            });
          }}
        >
          {STRING_FORMATS.map((item, index) => {
            return (
              <option key={String(index)} value={item.name}>
                {item.name}
              </option>
            );
          })}
        </Select>
      </FormControl>
      <DescriptionField {...props} />
      <DefaultValueField {...props} />
      <MinMaxLengthFields {...props} />
      <FormControl>
        <FormLabel>Pattern</FormLabel>
        <Input
          id="default"
          variant="filled"
          placeholder="e.g. '.*'"
          value={String(schema.pattern || "")}
          onChange={(evt) => {
            setSchema((draft) => {
              draft.pattern = evt.target.value;
            });
          }}
        />
      </FormControl>
    </Stack>
  );
}
