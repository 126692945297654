import { Text } from "@chakra-ui/react";
import BuildIcon from "@material-ui/icons/Build";
import { useQuery } from "react-query";

import Link from "@svix/common/widgets/Link";

import { getSvix } from "src/api";
import { routeResolver } from "src/App";
import { useAppSelector } from "src/hooks/store";
import GettingStartedCard from "../GettingStartedCard";

export default function DevEnvCard() {
  const activeEnvId = useAppSelector((store) => store.auth.activeEnvId) || "";
  const { data: applications } = useQuery(
    ["environments", activeEnvId, "applications", "gettingStarted"],
    async () => {
      const api = await getSvix();
      return api.application.list({ limit: 1 });
    }
  );

  const hasApp = applications?.data && applications.data.length > 0;

  return (
    <GettingStartedCard
      done={hasApp}
      icon={<BuildIcon style={{ fontSize: 24 }} />}
      title="Set up your development environment"
      cta={<Link to={routeResolver.getRoute("applications")}>Go to Applications</Link>}
    >
      <Text variant="caption">
        Connect your team to the Svix development environment to test your full integrated
        flow before it's time to go live.
      </Text>
    </GettingStartedCard>
  );
}
