import {
  Box,
  BoxProps,
  Breadcrumb,
  BreadcrumbItem as BaseBreadcrumbItem,
  BreadcrumbItemProps,
  BreadcrumbLink,
  BreadcrumbProps,
  Flex,
  Heading,
  HeadingProps,
  Tooltip,
  Text,
} from "@chakra-ui/react";
import { ChevronRight } from "@material-ui/icons";
import { Link } from "react-router-dom";

import Mono from "./Mono";
import useCopyToClipboard from "../hooks/copyToClipboard";
import { humanize } from "../utils";

type BreadcrumbItemWithId = BreadcrumbItemProps & {
  label?: string;
  identifier: string;
};

export function BreadcrumbItemWithId({
  children,
  label,
  identifier,
  ...rest
}: BreadcrumbItemWithId) {
  const [copied, copyToClipboard] = useCopyToClipboard(identifier);

  return (
    <BreadcrumbItem {...rest}>
      <Tooltip
        aria-label="Click to copy app ID"
        closeOnClick={false}
        label={
          <Box textAlign="center">
            <Mono as="div" mb={1}>
              {label ?? identifier}
            </Mono>
            <Text as="div" fontSize="xs">
              {copied ? "Copied!" : "Click to copy"}
            </Text>
          </Box>
        }
      >
        <Mono
          onClick={copyToClipboard}
          textDecoration="underline"
          textDecorationStyle="dotted"
          textUnderlineOffset="0.2em"
        >
          {humanize(identifier)}
        </Mono>
      </Tooltip>
    </BreadcrumbItem>
  );
}

type IBreadcrumbItemProps = BreadcrumbItemProps & { to?: string };

export function BreadcrumbItem({ to, children, ...rest }: IBreadcrumbItemProps) {
  return (
    <BaseBreadcrumbItem {...rest}>
      <BreadcrumbLink as={Link} to={to ?? "#"} isCurrentPage={!to}>
        {children}
      </BreadcrumbLink>
    </BaseBreadcrumbItem>
  );
}

type IBreadcrumbsProps = BreadcrumbProps & { title?: string };

export function Breadcrumbs(props: IBreadcrumbsProps) {
  const { children, title } = props;
  return (
    <>
      <Flex alignItems="center" color="text.heading" minH={10}>
        <Breadcrumb
          spacing={1}
          fontSize="md"
          separator={<ChevronRight fontSize="inherit" />}
          sx={{
            svg: {
              fill: "text.muted",
            },
          }}
        >
          {children}
        </Breadcrumb>
      </Flex>
      <Box display="flex" my={2}>
        <Heading as="h1" size="md" isTruncated>
          {title}
        </Heading>
      </Box>
    </>
  );
}

export function PageTitle({ children, ...rest }: HeadingProps) {
  return (
    <Box display="flex" my={2}>
      <Heading as="h1" size="md" isTruncated {...rest}>
        {children}
      </Heading>
    </Box>
  );
}

export function PageToolbar(props: BoxProps) {
  const { children } = props;
  return (
    <Flex alignItems="center" mb={4}>
      {children}
    </Flex>
  );
}
