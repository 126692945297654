import { FormLabel, Stack, FormControl, Radio, RadioGroup } from "@chakra-ui/react";
import { Draft } from "immer";

import { JSONSchema7 } from "@svix/common/widgets/JsonSchema/types";

import { DescriptionField } from "../Fields";

interface IArrayFieldSettingsProps {
  schema: JSONSchema7;
  setSchema: (mutations: (draft: Draft<JSONSchema7>) => void) => void;
}

export default function BooleanFieldSettings(props: IArrayFieldSettingsProps) {
  const { schema, setSchema } = props;

  let strValue = "none";
  if (schema.default !== undefined) {
    strValue = String(schema.default);
  }

  return (
    <Stack spacing={5}>
      <DescriptionField {...props} />
      <FormControl>
        <FormLabel>Default value</FormLabel>
        <RadioGroup
          onChange={(value) => {
            setSchema((draft) => {
              if (value === "none") {
                draft.default = undefined;
              } else {
                draft.default = value === "true";
              }
            });
          }}
          value={strValue}
        >
          <Stack dir="column">
            <Radio value="none">No default</Radio>
            <Radio value="false">False</Radio>
            <Radio value="true">True</Radio>
          </Stack>
        </RadioGroup>
      </FormControl>
    </Stack>
  );
}
