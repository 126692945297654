import * as React from "react";
import {
  FormControl,
  FormLabel,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Textarea,
} from "@chakra-ui/react";
import { Draft } from "immer";

import { JSONSchema7 } from "@svix/common/widgets/JsonSchema/types";

interface IFieldProps {
  schema: JSONSchema7;
  setSchema: (mutations: (draft: Draft<JSONSchema7>) => void) => void;
}

export function DescriptionField(props: IFieldProps) {
  const { schema, setSchema } = props;

  return (
    <FormControl>
      <FormLabel>Description</FormLabel>
      <Textarea
        rounded="lg"
        variant="filled"
        id="description"
        placeholder="Description"
        value={String(schema.description || "")}
        onChange={(evt) => {
          setSchema((draft) => {
            draft.description = evt.target.value;
          });
        }}
      />
    </FormControl>
  );
}

export function MinMaxLengthFields(props: IFieldProps) {
  const { schema, setSchema } = props;

  return (
    <Stack
      isInline
      alignItems="center"
      justifyContent="center"
      alignContent="center"
      spacing={4}
      m={1}
    >
      <FormLabel whiteSpace="nowrap" mr={2}>
        Min Length
      </FormLabel>
      <NumberInput
        size="sm"
        variant="filled"
        value={schema.minLength}
        onChange={(value) => {
          setSchema((draft) => {
            draft.minLength = value ? Number(value) : undefined;
          });
        }}
      >
        <NumberInputField borderRadius="md" value={0} />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      <FormLabel whiteSpace="nowrap" mr={2}>
        Max Length
      </FormLabel>
      <NumberInput
        size="sm"
        variant="filled"
        value={schema.maxLength}
        onChange={(value) => {
          setSchema((draft) => {
            draft.maxLength = value ? Number(value) : undefined;
          });
        }}
      >
        <NumberInputField borderRadius="md" value={0} />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </Stack>
  );
}

export function DefaultValueField(props: IFieldProps) {
  const { schema, setSchema } = props;

  return (
    <FormControl>
      <FormLabel>Default value</FormLabel>
      <Input
        id="default"
        variant="filled"
        placeholder="Default value"
        value={String(schema.default ?? "")}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
          setSchema((draft) => {
            draft.default = evt.target.value;
          });
        }}
      />
    </FormControl>
  );
}

export function MinMaxItemsFields(props: IFieldProps) {
  const { schema, setSchema } = props;

  return (
    <Stack
      isInline
      alignItems="center"
      justifyContent="center"
      alignContent="center"
      spacing={4}
      m={1}
    >
      <FormLabel whiteSpace="nowrap" mr={2}>
        Min Items
      </FormLabel>
      <NumberInput
        size="sm"
        value={schema.minItems}
        variant="filled"
        onChange={(value) => {
          setSchema((draft) => {
            draft.minItems = value ? Number(value) : undefined;
          });
        }}
      >
        <NumberInputField borderRadius="md" value={0} />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      <FormLabel whiteSpace="nowrap" mr={2}>
        Max Items
      </FormLabel>
      <NumberInput
        size="sm"
        value={schema.maxItems}
        variant="filled"
        onChange={(value) => {
          setSchema((draft) => {
            draft.maxItems = value ? Number(value) : undefined;
          });
        }}
      >
        <NumberInputField borderRadius="md" value={0} />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </Stack>
  );
}
