import * as React from "react";
import {
  Box,
  Flex,
  Heading,
  HStack,
  useColorModeValue,
  Tag,
  Text,
} from "@chakra-ui/react";
import DoneIcon from "@material-ui/icons/Done";

import Card from "@svix/common/widgets/Card";

interface IGettingStartedCardProps {
  icon: React.ReactNode;
  title: string;
  children: string | React.ReactNode;
  cta?: string | React.ReactNode;
  done?: boolean;
}

export default function GettingStartedCard(props: IGettingStartedCardProps) {
  const successColor = useColorModeValue("green.300", "green.400");
  const incompleteColor = useColorModeValue("gray.300", "gray.500");

  return (
    <Card w="100%" borderRadius="2xl">
      <Box alignItems="flex-start" display="flex" p={2}>
        <Box
          display="inline-block"
          bg={props.done ? successColor : incompleteColor}
          color="white"
          p="3"
          borderRadius="full"
        >
          {props.icon}
        </Box>
        <Box flexGrow={1} mx={6}>
          <HStack mb={4}>
            <Heading as="h2" size="sm">
              {props.title}
            </Heading>
            {props.done && (
              <Tag colorScheme="green">
                <Text pr={1}>Done</Text>
                <DoneIcon style={{ height: "1rem", width: "1rem" }} />
              </Tag>
            )}
          </HStack>
          {props.children}
          <Flex alignItems="center" textAlign="right" whiteSpace="nowrap" mt={4}>
            {props.cta}
          </Flex>
        </Box>
      </Box>
    </Card>
  );
}
