import { useState } from "react";
import { Flex, useColorMode, List, ListItem, Avatar, Heading } from "@chakra-ui/react";

import Sidebar from "@svix/common/widgets/Sidebar";

import EngineerMode from "src/components/EngineerMode";
import { drawerWidth } from "./Drawer";
import logo from "../logo.svg";

interface IAuthedChromeProps {
  children: React.ReactNode;
}

export default function UnauthedChrome({ children }: IAuthedChromeProps) {
  const { colorMode } = useColorMode();
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

  return (
    <>
      <Sidebar
        mobileDrawerOpen={mobileDrawerOpen}
        setMobileDrawerOpen={setMobileDrawerOpen}
      >
        <Flex h="100%" flexDir="column">
          <List w="100%">
            <ListItem
              p={3}
              height="64px"
              display="flex"
              alignItems="center"
              fontWeight={500}
            >
              <Avatar src={logo} size="sm" mr={3} />

              <Heading as="div" size="sm">
                Svix
              </Heading>
            </ListItem>
          </List>
        </Flex>
      </Sidebar>

      <EngineerMode />
      <Flex
        as="main"
        flexDir="column"
        flexGrow={1}
        bg="background.primary"
        color="text.primary"
        className={`theme-${colorMode}`}
        ml={[0, 0, drawerWidth]}
        p={8}
      >
        {children}
      </Flex>
    </>
  );
}
