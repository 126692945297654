/**
 * Dashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Feature } from './Feature';
import { OrganizationOut } from './OrganizationOut';
import { OrganizationRole } from './OrganizationRole';
import { Set } from './Set';
import { HttpFile } from '../http/http';

export class LoginFinishedOut {
    'companyName'?: string;
    'created': boolean;
    'emailVerified': boolean;
    'features': Set<Feature>;
    'fullName'?: string;
    'orgGroupId': string;
    'orgGroupRole': OrganizationRole;
    'orgs': Array<OrganizationOut>;
    'userId': string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "companyName",
            "baseName": "companyName",
            "type": "string",
            "format": ""
        },
        {
            "name": "created",
            "baseName": "created",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "emailVerified",
            "baseName": "emailVerified",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "features",
            "baseName": "features",
            "type": "Set<Feature>",
            "format": ""
        },
        {
            "name": "fullName",
            "baseName": "fullName",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgGroupId",
            "baseName": "orgGroupId",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgGroupRole",
            "baseName": "orgGroupRole",
            "type": "OrganizationRole",
            "format": ""
        },
        {
            "name": "orgs",
            "baseName": "orgs",
            "type": "Array<OrganizationOut>",
            "format": ""
        },
        {
            "name": "userId",
            "baseName": "userId",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return LoginFinishedOut.attributeTypeMap;
    }
    
    public constructor() {
    }
}

