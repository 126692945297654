/**
 * Dashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { OrganizationAndRole } from './OrganizationAndRole';
import { OrganizationInviteStatus } from './OrganizationInviteStatus';
import { OrganizationRole } from './OrganizationRole';
import { HttpFile } from '../http/http';

export class InviteOut {
    'inviteEmail': string;
    'inviterEmail': string;
    'orgGroupId': string;
    'orgGroupInviteId': string;
    'overrides'?: Array<OrganizationAndRole>;
    'role': OrganizationRole;
    'status': OrganizationInviteStatus;
    'userId'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "inviteEmail",
            "baseName": "inviteEmail",
            "type": "string",
            "format": "email"
        },
        {
            "name": "inviterEmail",
            "baseName": "inviterEmail",
            "type": "string",
            "format": "email"
        },
        {
            "name": "orgGroupId",
            "baseName": "orgGroupId",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgGroupInviteId",
            "baseName": "orgGroupInviteId",
            "type": "string",
            "format": ""
        },
        {
            "name": "overrides",
            "baseName": "overrides",
            "type": "Array<OrganizationAndRole>",
            "format": ""
        },
        {
            "name": "role",
            "baseName": "role",
            "type": "OrganizationRole",
            "format": ""
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "OrganizationInviteStatus",
            "format": ""
        },
        {
            "name": "userId",
            "baseName": "userId",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return InviteOut.attributeTypeMap;
    }
    
    public constructor() {
    }
}

