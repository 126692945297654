import { Box, Button, Heading, Text } from "@chakra-ui/react";
import { Launch } from "@material-ui/icons";

import * as C from "@svix/common/constants";

import { ReactComponent as NotFound } from "src/img/no_data.inline.svg";

export default function EventTypesEmptyState() {
  return (
    <Box
      maxWidth={800}
      margin="0 auto"
      p={4}
      textAlign="center"
      alignItems="center"
      display="flex"
      flexDirection="column"
    >
      <Box width="80%" maxW="240px">
        <NotFound width="100%" height="100%" />
      </Box>
      <Heading as="h2" size="md" mt={8} mb={2}>
        No Event Types
      </Heading>
      <Text variant="caption">Add an Event Type to get started.</Text>
      <Button
        as="a"
        mt={4}
        size="sm"
        variant="outline"
        rightIcon={<Launch fontSize="small" />}
        target="_blank"
        href={C.docs.quickstart.eventTypes}
      >
        Learn more about Event Types
      </Button>
    </Box>
  );
}
