import {
  FormLabel,
  Stack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import { Draft } from "immer";

import { JSONSchema7 } from "@svix/common/widgets/JsonSchema/types";

import { DefaultValueField, DescriptionField } from "../Fields";

interface IIntegerFieldSettingsProps {
  setSchema: (mutations: (draft: Draft<JSONSchema7>) => void) => void;
  schema: JSONSchema7;
}

export default function IntegerFieldSettings(props: IIntegerFieldSettingsProps) {
  const { schema, setSchema } = props;

  return (
    <Stack spacing={5}>
      <DescriptionField {...props} />
      <DefaultValueField {...props} />
      <Stack
        isInline
        alignItems="center"
        justifyContent="center"
        alignContent="center"
        m={1}
      >
        <FormLabel whiteSpace="nowrap" mr={2}>
          Min Value
        </FormLabel>
        <NumberInput
          size="sm"
          value={schema.minimum}
          variant="filled"
          onChange={(value) => {
            setSchema((draft) => {
              draft.minimum = value ? Number(value) : undefined;
            });
          }}
        >
          <NumberInputField value={0} />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <FormLabel whiteSpace="nowrap" mr={2}>
          Max Value
        </FormLabel>
        <NumberInput
          size="sm"
          value={schema.maximum}
          variant="filled"
          onChange={(value) => {
            setSchema((draft) => {
              draft.maximum = value ? Number(value) : undefined;
            });
          }}
        >
          <NumberInputField value={0} />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </Stack>
    </Stack>
  );
}
