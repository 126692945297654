import { useEffect, useState } from "react";
import { Flex, ModalFooter, ModalHeader, ModalBody, Text } from "@chakra-ui/react";
import { ArrowForward } from "@material-ui/icons";

import Button from "@svix/common/widgets/Button";

import { IOnboardingStepProps } from "./types";

// How long to wait before allowing the user to skip the onboarding
const waitCountdownSeconds = 2;

export default function IntroStep(props: IOnboardingStepProps) {
  const [waitLeft, setWaitLeft] = useState(waitCountdownSeconds);

  useEffect(() => {
    const timer = setInterval(() => {
      setWaitLeft(waitLeft - 1);
    }, 1000);

    if (waitLeft === 0) {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  });

  return (
    <>
      <ModalHeader
        bg="blue.500"
        pt="4em"
        pb="2em"
        borderTopRadius="lg"
        color="white"
        fontSize="3xl"
      >
        Welcome to Svix
      </ModalHeader>
      <ModalBody py={6}>
        <Text fontSize="lg">
          Now that you're here, let's send a webhook together. <br />
          It will only take a second.
        </Text>
      </ModalBody>

      <ModalFooter>
        <Button
          onClick={props.handleTeardown}
          variant="outline"
          colorScheme="gray"
          disabled={waitLeft > 0}
        >
          {waitLeft > 0 ? `Not now (${waitLeft})` : "Not now"}
        </Button>
        <Flex flexGrow={1} />
        <Button onClick={props.nextStep} rightIcon={<ArrowForward />}>
          Let's Get Started
        </Button>
      </ModalFooter>
    </>
  );
}
