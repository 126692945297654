import {
  Box,
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
  Icon,
  Text,
  Menu,
  MenuButton,
  MenuGroup,
  MenuDivider,
  MenuOptionGroup,
  MenuItemOption,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import {
  Person,
  Menu as MenuIcon,
  ExpandMore,
  Brightness7,
  Brightness3,
} from "@material-ui/icons";
import { Link } from "react-router-dom";

import { isOIDCEE } from "@svix/common/constants";
import { getAuth0ProviderName } from "@svix/common/utils";
import HelpBarItem from "@svix/common/widgets/HelpBarItem";
import Navbar, { NAVBAR_HEIGHT } from "@svix/common/widgets/Navbar";
import SSOAccountTag from "@svix/common/widgets/SSOAccountTag";

import { routeResolver } from "src/App";
import { getRegionConfig } from "src/constants";
import { useAppDispatch, useAppSelector } from "src/hooks/store";
import { logoutEE, switchEnvironment } from "src/store/auth";
import { useActiveEnv, useIsAdmin, useIsTestMode } from "src/store/selectors";
import { setSettings } from "src/store/settings";
import { useSvixAuth, getEnvTag, isEE } from "src/utils";
import DashboardSearch from "./DashboardSearch";
import { trackEvent } from "../analytics";

function EnvSwitcher() {
  const dispatch = useAppDispatch();
  const activeEnv = useActiveEnv();
  const envs = useAppSelector((state) => state.auth.environments);

  if (!activeEnv) {
    return null;
  }

  return (
    <>
      <Menu>
        <MenuButton
          data-cy="environment-menu-button"
          textAlign="left"
          color="white"
          aria-label="show environments"
          height="calc(100% - 12px)"
          borderRadius="md"
          px={3}
          _hover={{ bgColor: "blue.600" }}
          _expanded={{ bgColor: "blue.600" }}
        >
          <Flex alignItems="center">
            <Icon
              display={{ md: "none", lg: "block" }}
              boxSize={8}
              as={getRegionConfig(activeEnv.region).flag}
              mr={4}
            />
            <Box mr={3}>
              <Box>{activeEnv.orgName}</Box>
              <Box fontSize="x-small">{getEnvTag(activeEnv)}</Box>
            </Box>
            <ExpandMore opacity={0.6} />
          </Flex>
        </MenuButton>
        <MenuList maxH={`calc(100vh - ${NAVBAR_HEIGHT} - 12px)`} overflowY="scroll">
          <MenuOptionGroup
            title="Switch Environment"
            type="checkbox"
            value={activeEnv.orgId}
          >
            {envs.map((env) => (
              <MenuItemOption
                key={env.orgId}
                value={env.orgId}
                onClick={async () => switchEnvironment(env, dispatch)}
              >
                {env.orgName}
                <Text variant="caption" fontSize="xs" alignItems="center" display="flex">
                  <Icon boxSize={4} as={getRegionConfig(env.region).flag} mr={2} />
                  {getEnvTag(env)}
                </Text>
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
          <MenuDivider />
          <MenuItem as={Link} to={routeResolver.getRoute("environments")}>
            Manage Environments
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
}

function AccountBarItem() {
  const dispatch = useAppDispatch();
  const { user, logout } = useSvixAuth();
  const isAdmin = useIsAdmin();

  function logoutClicked() {
    if (isEE) {
      dispatch(logoutEE());

      if (isOIDCEE) {
        logout();
      }
    } else {
      logout({ logoutParams: { returnTo: window.location.origin } });
    }
  }

  const emailTitle = user?.email;
  const provider = getAuth0ProviderName(user?.sub || "");

  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          variant="appbar"
          aria-label="show account"
          data-cy="account-menu"
        >
          <Person />
        </MenuButton>
        <MenuList>
          <MenuGroup>
            {user && (
              <>
                <HStack px="0.8rem" py="0.4rem">
                  <Text fontSize="sm" fontWeight="semibold">
                    {emailTitle}
                  </Text>
                  <SSOAccountTag provider={provider} />
                </HStack>
                <MenuDivider />
              </>
            )}
            {!isEE && (
              <>
                <MenuItem as={Link} to={routeResolver.getRoute("settings.user.account")}>
                  Account
                </MenuItem>
                <MenuItem
                  as={Link}
                  to={routeResolver.getRoute("settings.organizationGroup.members")}
                >
                  Organization Members
                </MenuItem>
                <MenuItem as={Link} to={routeResolver.getRoute("settings")}>
                  Settings
                </MenuItem>
                {isAdmin && (
                  <MenuItem as={Link} to={routeResolver.getRoute("billing")}>
                    Plans &amp; Billing
                  </MenuItem>
                )}
                <MenuDivider />
              </>
            )}
            <MenuItem onClick={logoutClicked}>Logout</MenuItem>
          </MenuGroup>
        </MenuList>
      </Menu>
    </>
  );
}

function ColorModeToggle() {
  const dispatch = useAppDispatch();

  const darkMode = useAppSelector((state) => state.settings.darkMode);

  return (
    <IconButton
      variant="appbar"
      aria-label="toggle dark mode"
      onClick={() => {
        if (darkMode === false) {
          trackEvent("Enable Dark Mode");
        }
        dispatch(setSettings({ darkMode: !darkMode }));
      }}
    >
      {darkMode ? (
        <Icon as={Brightness7} />
      ) : (
        <Icon transform="auto" rotate={9} as={Brightness3} />
      )}
    </IconButton>
  );
}

interface IAppBarProps {
  mobileDrawerOpen: boolean;
  setMobileDrawerOpen: (isOpen: boolean) => void;
}

const testModeTag = {
  colorScheme: "red",
  label: "Viewing Test Data",
};

export default function AppBar(props: IAppBarProps) {
  const isTestMode = useIsTestMode();
  const handleDrawerToggle = () => {
    props.setMobileDrawerOpen(!props.mobileDrawerOpen);
  };

  return (
    <Navbar tag={isTestMode ? testModeTag : undefined}>
      <IconButton
        variant="appbar"
        aria-label="open drawer"
        onClick={handleDrawerToggle}
        display={["flex", "flex", "none"]}
      >
        <MenuIcon />
      </IconButton>
      <EnvSwitcher />
      <Flex flexGrow={1} />
      <ButtonGroup alignItems="center" justifyContent="flex-end" flexGrow={1} spacing={1}>
        <DashboardSearch />
        <ColorModeToggle />
        {!isEE && <HelpBarItem />}
        <AccountBarItem />
      </ButtonGroup>
    </Navbar>
  );
}
