import {
  Code,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalContent,
  ModalBody,
  ModalOverlay,
} from "@chakra-ui/react";
import { Draft } from "immer";

import Button from "@svix/common/widgets/Button";
import {
  JSONSchema7,
  JSONSchema7Definition,
} from "@svix/common/widgets/JsonSchema/types";

import ConfigurationSwitcher from "./ConfigurationSwitcher";

interface ISchemaFieldConfigurationModalProps {
  name: string;
  isOpen: boolean;
  onClose: () => void;
  schema: JSONSchema7Definition;
  setSchema: (mutations: (draft: Draft<JSONSchema7>) => void) => void;
}

export default function SchemaFieldConfigurationModal(
  props: ISchemaFieldConfigurationModalProps
) {
  const { name, isOpen, onClose, schema, setSchema } = props;

  if (typeof schema === "boolean") {
    return null;
  }

  return (
    <Modal isOpen={isOpen} size="lg" onClose={onClose}>
      <ModalOverlay />
      <ModalContent data-cy="schema-configuration-modal">
        <ModalHeader textAlign="center">
          Configure <Code>{name}</Code>
        </ModalHeader>

        <ModalBody>
          <ConfigurationSwitcher schema={schema} setSchema={setSchema} />
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
