import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
  Text,
  Box,
  Button,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

import * as C from "@svix/common/constants";
import Card from "@svix/common/widgets/Card";
import LoadingIndicator from "@svix/common/widgets/LoadingIndicator";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";

import { getApiConfiguration } from "src/api";
import { routeResolver } from "src/App";
import { SlackApi } from "src/generated/dashboard-openapi";
import { useLoading } from "src/utils";

export default function SlackAppSettings() {
  const slackUri = C.getSlackAppRegistrationUri();

  const [slackApp, , appLoading] = useLoading(async () => {
    const config = await getApiConfiguration();
    const slackApi = new SlackApi(config);
    return slackApi.getSlackAppSlackAppGet();
  }, []);

  return (
    <>
      <MetaTitle path={["Slack Notifications"]} />
      <Flex mb={4}>
        <Breadcrumb fontSize="md">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routeResolver.getRoute("settings")}>
              Settings
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>Slack</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Flex>
      <Card>
        {appLoading ? (
          <LoadingIndicator style={{ display: "block", margin: "40px auto" }} />
        ) : (
          <>
            {slackApp ? (
              <>
                <Heading as="h2" size="md" mb={4}>
                  Slack Application Configuration (Beta)
                </Heading>
                <Text size="md" variant="caption">
                  Manage your daily slack notifications for this environment.
                </Text>
                <Text mb={4} my={4}>
                  <strong>Channel: </strong>
                  {slackApp.incomingWebhookChannel}
                </Text>
                <Button
                  as="a"
                  mr={2}
                  href={slackApp.incomingWebhookConfigurationUrl}
                  target="_blank"
                  variant="outline"
                >
                  Manage Slack App
                </Button>
              </>
            ) : (
              <>
                <Heading as="h2" size="md" mb={4}>
                  Register For Slack Notifications (Beta)
                </Heading>
                <Text size="md" variant="caption">
                  Get daily slack notifications about this environment.
                </Text>
                <Text size="md" variant="caption">
                  Note: To be a part of this beta, first contact us on slack or via email.
                </Text>
                <Box mt={4} display="inline-block">
                  <a href={slackUri}>
                    <img
                      alt="Add to Slack"
                      height="40"
                      width="139"
                      src="https://platform.slack-edge.com/img/add_to_slack.png"
                      srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
                    />
                  </a>
                </Box>
              </>
            )}
          </>
        )}
      </Card>
    </>
  );
}
