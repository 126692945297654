import { TransformationTemplateKind } from "svix/dist/openapi";

import discordIcon from "src/img/integrations/discord-icon.svg";
import hubspotIcon from "src/img/integrations/hubspot-icon.svg";
import inngestIcon from "src/img/integrations/inngest-icon.png";
import teamsIcon from "src/img/integrations/microsoft-teams-icon.svg";
import slackIcon from "src/img/integrations/slack-icon.svg";
import webhookIcon from "src/img/integrations/webhook-icon.svg";
import zapierIcon from "src/img/integrations/zapier-icon.svg";

export interface IntegrationData {
  name: string;
  icon: string;
  description: string;
}

export const templateTypes: { [k in TransformationTemplateKind]: IntegrationData } = {
  Custom: {
    name: "Custom",
    icon: webhookIcon,
    description:
      "Define your own kind of transformation template, and provide instructions for how to connect it to an external service.",
  },
  Slack: {
    name: "Slack",
    icon: slackIcon,
    description:
      "The Slack template lets your users get a Slack Incoming Webhooks URL directly from the App Portal, for a seamless integration with Slack channels.",
  },
  Discord: {
    name: "Discord",
    icon: discordIcon,
    description:
      "The Discord template lets your users get a Discord Incoming Webhooks URL directly from the App Portal, for a seamless integration with Discord channels.",
  },
  Hubspot: {
    name: "Hubspot",
    icon: hubspotIcon,
    description:
      "The Hubspot template lets your users connect your webhooks to their Hubspot account.",
  },
  Inngest: {
    name: "Inngest",
    icon: inngestIcon,
    description:
      "The Inngest template lets your users get an Inngest Webhook URL directly from the App Portal.",
  },
  Teams: {
    name: "Teams",
    icon: teamsIcon,
    description:
      "The Teams template gives your users a instructions on how to get a Teams Incoming Webhook URL to set up a connection with Teams channels.",
  },
  Zapier: {
    name: "Zapier",
    icon: zapierIcon,
    description:
      "The Zapier template gives your users a instructions on how to get a Zapier Webhook URL to trigger a Zap using a webhook.",
  },
  // --- TBD ---
  CustomerIO: {
    name: "Customer.io",
    icon: "",
    description: "",
  },
  Salesforce: {
    name: "Salesforce",
    icon: "",
    description: "",
  },
  TriggerDev: {
    name: "Trigger.dev",
    icon: "",
    description: "",
  },
  Windmill: {
    name: "Windmill",
    icon: "",
    description: "",
  },
  Segment: {
    name: "Segment",
    icon: "",
    description: "",
  },
};
