import { useCallback, useEffect } from "react";
import { Box, FormControl, FormLabel, Text, Input, InputProps } from "@chakra-ui/react";
import { Publish } from "@material-ui/icons";
import { useDropzone } from "react-dropzone";
import { Controller, useFormContext, useFormState } from "react-hook-form";

import LoadingIndicator from "../../LoadingIndicator";

interface IFileInputProps extends InputProps {
  accept?: string;
  multiple?: boolean;
  name: string;
  prompt: string;
  label?: string;
  isLoading?: boolean;
}

function FileInput(props: IFileInputProps) {
  const { name, label, prompt, height, isLoading = false, isDisabled } = props;
  const { register, unregister, setError, setValue, watch } = useFormContext();
  const { errors } = useFormState();

  const files: File[] = watch(name);
  const onDrop = useCallback(
    (droppedFiles, rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        setError(name, rejectedFiles[0].errors[0]);
      }
      if (droppedFiles.length > 0) {
        setValue(name, droppedFiles, { shouldValidate: true });
      }
    },
    [setValue, name, setError]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: props.accept,
  });

  const hasError = errors[name];
  const hasFiles = Array.isArray(files) ? files.length > 0 : !!files;

  useEffect(() => {
    register(name);
    return () => {
      unregister(name);
    };
  }, [register, unregister, name]);

  return (
    <FormControl isDisabled={isDisabled}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Box {...getRootProps()}>
        <Input
          {...getInputProps()}
          isDisabled={isDisabled}
          size="md"
          {...props}
          id={name}
        />
        <Box
          cursor={isDisabled ? "default" : "pointer"}
          border="2px solid"
          borderColor={hasError ? "text.danger" : "background.modifier.border"}
          textAlign="center"
          px={4}
          py={12}
          borderRadius="lg"
          borderStyle={hasFiles ? "solid" : "dashed"}
          transition="all ease-in-out 0.2s"
          {...(isDragActive && {
            color: "blue.400",
            borderColor: "blue.400",
          })}
          _hover={
            isDisabled
              ? {}
              : {
                  color: "blue.400",
                  borderColor: "blue.400",
                }
          }
          _active={{
            outline: "4px solid",
            outlineColor: "blue.200",
          }}
          h={height}
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <>
              {hasFiles ? (
                <Box color="text.primary">
                  {files.map((file) => {
                    return (
                      <Text fontWeight="medium" key={file.name}>
                        {file.name}
                      </Text>
                    );
                  })}
                </Box>
              ) : (
                <Box color={hasError ? "text.danger" : "text.muted"}>
                  <Publish color="inherit" />
                  <Text>{prompt}</Text>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    </FormControl>
  );
}

export default function FileInputField(props: IFileInputProps) {
  const { name, multiple, ...rest } = props;
  const { control } = useFormContext();

  return (
    <Controller
      render={() => <FileInput name={name} multiple={multiple} {...rest} />}
      name={name}
      control={control}
      defaultValue=""
    />
  );
}
