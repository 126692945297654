import { Fragment } from "react";
import {
  Box,
  Flex,
  HStack,
  SkeletonText,
  Link as StyledLink,
  Thead,
  Text,
  Tr,
  Th,
  Tbody,
} from "@chakra-ui/react";
import { Add, Publish, CloudDownload } from "@material-ui/icons";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { Link, useHistory } from "react-router-dom";
import type { EventTypeOut } from "svix";
import { EventTypeApi } from "svix/dist/openapi";

import usePagination from "@svix/common/hooks/pagination";
import Button from "@svix/common/widgets/Button";
import Form from "@svix/common/widgets/Form";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
} from "@svix/common/widgets/PageToolbar";
import ResourceError from "@svix/common/widgets/ResourceError";
import SubmitButton from "@svix/common/widgets/SubmitButton";
import Table from "@svix/common/widgets/Table";
import TableCell from "@svix/common/widgets/TableCell";

import { getApiConfiguration, getSvix } from "src/api";
import { routeResolver } from "src/App";
import { OrganizationSettingsApi } from "src/generated/dashboard-openapi";
import { useAppSelector } from "src/hooks/store";
import { useIsMemberOrAdmin, useRegion } from "src/store/selectors";
import { isEE } from "src/utils";
import EventTypesEmptyState from "./EmptyState";
import EventTypeRow from "./EventTypeRow";

interface IGroupedEventTypes {
  key: string;
  items: EventTypeOut[];
}

function groupEventTypes(eventTypes: EventTypeOut[]): IGroupedEventTypes[] {
  return eventTypes.reduce((groups: IGroupedEventTypes[], et: EventTypeOut) => {
    const key = et.groupName ?? et.name.split(".")[0];
    let group = groups.find((group: IGroupedEventTypes) => group.key === key);
    if (!group) {
      group = {
        key,
        items: [],
      };
      groups.push(group);
    }
    group.items.push(et);
    return groups;
  }, []);
}

export default function EventTypes() {
  const region = useRegion();
  const activeEnvId = useAppSelector((store) => store.auth.activeEnvId)!;
  const isMemberOrAdmin = useIsMemberOrAdmin();
  const history = useHistory();
  const { data: orgSettings } = useQuery(
    ["environments", activeEnvId, "orgSettings"],
    async () => {
      const config = await getApiConfiguration();
      const orgSettingsApi = new OrganizationSettingsApi(config);
      return orgSettingsApi.settingsGetOrganizationSettingsGet();
    },
    {
      enabled: !isEE,
    }
  );
  const formCtx = useForm({
    defaultValues: {},
  });

  const isPublished = orgSettings?.eventCatalogPublished ?? false;
  const catalogLink = `https://www.svix.com/event-types/${region}/${activeEnvId}/`;

  const [availableEvents, eventTypesCtx] = usePagination(
    ["environments", activeEnvId, "eventTypes"],
    async (iterator) => {
      const api = await getSvix();
      return api.eventType.list({ iterator });
    }
  );

  const generateSDKMutation = useMutation(async function onGenerateSDK() {
    const sv = await getSvix();
    const api = new EventTypeApi(sv._configuration);
    const backgroundTask = await api.v1EventTypeExportOpenapi({});

    history.push(
      routeResolver.getRoute("event-types.create.generateSDK._taskId", {
        taskId: backgroundTask.id,
      })
    );
  });

  if (eventTypesCtx.error) {
    return <ResourceError resourceName="event types" onClick={eventTypesCtx.refetch} />;
  }

  const groupedEventTypes =
    availableEvents?.data && groupEventTypes(availableEvents.data);

  const showGroupHeaders = groupedEventTypes?.some((x) => x.items.length > 1);

  return (
    <>
      <MetaTitle path={["Event Types"]} />
      <PageToolbar>
        <Breadcrumbs>
          <BreadcrumbItem>Event Types</BreadcrumbItem>
        </Breadcrumbs>
        <Flex flexGrow={1} />
        {isMemberOrAdmin && (
          <HStack spacing={4}>
            <Button
              colorScheme="brand"
              variant="outline"
              as={Link}
              leftIcon={<Publish />}
              to={routeResolver.getRoute("event-types.create.import")}
              size="sm"
            >
              Import from OpenAPI
            </Button>
            <Button
              colorScheme="brand"
              as={Link}
              leftIcon={<Add />}
              to={routeResolver.getRoute("event-types.create.new")}
              size="sm"
            >
              Add Event Type
            </Button>
          </HStack>
        )}
      </PageToolbar>

      {!isEE && (
        <>
          {isPublished ? (
            <Box mt={2} mb={6}>
              <Text>
                Your event types are publicly served at{" "}
                <StyledLink
                  border="1px solid"
                  borderColor="gray.200"
                  borderRadius="lg"
                  color="blue.600"
                  display="inline-block"
                  fontWeight="semibold"
                  href={catalogLink}
                  target="_blank"
                  mt={2}
                  px={2}
                  py={1}
                >
                  {catalogLink}
                </StyledLink>
              </Text>
            </Box>
          ) : (
            <Box my={2}>
              <Text maxW="50em">
                Your event types are only available to users within the App Portal. If you
                would like to make your event types served publicly, you should configure
                your{" "}
                <StyledLink
                  fontWeight="medium"
                  color="blue.500"
                  mt={2}
                  as={Link}
                  to={routeResolver.getRoute("settings.organization.catalog")}
                >
                  Event Catalog settings
                </StyledLink>
                .
              </Text>
            </Box>
          )}
        </>
      )}

      {isMemberOrAdmin && !isEE && (
        <Box my={4}>
          <Form onSubmit={() => generateSDKMutation.mutate()} {...formCtx}>
            <SubmitButton
              size="sm"
              variant="outline"
              leftIcon={<CloudDownload />}
              isLoading={generateSDKMutation.isLoading}
            >
              Generate SDK types
            </SubmitButton>
          </Form>
        </Box>
      )}

      {eventTypesCtx.isLoading && (
        <Table
          data-cy="eventtypes-table"
          response={availableEvents}
          requestElems={eventTypesCtx}
        >
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Description</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <TableCell align="left">
                <SkeletonText noOfLines={1} />
              </TableCell>
              <TableCell align="left">
                <SkeletonText noOfLines={2} />
              </TableCell>
              <TableCell />
            </Tr>
          </Tbody>
        </Table>
      )}

      {availableEvents && availableEvents.data.length === 0 && <EventTypesEmptyState />}

      {availableEvents && availableEvents.data.length > 0 && (
        <Table
          data-cy="eventtypes-table"
          variant="hover"
          response={availableEvents}
          requestElems={eventTypesCtx}
        >
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Description</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {groupedEventTypes?.map((eventTypeGroup) => (
              <Fragment key={eventTypeGroup.key}>
                {showGroupHeaders && (
                  <Tr
                    bg="background.primary"
                    role="rowgroup"
                    sx={{
                      "tbody &": {
                        _hover: {
                          background: "background.primary",
                        },
                      },
                    }}
                  >
                    <Th
                      as="td"
                      cursor="initial"
                      colSpan={3}
                      scope="row"
                      borderTop="1px solid"
                      borderBottom="1px solid"
                      borderColor="background.modifier.border"
                    >
                      {eventTypeGroup.key}
                    </Th>
                  </Tr>
                )}
                {eventTypeGroup.items.map((eventType) => (
                  <EventTypeRow key={eventType.name} eventType={eventType} />
                ))}
              </Fragment>
            ))}
          </Tbody>
        </Table>
      )}
    </>
  );
}
