import { useState, useCallback, useEffect } from "react";
import { Box, Alert, AlertIcon, Text, Divider } from "@chakra-ui/react";

import { useSearch } from "@svix/common/hooks/search";
import Button from "@svix/common/widgets/Button";
import LoadingIndicator from "@svix/common/widgets/LoadingIndicator";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";

import { getApiConfiguration } from "src/api";
import { PromiseIntegrationApi as IntegrationApi } from "src/generated/dashboard-openapi/types/PromiseAPI";
import { useAppSelector } from "src/hooks/store";
import AddEnvironmentModal from "src/screens/Environments/AddEnvironmentModal";
import ProjectEnvironmentMapper from "./ProjectEnvironmentMapper";

export default function VercelInstallScreen() {
  const code = useSearch("code") ?? "";
  const next = useSearch("next") ?? "";
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);

  const [success, setSuccess] = useState<boolean>();
  const [isAddEnvironmentOpen, setIsAddEnvironmentOpen] = useState(false);

  const setVercelSecret = useCallback(async () => {
    setSuccess(undefined);
    try {
      const config = await getApiConfiguration();
      const integrationApi = new IntegrationApi(config);
      await integrationApi.setVercelTokenIntegrationsVercelPost({ code });
      setSuccess(true);
    } catch (e) {
      setSuccess(false);
    }
  }, [code]);

  useEffect(() => {
    if (isLoggedIn) {
      setVercelSecret();
    }
  }, [isLoggedIn, setVercelSecret]);

  const environments = useAppSelector((state) => state.auth.environments);

  const onAddEnvironmentClose = () => {
    setIsAddEnvironmentOpen(false);
  };

  return (
    <Box p="1em">
      <MetaTitle path={["Vercel Integration"]} />
      {success === undefined && (
        <>
          <LoadingIndicator style={{ display: "block", margin: "40px auto" }} />
        </>
      )}
      {success === false && (
        <Alert status="error" rounded="md">
          <AlertIcon />
          An error occurred configuring this integration. Please close this window and try
          again.
        </Alert>
      )}
      {success === true && (
        <Box>
          <Alert status="info" rounded="md">
            <AlertIcon />
            <Text>
              Svix will automatically configure your Vercel project with the
              <Text display="inline" mx=".25em" fontFamily="menlo; monospace;">
                SVIX_API_KEY
              </Text>
              and
              <Text display="inline" mx=".25em" fontFamily="menlo; monospace;">
                SVIX_ENV_ID
              </Text>
              environment variables. You can always make changes later from the Svix
              dashboard.
            </Text>
          </Alert>
          <Button size="sm" onClick={() => setIsAddEnvironmentOpen(true)} my="1em">
            Create a new Svix environment
          </Button>
          <AddEnvironmentModal
            isOpen={isAddEnvironmentOpen}
            onClose={onAddEnvironmentClose}
            onAddEnvironment={onAddEnvironmentClose}
          />
          <Divider mb="1em" />
          <ProjectEnvironmentMapper environments={environments} />
          <Divider mt="1em" />
          <Box mt="1em">
            <Button size="sm" as="a" href={next}>
              Finish
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}
