import { useState } from "react";
import { useBoolean } from "@chakra-ui/react";
import { EventTypeFromOpenApi } from "svix/dist/openapi";

import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
} from "@svix/common/widgets/PageToolbar";

import { routeResolver } from "src/App";
import { EventTypesImportList } from "./EventTypesImportList";
import EventTypesUpload from "./EventTypesUpload";

export default function EventTypesImportScreen() {
  const [hasUploadedSpec, setHasUploadedSpec] = useBoolean(false);
  const [uploadedEvents, setUploadedEvents] = useState<EventTypeFromOpenApi[]>([]);

  const onUpload = (eventTypes: EventTypeFromOpenApi[]) => {
    setUploadedEvents(eventTypes);
    setHasUploadedSpec.on();
  };

  return (
    <>
      <MetaTitle path={["Import OpenAPI spec"]} />
      <PageToolbar>
        <Breadcrumbs>
          <BreadcrumbItem to={routeResolver.getRoute("event-types")}>
            Event Types
          </BreadcrumbItem>
          <BreadcrumbItem>Import OpenAPI spec</BreadcrumbItem>
        </Breadcrumbs>
      </PageToolbar>

      {!hasUploadedSpec && <EventTypesUpload onUpload={onUpload} />}
      {hasUploadedSpec && <EventTypesImportList uploadedEventTypes={uploadedEvents} />}
    </>
  );
}
