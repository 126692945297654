import * as React from "react";
import { Tr } from "@chakra-ui/table";
import { EventTypeOut } from "svix";

import TableCell from "@svix/common/widgets/TableCell";

import { routeResolver } from "src/App";
import { useIsMemberOrAdmin } from "src/store/selectors";
import EventTypeMenu from "./EventTypeMenu";

interface IEventTypeRowProps {
  eventType: EventTypeOut;
}

const EventTypeRow: React.FunctionComponent<IEventTypeRowProps> = ({
  eventType,
}: IEventTypeRowProps) => {
  const isMemberOrAdmin = useIsMemberOrAdmin();
  return (
    <>
      <Tr key={eventType.name}>
        <TableCell
          align="left"
          to={routeResolver.getRoute("event-types._name", { name: eventType.name })}
        >
          {eventType.name}
        </TableCell>
        <TableCell
          align="left"
          to={routeResolver.getRoute("event-types._name", { name: eventType.name })}
        >
          {eventType.description}
        </TableCell>
        {isMemberOrAdmin ? <EventTypeMenu eventType={eventType} /> : <TableCell />}
      </Tr>
    </>
  );
};

export default EventTypeRow;
