import * as React from "react";
import { Box, BoxProps, Heading, HeadingProps, useStyleConfig } from "@chakra-ui/react";

export interface ICardProps extends Omit<BoxProps, "title"> {
  title?: React.ReactNode;
  cta?: React.ReactNode;
}

export function CardHeading(props: HeadingProps) {
  const { children } = props;

  return (
    <Heading
      as="h4"
      alignItems="center"
      bgColor="background.hover"
      size="xs"
      h="40px"
      w="100%"
      borderBottom="1px solid"
      borderColor="background.modifier.border"
      borderTopRadius="card"
      justifyContent="space-between"
      display="flex"
      px={5}
    >
      {children}
    </Heading>
  );
}

export default function Card(props: ICardProps) {
  const { children, cta, title, maxH, overflowY, padding, ...rest } = props;
  const cardStyles = useStyleConfig("Card");
  const cardBodyStyles = useStyleConfig("CardBody");

  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  const showHeading = Boolean(title || cta);

  return (
    <Box __css={cardStyles} {...rest}>
      {showHeading && (
        <CardHeading>
          <Box as="span" flexShrink={0}>
            {title}
          </Box>
          {cta}
        </CardHeading>
      )}
      <Box __css={cardBodyStyles} overflowY={overflowY} maxH={maxH} padding={padding}>
        {children}
      </Box>
    </Box>
  );
}
