import { Text } from "@chakra-ui/react";
import MenuBook from "@material-ui/icons/MenuBook";

import Link from "@svix/common/widgets/Link";

import { routeResolver } from "src/App";
import { useAppSelector } from "src/hooks/store";
import GettingStartedCard from "../GettingStartedCard";

export default function DocumentationCard() {
  const { documentationTaskDone } = useAppSelector((store) => store.settings);
  return (
    <GettingStartedCard
      done={documentationTaskDone}
      icon={<MenuBook style={{ fontSize: 24 }} />}
      title="Write your webhooks documentation"
      cta={
        <Link to={routeResolver.getRoute("documentation.generator")}>
          Go to documentation template
        </Link>
      }
    >
      <Text variant="caption">
        We provide a template for your webhook documentation to get you started.
      </Text>
    </GettingStartedCard>
  );
}
