import { Heading, Box, Th, Tbody, Thead, Tr } from "@chakra-ui/react";

import usePagination from "@svix/common/hooks/pagination";
import Table from "@svix/common/widgets/Table";

import { getApiConfiguration } from "src/api";
import { InviteOut, InvitationApi } from "src/generated/dashboard-openapi";
import InviteRow from "./InviteRow";

export default function InvitesTable() {
  const [invites, invitesCtx] = usePagination(
    ["outgoingInvites", "pending"],
    async (iterator) => {
      const config = await getApiConfiguration();
      const inviteApi = new InvitationApi(config);
      return inviteApi.listOrgGroupInvitesInviteOrgGroupGet(
        iterator,
        undefined,
        "Pending"
      );
    }
  );

  const hasInvites = !invitesCtx.error && invites && invites.data.length > 0;

  return hasInvites ? (
    <Box mt={2}>
      <Heading as="h2" size="sm" mb={4}>
        Pending Invites
      </Heading>
      <Table response={invites} requestElems={invitesCtx} data-cy="invites-table">
        <Thead>
          <Tr>
            <Th>Email Address</Th>
            <Th>Roles</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {invites.data.map((invite: InviteOut) => (
            <InviteRow key={invite.orgGroupInviteId} invite={invite} />
          ))}
        </Tbody>
      </Table>
    </Box>
  ) : null;
}
