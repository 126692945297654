import { Text } from "@chakra-ui/react";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import { useQuery } from "react-query";

import Link from "@svix/common/widgets/Link";

import { getApiConfiguration } from "src/api";
import { routeResolver } from "src/App";
import { BillingApi } from "src/generated/dashboard-openapi";
import { useAppSelector } from "src/hooks/store";
import GettingStartedCard from "../GettingStartedCard";

export default function ProdEnvCard() {
  const environments = useAppSelector((state) => state.auth.environments);

  const { data: paymentMethods } = useQuery(["billing", "paymentMethods"], async () => {
    const config = await getApiConfiguration();
    const billingApi = new BillingApi(config);
    return billingApi.getPaymentMethodsBillingPaymentMethodsGet();
  });

  const hasProdEnv = environments.some((env) => env.orgEnv === "Prod");
  const hasPaymentMethod = paymentMethods && paymentMethods.data.length > 0;

  return (
    <GettingStartedCard
      done={hasProdEnv && hasPaymentMethod}
      icon={<PlayArrowIcon style={{ fontSize: 24 }} />}
      title="Go to production"
      cta={<Link to={routeResolver.getRoute("billing")}>Add Billing information</Link>}
    >
      <Text variant="caption">
        Create a production environment from the Environments page. Make sure to add your
        billing information so that your webhooks don't get rate limited.
      </Text>
      <Text mt={2} variant="caption">
        <strong>Security Note:</strong> Make sure that you don't share your production API
        key anywhere publicly.
      </Text>
    </GettingStartedCard>
  );
}
