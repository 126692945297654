import { Checkbox, CheckboxProps } from "@chakra-ui/react";
import { useController } from "react-hook-form";

export interface ICheckboxFieldProps extends CheckboxProps {
  control: any;
  name: string;
}

export default function CheckboxField(props: ICheckboxFieldProps) {
  const { children, name, control, ...rest } = props;
  const { field } = useController({
    control,
    name,
  });

  return (
    <Checkbox
      {...field}
      {...rest}
      fontWeight="semibold"
      id={name}
      isChecked={field.value}
      display="flex"
      alignItems="center"
      borderRadius="md"
      py={1}
      size="sm"
    >
      {children}
    </Checkbox>
  );
}
