import Ajv from "ajv";
import addFormats from "ajv-formats";
import { Draft } from "immer";

import { toPascalCase } from "@svix/common/utils";
import { JSONSchema7 } from "@svix/common/widgets/JsonSchema/types";

export const UI_LEVEL_PX_OFFSET = 32;
const SCHEMA_DRAFT = "http://json-schema.org/draft-07/schema#";

export const SchemaTypes = ["string", "number", "array", "object", "boolean", "integer"];
export type SchemaType = typeof SchemaTypes[number];

const ajv = new Ajv({ strictTypes: false, addUsedSchema: false, strictSchema: false });
addFormats(ajv);

export const STRING_FORMATS = Object.freeze([
  { name: "date-time" },
  { name: "date" },
  { name: "time" },
  { name: "email" },
  { name: "hostname" },
  { name: "ipv4" },
  { name: "ipv6" },
  { name: "uri" },
  { name: "regex" },
]);

export const getDefaultSchema = (
  dataType: SchemaType,
  includeSchema?: boolean
): JSONSchema7 => {
  switch (dataType) {
    case "number":
      return includeSchema
        ? {
            $schema: SCHEMA_DRAFT,
            $id: SCHEMA_DRAFT,
            type: "number",
            title: "",
            description: "",
            properties: {},
          }
        : {
            type: "number",
            title: "",
            description: "",
            properties: {},
          };
    case "boolean":
      return includeSchema
        ? {
            $schema: SCHEMA_DRAFT,
            $id: SCHEMA_DRAFT,
            type: "boolean",
            title: "",
            description: "",
            properties: {},
          }
        : {
            type: "boolean",
            title: "",
            description: "",
            properties: {},
          };
    case "integer":
      return includeSchema
        ? {
            $schema: SCHEMA_DRAFT,
            $id: SCHEMA_DRAFT,
            type: "integer",
            title: "",
            description: "",
            properties: {},
          }
        : {
            type: "integer",
            title: "",
            description: "",
            properties: {},
          };
    case "array":
      return includeSchema
        ? {
            $schema: SCHEMA_DRAFT,
            $id: SCHEMA_DRAFT,
            type: "array",
            title: "",
            description: "",
            items: {
              type: "string",
              title: "",
              description: "",
            },
          }
        : {
            type: "array",
            title: "",
            description: "",
            items: {
              type: "string",
              title: "",
              description: "",
            },
          };
    case "object":
      return includeSchema
        ? {
            $schema: SCHEMA_DRAFT,
            $id: SCHEMA_DRAFT,
            type: "object",
            title: "",
            description: "",
            properties: {},
            required: [],
            additionalProperties: false,
          }
        : {
            type: "object",
            title: "",
            description: "",
            properties: {},
            required: [],
            additionalProperties: false,
          };
    case "string":
      return includeSchema
        ? {
            $schema: SCHEMA_DRAFT,
            $id: SCHEMA_DRAFT,
            type: "string",
            title: "",
            description: "",
            properties: {},
          }
        : {
            type: "string",
            title: "",
            description: "",
            properties: {},
          };
    default:
      return {
        title: "",
        description: "",
        $ref: dataType,
      };
  }
};

export function findAvailableFieldName(
  schema: JSONSchema7 | Draft<JSONSchema7>,
  baseName = "field"
): string {
  let num = 0;
  while (schema.properties?.[`${baseName}_${num}`] !== undefined) {
    num++;
  }
  return `${baseName}_${num}`;
}

export function isSchemaValid(schema?: JSONSchema7): boolean {
  if (!schema) {
    return false;
  }

  try {
    ajv.compile(schema);
    return true;
  } catch (err) {
    return false;
  }
}

export function transformSchema(schema?: JSONSchema7): void {
  if (schema?.type === "array" && Boolean(schema.items)) {
    const itemSchema = Array.isArray(schema.items) ? schema.items[0] : schema.items;
    if (typeof itemSchema === "object") {
      transformSchema(itemSchema);
    }
  }

  if (schema?.type === "object" && schema.properties) {
    Object.entries(schema.properties).forEach(([name, itemSchema]) => {
      if (typeof itemSchema === "object") {
        if (!itemSchema.title) {
          itemSchema.title = toPascalCase(name);
        }
        transformSchema(itemSchema);
      }
    });
  }
}
