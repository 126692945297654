/**
 * Dashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Set } from './Set';
import { HttpFile } from '../http/http';

export class ApplicationEventTypesResponse {
    'explicitlySubscribedEventTypes': Set<string>;
    'hasCatchAllEndpoint': boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "explicitlySubscribedEventTypes",
            "baseName": "explicitlySubscribedEventTypes",
            "type": "Set<string>",
            "format": ""
        },
        {
            "name": "hasCatchAllEndpoint",
            "baseName": "hasCatchAllEndpoint",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return ApplicationEventTypesResponse.attributeTypeMap;
    }
    
    public constructor() {
    }
}

