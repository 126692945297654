import { Tooltip, forwardRef, Tag, TagProps } from "@chakra-ui/react";

import {
  OrganizationAndRole,
  OrganizationOut,
  OrganizationRole,
} from "src/generated/dashboard-openapi";

interface RolesSummaryTagProps {
  defaultRole: OrganizationRole;
  envs: OrganizationOut[];
  overrides?: OrganizationAndRole[];
}

const RolesSummaryTag = forwardRef<TagProps & RolesSummaryTagProps, "div">(
  (props, ref) => {
    const { defaultRole, overrides = [], envs, ...rest } = props;
    const envsByRole = environmentsPerRole(envs, overrides, defaultRole);

    return (
      <>
        {Object.entries(envsByRole).map((e) => {
          const [role, envs] = e;
          if (envs.length === 0 || role === "NoAccess") {
            return null;
          }
          const name = roleNames[role as OrganizationRole];
          return (
            <Tooltip label={envs.map((e) => e.orgName).join(", ")} key={role}>
              <Tag ref={ref} {...rest} fontWeight="semibold" mr={1}>
                {`${name} (${envs.length})`}
              </Tag>
            </Tooltip>
          );
        })}
      </>
    );
  }
);

const roleNames = {
  Admin: "Admin",
  Member: "Member",
  Viewer: "Viewer",
  NoAccess: "No Access",
};

const environmentsPerRole = (
  envs: OrganizationOut[],
  overrides: OrganizationAndRole[],
  defaultRole: OrganizationRole
) => {
  const envsByRole: { [k in OrganizationRole]: OrganizationOut[] } = {
    Admin: [],
    Member: [],
    Viewer: [],
    NoAccess: [],
  };

  for (const env of envs) {
    const ov = overrides.find((o) => o.org === env.orgId);
    if (ov) {
      envsByRole[ov.role].push(env);
    } else {
      envsByRole[defaultRole].push(env);
    }
  }

  return envsByRole;
};

export default RolesSummaryTag;
