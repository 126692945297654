import { Stack } from "@chakra-ui/react";
import { Draft } from "immer";

import { JSONSchema7 } from "@svix/common/widgets/JsonSchema/types";

import { DescriptionField, MinMaxItemsFields } from "../Fields";

interface IArrayFieldSettingsProps {
  schema: JSONSchema7;
  setSchema: (mutations: (draft: Draft<JSONSchema7>) => void) => void;
}

export default function ArrayFieldSettings(props: IArrayFieldSettingsProps) {
  return (
    <Stack spacing={5}>
      <DescriptionField {...props} />
      <MinMaxItemsFields {...props} />
    </Stack>
  );
}
