import { Box, Skeleton, Stat, StatLabel, StatNumber, Tooltip } from "@chakra-ui/react";

import { EndpointCountResponse } from "src/generated/dashboard-openapi/models/EndpointCountResponse";

interface ITotalMessagesProps {
  stats: EndpointCountResponse | undefined;
}

export default function TotalMessages(props: ITotalMessagesProps) {
  const endpointCount = props.stats?.data ?? 0;

  return (
    <Tooltip label="Sum of all endpoints across all apps in this environment">
      <Box pr={12}>
        <Stat>
          <StatLabel>Total Endpoints</StatLabel>
          <Skeleton isLoaded={!!props.stats}>
            <StatNumber>{endpointCount}</StatNumber>
          </Skeleton>
        </Stat>
      </Box>
    </Tooltip>
  );
}
