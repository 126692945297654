import * as React from "react";
import {
  Flex,
  Input,
  Checkbox,
  Select,
  Tooltip,
  IconButton,
  useBoolean,
  Collapse,
} from "@chakra-ui/react";
import { ArrowRight, Close, Settings } from "@material-ui/icons";
import { Draft } from "immer";

import { toPascalCase } from "@svix/common/utils";
import {
  JSONSchema7,
  JSONSchema7Definition,
  JSONSchema7TypeName,
} from "@svix/common/widgets/JsonSchema/types";

import SchemaFieldConfigurationModal from "./ConfigurationModal";
import SchemaArray from "./SchemaArray";
import SchemaObject from "./SchemaObject";
import { UI_LEVEL_PX_OFFSET } from "./utils";

export interface ISchemaItemProps {
  definitions: JSONSchema7["definitions"];
  name: string;
  schema: JSONSchema7Definition;
  required?: string[];
  isReadOnly?: boolean;
  level: number;

  setSchema: (mutations: (draft: Draft<JSONSchema7>) => void) => void;
  renameItem: (newName: string) => void;
  changeType: (fieldName: string, newType: string) => void;
  deleteItem: () => void;
  toggleRequired: (required: boolean) => void;
}

const SCHEMA_TYPES = ["string", "number", "array", "object", "boolean", "integer"];

export default function SchemaItem(props: ISchemaItemProps) {
  const {
    definitions,
    name,
    level,
    changeType,
    renameItem,
    deleteItem,
    schema,
    toggleRequired,
    isReadOnly,
    required,
    setSchema,
  } = props;

  const [showConfigModal, setShowConfigModal] = useBoolean();
  const [isExpanded, setExpanded] = useBoolean(true);

  const isRequired = required?.includes(name) ?? false;
  const offsetPx = `${level * UI_LEVEL_PX_OFFSET}px`;

  if (typeof schema === "boolean") {
    return null;
  }

  return (
    <>
      <Flex
        alignItems="center"
        direction="row"
        wrap="nowrap"
        className="schema-item"
        w={`calc(100% - ${offsetPx})`}
        ml={offsetPx}
        my={1}
        sx={{ gap: 12 }}
      >
        {schema.type === "object" && (
          <IconButton
            aria-label="toggle expand"
            variant="ghost"
            size="xs"
            color="gray.600"
            position="absolute"
            left={`${level * UI_LEVEL_PX_OFFSET - 28}px`}
            onClick={setExpanded.toggle}
          >
            <ArrowRight
              fontSize="small"
              style={{ transform: isExpanded ? "rotate(90deg)" : "rotate(0deg)" }}
            />
          </IconButton>
        )}
        <Input
          name="schema-item-property-name"
          defaultValue={name}
          size="sm"
          variant="filled"
          placeholder="Enter property name"
          flexShrink={0}
          w={`calc(18em - ${offsetPx})`}
          onChange={(evt) => {
            const name = evt.target.value;
            if (name) {
              renameItem(name as JSONSchema7TypeName);
            }
          }}
          onBlur={(evt) => {
            const fieldNameHasCollision = evt.target.value !== name;
            if (fieldNameHasCollision) {
              evt.target.value = name;
            }
          }}
        />
        <Select
          name="schema-item-datatype"
          variant="filled"
          size="sm"
          flexShrink={0}
          placeholder="Choose data type"
          value={schema.type ?? schema.$ref}
          onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
            if (evt.target.value) {
              changeType(name, evt.target.value);
            }
          }}
          w="15em"
        >
          {SCHEMA_TYPES.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
          {definitions &&
            Object.keys(definitions).map((definition) => (
              <option key={definition} value={`#/definitions/${definition}`}>
                {definition}
              </option>
            ))}
        </Select>
        <Input
          isDisabled={isReadOnly}
          value={schema.title || ""}
          placeholder={name ? toPascalCase(name) : "Add Title"}
          size="sm"
          variant="filled"
          flexShrink={1}
          onChange={(evt) => {
            setSchema((draft) => {
              draft.title = evt.target.value;
            });
          }}
        />
        <Checkbox
          isDisabled={isReadOnly}
          isChecked={isRequired}
          colorScheme="blue"
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
            toggleRequired(evt.target.checked);
          }}
          w="3.5em"
          flexShrink={0}
          justifyContent="center"
        />

        <Flex position="absolute" right="-4em">
          {schema.type !== "object" && (
            <Tooltip
              hasArrow
              aria-label="Configure field"
              label="Configure"
              placement="top"
            >
              <IconButton
                isRound
                isDisabled={isReadOnly}
                size="sm"
                variant="ghost"
                colorScheme="gray"
                icon={<Settings style={{ fontSize: 16 }} />}
                aria-label="Configure field"
                onClick={setShowConfigModal.on}
              />
            </Tooltip>
          )}

          <Tooltip hasArrow aria-label="Remove Node" label="Remove Node" placement="top">
            <IconButton
              isRound
              isDisabled={isReadOnly}
              size="sm"
              variant="ghost"
              colorScheme="gray"
              icon={<Close style={{ fontSize: 16 }} />}
              aria-label="Remove Node"
              onClick={deleteItem}
            />
          </Tooltip>
        </Flex>
      </Flex>

      <Collapse in={isExpanded}>
        {schema.type === "object" && (
          <SchemaObject
            level={level + 1}
            schema={schema}
            setSchema={setSchema}
            definitions={definitions}
          />
        )}
        {schema.type === "array" && (
          <SchemaArray
            level={level + 1}
            schema={schema}
            setSchema={setSchema}
            definitions={definitions}
          />
        )}
      </Collapse>

      <SchemaFieldConfigurationModal
        name={name}
        isOpen={showConfigModal}
        onClose={setShowConfigModal.off}
        schema={schema}
        setSchema={setSchema}
      />
    </>
  );
}
