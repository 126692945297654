import * as React from "react";
import { Box, Fade, Text, useColorModeValue } from "@chakra-ui/react";
import { useElements, PaymentElement, useStripe } from "@stripe/react-stripe-js";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";

import Button from "@svix/common/widgets/Button";

import { trackEvent } from "src/analytics";
import { routeResolver } from "src/App";

interface IPaymentFormProps {
  isStripeReady: boolean;
  onReady: () => void;
}

export default function PaymentForm(props: IPaymentFormProps) {
  const { onReady, isStripeReady } = props;
  const dangerColor = useColorModeValue("red.500", "red.300");
  const stripe = useStripe();
  const { planName } = useParams<{ planName: string }>();
  const elements = useElements();
  const [message, setMessage] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const history = useHistory();

  const handleSubmit: React.FormEventHandler<HTMLElement> = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { setupIntent, error } = await stripe.confirmSetup({
      elements,
      redirect: "if_required",
      confirmParams: {
        return_url: `http://localhost:4000/billing/payment-complete/${planName}`,
      },
    });

    if (error) {
      setMessage(error.message ?? "Something went wrong");
      setIsLoading(false);
    } else if (!setupIntent) {
      setMessage("Something went wrong");
      setIsLoading(false);
    } else {
      trackEvent("Add Payment Method");
      const dest = routeResolver.getRoute("billing.paymentComplete._planName", {
        planName,
      });
      history.push(`${dest}?setup_intent=${setupIntent.id}`);
    }
  };

  return (
    <Box as="form" onSubmit={handleSubmit} mb={2}>
      <Fade in={isStripeReady}>
        <PaymentElement id="payment-form" onReady={onReady} />
        <Button isLoading={isLoading} type="submit" mt={6}>
          Upgrade Subscription
        </Button>
      </Fade>
      {/* Show any error or success messages */}
      {message && (
        <Text mt={3} id="payment-message" color={dangerColor}>
          {message}
        </Text>
      )}
    </Box>
  );
}
