import { Box, Button, Heading, Link as StyledLink, Text } from "@chakra-ui/react";
import { Launch } from "@material-ui/icons";
import { Link } from "react-router-dom";

import * as C from "@svix/common/constants";

import { routeResolver } from "src/App";
import { ReactComponent as NotFound } from "src/img/no_data.inline.svg";

export default function TransformationTemplatesEmptyState(props: {
  transformationsEnabled: boolean;
}) {
  const { transformationsEnabled } = props;

  return (
    <Box
      maxWidth={800}
      margin="0 auto"
      p={4}
      textAlign="center"
      alignItems="center"
      display="flex"
      flexDirection="column"
    >
      <Box width="80%" maxW="240px">
        <NotFound width="100%" height="100%" />
      </Box>
      <Heading as="h2" size="md" mt={8} mb={2}>
        No Transformation Templates
      </Heading>
      <Text variant="caption">
        Transformation Templates let you provide pre-made integrations to connect your
        webhooks to other services.
      </Text>
      {transformationsEnabled ? (
        <Text variant="caption">
          <StyledLink
            as={Link}
            textDecoration="underline"
            to={routeResolver.getRoute("transformations.create.new")}
          >
            Create one
          </StyledLink>{" "}
          to get started.
        </Text>
      ) : (
        <Text variant="caption">
          To use them, enable Transformations in your{" "}
          <StyledLink
            as={Link}
            textDecoration="underline"
            to={routeResolver.getRoute("settings.organization.generalSettings")}
          >
            environment settings
          </StyledLink>
          .
        </Text>
      )}
      <Button
        as="a"
        mt={4}
        size="sm"
        variant="outline"
        rightIcon={<Launch fontSize="small" />}
        target="_blank"
        href={C.docs.advanced.transformations.templates}
      >
        Learn more
      </Button>
    </Box>
  );
}
