import { Box, Link, Stack, Text } from "@chakra-ui/react";
import { useForm, useWatch } from "react-hook-form";

import * as C from "@svix/common/constants";
import Card from "@svix/common/widgets/Card";
import CodeBlock from "@svix/common/widgets/Code";
import Form from "@svix/common/widgets/Form";
import TextField from "@svix/common/widgets/form/TextField";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
} from "@svix/common/widgets/PageToolbar";

import { routeResolver } from "src/App";
import { useRegion } from "src/store/selectors";

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};
const MASKED_ORG_KEY = "••••••••••••••••••••••••••••";

export default function ApplicationCreateScreen() {
  const region = useRegion();
  const formCtx = useForm({
    defaultValues: {
      name: "My App",
    },
  });

  const appName = useWatch({
    control: formCtx.control,
    name: "name",
  });

  const CREATE_APPLICATION_CODE_BLOCK = `\
curl -X POST "${C.envConfig.getServerUrl(region)}/api/v1/app/" \\
    -H  "Accept: application/json" \\
    -H  "Content-Type: application/json" \\
    -H  "Authorization: Bearer ${MASKED_ORG_KEY}" \\
    -d '{"name": "${appName}"}'\
`;

  return (
    <>
      <MetaTitle path={["Create Application"]} />
      <PageToolbar>
        <Breadcrumbs>
          <BreadcrumbItem to={routeResolver.getRoute("applications")}>
            Consumer Applications
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>New Application</BreadcrumbItem>
        </Breadcrumbs>
      </PageToolbar>

      <Card maxW="50em">
        <Text fontSize="md" mb={4}>
          A Consumer Application defines where your messages are sent. Usually you'll want
          one Application for each of your customers.
        </Text>
        <Text fontSize="md" mb={4}>
          Because of this, Applications are intended to be created only via{" "}
          <Link
            fontWeight="medium"
            color="blue.500"
            href={C.docs.quickstart.application}
            isExternal
          >
            our API
          </Link>
          .
        </Text>
        <Form shouldPromptOnDirty={false} onSubmit={noop} {...formCtx}>
          <Stack spacing={5}>
            <TextField
              maxW="30em"
              control={formCtx.control}
              name="name"
              label="Name"
              autoFocus
              required
              placeholder="e.g. My app"
            />
          </Stack>
        </Form>
        <Text fontSize="md" mt={12}>
          To create one, run the following snippet from your terminal:
        </Text>
        <Box my={4}>
          <CodeBlock
            copyToClipboard
            language="bash"
            code={CREATE_APPLICATION_CODE_BLOCK}
          />
        </Box>
      </Card>
    </>
  );
}
