import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: unknown[] = [];

const slice = createSlice({
  name: "errors",
  initialState,
  reducers: {
    pushError(state: unknown[], action: PayloadAction<unknown>) {
      state.push(action.payload);
    },
    popError(state: unknown[]) {
      state.shift();
    },
  },
});

export const { pushError, popError } = slice.actions;
export default slice.reducer;
