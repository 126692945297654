/**
 * Dashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { OrganizationEnvironment } from './OrganizationEnvironment';
import { OrganizationRegion } from './OrganizationRegion';
import { HttpFile } from '../http/http';

export class OrganizationOut {
    'createdAt': Date;
    'orgEnv': OrganizationEnvironment;
    'orgId': string;
    'orgName': string;
    'region': OrganizationRegion;
    'updatedAt': Date;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "createdAt",
            "baseName": "createdAt",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "orgEnv",
            "baseName": "orgEnv",
            "type": "OrganizationEnvironment",
            "format": ""
        },
        {
            "name": "orgId",
            "baseName": "orgId",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgName",
            "baseName": "orgName",
            "type": "string",
            "format": ""
        },
        {
            "name": "region",
            "baseName": "region",
            "type": "OrganizationRegion",
            "format": ""
        },
        {
            "name": "updatedAt",
            "baseName": "updatedAt",
            "type": "Date",
            "format": "date-time"
        }    ];

    static getAttributeTypeMap() {
        return OrganizationOut.attributeTypeMap;
    }
    
    public constructor() {
    }
}

