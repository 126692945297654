import { MenuItem, Tr, useToast } from "@chakra-ui/react";
import { useQueryClient } from "react-query";

import TableCell from "@svix/common/widgets/TableCell";
import TableRowMenu from "@svix/common/widgets/TableRowMenu";

import { getApiConfiguration } from "src/api";
import { InvitationApi, InviteOut } from "src/generated/dashboard-openapi";
import { useAppDispatch, useAppSelector } from "src/hooks/store";
import { pushError } from "src/store/errors";
import { useIsAdmin } from "src/store/selectors";
import { useSvixAuth } from "src/utils";
import RolesSummaryTag from "./RolesSummaryTag";

interface IInviteRowProps {
  invite: InviteOut;
}

export default function InviteRow(props: IInviteRowProps) {
  const { invite } = props;
  const { user } = useSvixAuth();
  const dispatch = useAppDispatch();
  const toast = useToast();
  const queryClient = useQueryClient();
  const isAdmin = useIsAdmin();
  const isInviter = user?.email === invite.inviterEmail;

  const envs = useAppSelector((state) => state.auth.environments);

  async function revokeInvite() {
    const api = new InvitationApi(await getApiConfiguration());

    toast({
      title: "Invitation revoked",
      description: invite.inviteEmail,
      status: "info",
      duration: 3000,
      isClosable: true,
    });
    try {
      await api.deleteInviteInviteOrgGroupInviteIdDelete(invite.orgGroupInviteId);
      queryClient.invalidateQueries(["outgoingInvites", "pending"]);
    } catch (error) {
      dispatch(pushError(error));
    }
  }

  return (
    <Tr>
      <TableCell>{invite.inviteEmail}</TableCell>
      <TableCell w="20%">
        <RolesSummaryTag
          defaultRole={invite.role}
          envs={envs}
          overrides={invite.overrides}
        />
      </TableCell>
      {(isAdmin || isInviter) && (
        <TableRowMenu>
          <MenuItem onClick={revokeInvite}>Revoke invite</MenuItem>
        </TableRowMenu>
      )}
    </Tr>
  );
}
