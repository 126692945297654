/**
 * Dashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { OrganizationGroupRow } from './OrganizationGroupRow';
import { HttpFile } from '../http/http';

export class StatsOut {
    'now': Date;
    'orgGroups': { [key: string]: OrganizationGroupRow; };

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "now",
            "baseName": "now",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "orgGroups",
            "baseName": "orgGroups",
            "type": "{ [key: string]: OrganizationGroupRow; }",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return StatsOut.attributeTypeMap;
    }
    
    public constructor() {
    }
}

