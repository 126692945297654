import * as React from "react";
import {
  Box,
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  HStack,
  Text,
  Heading,
  List,
  ListItem,
  ListIcon,
} from "@chakra-ui/react";
import { Check } from "@material-ui/icons";

import * as C from "@svix/common/constants";

interface PaymentPlanCardProps {
  actionLabel: string;
  plan: Plan;
}

import { SubscriptionEnum } from "src/generated/dashboard-openapi";

export interface Plan {
  planName: SubscriptionEnum;
  accent: string;
  title: string;
  lineItems: React.ReactNode[];
  price: string;
}

export default function PaymentPlanCard(props: PaymentPlanCardProps) {
  return (
    <Box bgColor={props.plan.accent} padding="2em" color="white">
      <Stat>
        <StatLabel>{props.actionLabel}</StatLabel>
        <StatNumber fontSize="3xl" textShadow="0px 1px 2px rgba(0, 0, 0, 0.15)">
          {props.plan.title}
        </StatNumber>
      </Stat>
      <HStack mt="1em" spacing=".2em">
        <Text fontSize="xl" fontWeight="semibold">
          {props.plan.price}
        </Text>
        <Text fontSize="lg">/ month</Text>
      </HStack>
      <Heading mt={6} mb={1} as="h3" size="sm" color="white">
        Included in this plan:
      </Heading>
      <Flex flexDir="column" w="100%">
        <List>
          {props.plan.lineItems.map((line, index) => (
            <ListItem key={index}>
              <ListIcon as={Check} color="white" />
              {line}
            </ListItem>
          ))}
        </List>
        <Text mt="1em" as="div" decoration="underline">
          <a href={C.pricingUrl}>More information</a>
        </Text>
      </Flex>
    </Box>
  );
}

export const planColorAccents: { [k in SubscriptionEnum]: string } = {
  free: "blue.300",
  starter: "blue.400",
  business: "blue.500",
  enterprise: "blue.600",
};
