import * as React from "react";
import { Link, LinkProps } from "@chakra-ui/react";

interface Props extends LinkProps {
  email: string;
}

export default function EmailLink(props: React.PropsWithChildren<Props>) {
  const { email, ...rest } = props;
  return <Link href={`mailto:${email}`} {...rest} />;
}
