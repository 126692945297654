import * as React from "react";
import { Box, Flex, Skeleton, Text, useColorModeValue, useToken } from "@chakra-ui/react";
import { ResponsiveLine } from "@nivo/line";
import { format } from "d3-format";

function formatTime(time: Date): string {
  return time.toLocaleString(undefined, {
    hour: "numeric",
    minute: "2-digit",
  });
}

interface ITimeCoord {
  x: Date;
  y: number;
}

interface AttemptCountData {
  id: string;
  data: ITimeCoord[];
}

interface ILineChartProps {
  startTime: Date;
  endTime: Date;
  data: AttemptCountData[];
  emptyState: string | React.ReactNode;
  isLoading?: boolean;
}

function getPlaceholderData() {
  const FIVE_MINUTES = 5 * 60 * 60 * 1000;
  const startTime = new Date();
  return [
    {
      id: "placeholder",
      data: Array(32)
        .fill(0)
        .map((_, i) => ({
          x: new Date(startTime.getTime() + i * FIVE_MINUTES),
          y: Math.floor(Math.random() * 100),
        })),
    },
  ];
}

export default function TimeIntervalLineChart({
  data,
  emptyState,
  isLoading = false,
}: ILineChartProps) {
  const emptyColorName = useColorModeValue("gray.200", "gray.700");
  const tickColorName = useColorModeValue("gray.500", "gray.600");

  const emptyColor = useToken("colors", emptyColorName);
  const tickColor = useToken("colors", tickColorName);

  const hasData = data.some((record) => record.data.some((time) => time.y > 0));

  return (
    <Skeleton isLoaded={!isLoading} h="100%" w="100%">
      <ResponsiveLine
        data={hasData ? data : getPlaceholderData()}
        enableSlices="x"
        xScale={{ type: "time", precision: "minute" }}
        xFormat="time:%Y-%m-%d"
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: true,
          reverse: false,
        }}
        yFormat=" >-.2f"
        margin={{ top: 20, right: 20, bottom: 40, left: 40 }}
        theme={{
          axis: {
            ticks: {
              line: {
                stroke: "transparent",
              },
              text: {
                fill: tickColor,
                letterSpacing: "loose",
                fontWeight: "bold",
              },
            },
          },
          grid: {
            line: {
              stroke: emptyColor,
            },
          },
        }}
        areaOpacity={0.08}
        axisBottom={{
          format: "%H:%M",
        }}
        enableArea
        sliceTooltip={(input) => (
          <Box
            py={2}
            px={4}
            rounded="xl"
            bg="gray.700"
            color="white"
            boxShadow="2xl"
            alignItems="flex-end"
          >
            <Text fontSize="xs" fontWeight="semibold" my={1}>
              {formatTime(input.slice.points[0].data.x as unknown as Date)}
            </Text>
            {input.slice.points.map((pt) => (
              <Flex key={pt.id} justifyContent="space-between" color={pt.serieColor}>
                <Text mr={3}>{pt.serieId}</Text>
                <Text letterSpacing="wider">{format(",")(pt.data.y as number)}</Text>
              </Flex>
            ))}
          </Box>
        )}
        {...(!hasData && {
          axisBottom: null,
          axisLeft: null,
          enableGridX: false,
          enableGridY: false,
          colors: [emptyColor],
        })}
      />
      {!hasData && (
        <Box
          display="flex"
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          px={4}
          position="absolute"
          h="100%"
          top={0}
          bottom={0}
          right={0}
          left={0}
          zIndex={1}
        >
          {emptyState}
        </Box>
      )}
    </Skeleton>
  );
}
