import { useEffect, useState } from "react";
import {
  HStack,
  Link,
  Stack,
  Text,
  Icon,
  FormControl,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import { Brightness7, Brightness3 } from "@material-ui/icons";
import { useForm } from "react-hook-form";

import * as C from "@svix/common/constants";
import { stripEmptyFields } from "@svix/common/formUtils";
import Button from "@svix/common/widgets/Button";
import Card from "@svix/common/widgets/Card";
import Code from "@svix/common/widgets/Code";
import TextField from "@svix/common/widgets/form/TextField";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
} from "@svix/common/widgets/PageToolbar";
import { AppPortalThemeProvider } from "@svix/common/widgets/ThemeProvider";

import { SettingsInternalIn } from "src/generated/dashboard-openapi";
import { useAppDispatch, useAppSelector } from "src/hooks/store";
import { setSettings } from "src/store/settings";
import { WhiteLabelSettingsForm } from ".";

export const WhitelabelSettingsSelfHostWizard = () => {
  const [key, setKey] = useState(0);
  const formCtx = useForm<SettingsInternalIn>();
  const { watch } = formCtx;

  // Force remount of the theme provider when the form changes
  const allValues = watch();
  const valuesJson = JSON.stringify(allValues);
  useEffect(() => {
    setKey((prev) => prev + 1);
  }, [valuesJson, setKey]);

  const darkModeUserSetting = useAppSelector((state) => state.settings.darkMode);
  const emptyForm = useForm(); // For the sample input

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const doNothing = async () => {};

  return (
    <>
      <MetaTitle path={["White Labeling"]} />
      <PageToolbar>
        <Breadcrumbs>
          <BreadcrumbItem>White Labeling Configuration Wizard</BreadcrumbItem>
        </Breadcrumbs>
      </PageToolbar>
      <HStack>
        <Card title="White Labeling" maxW="50em">
          <Text size="md" variant="caption">
            Customize how the{" "}
            <Link target="_blank" href={C.docs.appPortal} color="teal.500">
              Consumer App Portal
            </Link>{" "}
            will look for your users in this environment.
          </Text>
          <WhiteLabelSettingsForm
            formCtx={formCtx}
            onSubmit={doNothing}
            showSubmitButton={false}
          />
        </Card>

        {/* FIXME: Chakra has a lot of issues with having nested Theme Providers. When this component is mounted, it 
            impacts the root theme as well. This works for this simple tool, but we need to find a way to 
            show a theme preview without rendering a new theme provider. */}
        <AppPortalThemeProvider
          key={key}
          darkMode={darkModeUserSetting}
          customFontFamily={watch("customFontFamily")}
          customFontFamilyUrl={watch("customFontFamilyUrl")}
          primaryLightOverride={watch("colorPaletteLight.primary")}
          primaryDarkOverride={watch("colorPaletteDark.primary")}
          paletteDark={watch("colorPaletteDark")}
          paletteLight={watch("colorPaletteLight")}
          themeOverrides={watch("customThemeOverride")}
        >
          <Stack>
            <Card title="Preview" w="50em" cta={<ColorModeToggle />}>
              <Card title="Example card">
                <Stack spacing={8}>
                  <Text color="text.primary">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </Text>
                  <FormControl isInvalid>
                    <TextField
                      name="example"
                      label="Input example"
                      control={emptyForm.control}
                      placeholder="Sample text input"
                    />
                  </FormControl>
                  <Button>Example button</Button>
                </Stack>
              </Card>
            </Card>
            <Card w="50em">
              <Code
                copyToClipboard
                code={getEEAppPortalConfig(allValues)}
                title="app_portal_whitelabel_config"
                language="javascript"
              />
            </Card>
          </Stack>
        </AppPortalThemeProvider>
      </HStack>
    </>
  );
};

function ColorModeToggle() {
  const dispatch = useAppDispatch();

  const darkMode = useAppSelector((state) => state.settings.darkMode);

  return (
    <Tooltip label="Preview dark mode" aria-label="Preview dark mode">
      <IconButton
        aria-label="toggle dark mode"
        onClick={() => {
          dispatch(setSettings({ darkMode: !darkMode }));
        }}
      >
        {darkMode ? (
          <Icon as={Brightness7} />
        ) : (
          <Icon transform="auto" rotate={9} as={Brightness3} />
        )}
      </IconButton>
    </Tooltip>
  );
}

const getEEAppPortalConfig = (formValues: SettingsInternalIn) => {
  let config: any = stripEmptyFields(formValues);
  delete config.primaryLight;
  delete config.primaryDark;

  config = {
    ...config,
    colorPaletteDark: {
      ...config.colorPaletteDark,
      surfaceBackground: config.colorPaletteDark?.backgroundSecondary,
      surfaceHeader: config.colorPaletteDark?.backgroundHover,
    },
    colorPaletteLight: {
      ...config.colorPaletteLight,
      surfaceBackground: config.colorPaletteLight?.backgroundSecondary,
      surfaceHeader: config.colorPaletteLight?.backgroundHover,
    },
  };

  return JSON.stringify(JSON.stringify(formValues));
};
