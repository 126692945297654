/**
 * Dashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { OrganizationOutEE } from './OrganizationOutEE';
import { HttpFile } from '../http/http';

export class ListResponseOrganizationOutEE {
    'data': Array<OrganizationOutEE>;
    'done': boolean;
    'iterator'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "data",
            "baseName": "data",
            "type": "Array<OrganizationOutEE>",
            "format": ""
        },
        {
            "name": "done",
            "baseName": "done",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "iterator",
            "baseName": "iterator",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return ListResponseOrganizationOutEE.attributeTypeMap;
    }
    
    public constructor() {
    }
}

