import * as React from "react";
import {
  Button,
  ButtonProps,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Text,
} from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";

import { getApiConfiguration } from "src/api";
import { routeResolver } from "src/App";
import { OrganizationSettingsApi } from "src/generated/dashboard-openapi";
import { useSvixAuth } from "src/utils";

export default function AuthAlertModal() {
  const [showMFARequiredModal, setShowMFARequiredModal] = React.useState(false);
  const [showSSORequiredModal, setShowSSORequiredModal] = React.useState(false);
  const { pathname } = useLocation();
  const { logout } = useSvixAuth();

  function logoutClicked() {
    logout({ logoutParams: { returnTo: window.location.origin } });
  }

  // FIXME: We need a better way to check if the user has MFA in every page.
  // At the moment, there is no global error handler where we can do that. It will require a larger refactor.
  // This works for now, the only downside is that it sends an extra API call every time the user navigates to a new page.
  React.useEffect(() => {
    const inSettingsPage = pathname === routeResolver.getRoute("settings.user.account");

    if (inSettingsPage) {
      // If we're in the account page, don't show the modal to let the user enable MFA
      setShowMFARequiredModal(false);
    }

    const checkMFA = async () => {
      try {
        const config = await getApiConfiguration();
        const orgSettingsApi = new OrganizationSettingsApi(config);
        await orgSettingsApi.settingsGetOrganizationSettingsGet();
      } catch (e) {
        if (!inSettingsPage) {
          if (e?.body?.code === "mfa_required_by_org") {
            setShowMFARequiredModal(true);
          } else if (e?.body?.code === "sso_required_by_org") {
            setShowSSORequiredModal(true);
          }
        }
      }
    };

    checkMFA();
  }, [pathname]);

  return (
    <>
      <MessageModal
        isOpen={showMFARequiredModal}
        title="Multi-Factor Authentication Required"
        message="Your organization requires you to set up multi-factor authentication (MFA) in your Svix account. Go to your account page to set it up."
        ctaProps={{
          as: Link,
          to: routeResolver.getRoute("settings.user.account"),
          children: "Setup MFA",
        }}
      />
      <MessageModal
        isOpen={showSSORequiredModal}
        title="Single Sign-On Required"
        message="Your organization requires you to use Single Sign-On (SSO) to log in to Svix. Please log out and log in again using your SSO provider."
        ctaProps={{
          as: "button",
          onClick: logoutClicked,
          children: "Logout",
        }}
      />
    </>
  );
}

interface CtaProps extends ButtonProps {
  as?: React.ElementType;
  to?: string;
  onClick?: () => void;
  children: React.ReactNode;
}

interface IMessageModalProps {
  isOpen: boolean;
  title: string;
  message: string;
  ctaProps?: CtaProps;
}

const MessageModal = ({ isOpen, title, message, ctaProps }: IMessageModalProps) => {
  // Don't allow closing the modal
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onClose = () => {};

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="auto" backdropBlur="2px" />
      <ModalContent borderRadius="lg">
        <ModalHeader
          bgGradient="linear(to-r, purple.300, purple.400, blue.400)"
          pt="3em"
          pb="1em"
          borderTopRadius="lg"
          color="white"
          fontSize="3xl"
        >
          {title}
        </ModalHeader>
        <ModalBody mt={4}>
          <Text my={3} fontSize="md">
            {message}
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button mr={2} variant="outline" colorScheme="purple" {...ctaProps} />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
