/**
 * Dashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class ZapierIntegrationPackageOut {
    'blob': HttpFile;
    'mime': ZapierIntegrationPackageOutMimeEnum;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "blob",
            "baseName": "blob",
            "type": "HttpFile",
            "format": "binary"
        },
        {
            "name": "mime",
            "baseName": "mime",
            "type": "ZapierIntegrationPackageOutMimeEnum",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return ZapierIntegrationPackageOut.attributeTypeMap;
    }
    
    public constructor() {
    }
}


export type ZapierIntegrationPackageOutMimeEnum = "application/x-zip-compressed" ;

