import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";

import { useSearch } from "@svix/common/hooks/search";
import { logError } from "@svix/common/logger";
import { withRetries } from "@svix/common/utils";

import { getApiConfiguration } from "src/api";
import { useAppDispatch, useAppSelector } from "src/hooks/store";
import { sendLoginFinished } from "src/store/auth";
import { enableFlag, isFlag } from "src/store/featureFlags";
import { setSettings } from "src/store/settings";
import { SvixUser, useLoading, useSvixAuth } from "src/utils";

interface IAuthedContainerProps {
  children: React.ReactNode;
}

function useFeatureFlags() {
  const dispatch = useDispatch();
  const flags = useSearch("flags");

  useEffect(() => {
    if (flags) {
      for (const flag of flags.split(",")) {
        if (isFlag(flag)) {
          dispatch(enableFlag(flag));
        }
      }
    }
  }, [flags, dispatch]);
}

function useTrackPageviews() {
  const location = useLocation();
  useEffect(() => {
    (async () => {
      try {
        await window.analytics!.page();
      } catch (e) {
        logError(e);
      }
    })();
  }, [location]);
}

function useIdentifyUser(user: SvixUser | undefined) {
  const { orgGroupId } = useAppSelector((state) => state.auth);

  useEffect(() => {
    Sentry.setUser({ id: user?.sub });

    if (user?.sub && orgGroupId) {
      window.analytics?.identify(user.sub, { email: user.email, orgGroupId });
    }
  }, [user, orgGroupId]);
}

function useUserMode() {
  const dispatch = useAppDispatch();
  const engineerParam = useSearch("_svix_engineer");

  useEffect(() => {
    if (engineerParam) {
      dispatch(setSettings({ engineerMode: engineerParam === "true" }));
    }
  }, [engineerParam, dispatch]);
}

export default function AuthedContainer({ children }: IAuthedContainerProps) {
  const dispatch = useAppDispatch();
  const { user } = useSvixAuth();
  const { isLoggedIn, emailVerified } = useAppSelector((state) => state.auth);
  useFeatureFlags();
  useTrackPageviews();
  useIdentifyUser(user);
  useUserMode();

  useLoading(async () => {
    // Don't use the stored active env, in case the user
    // has logged in with a different account or the env does not exist anymore.
    const config = await getApiConfiguration({ noEnvSelected: true });

    // Finish loading user
    try {
      await withRetries(() => sendLoginFinished(config, dispatch));
    } catch (e) {
      if (e.code !== "login_failed_account_exists") {
        logError(e);
      }
      throw e;
    }
  }, []);

  useEffect(() => {
    if (!isLoggedIn || emailVerified) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
    }

    // Poll for email verification
    const intervalId = setInterval(async () => {
      const config = await getApiConfiguration();
      const res = await sendLoginFinished(config, dispatch);

      if (res.emailVerified) {
        clearInterval(intervalId);
      }
    }, 1000 * 5);

    return () => clearInterval(intervalId);
  }, [dispatch, isLoggedIn, emailVerified]);

  return <>{children}</>;
}
