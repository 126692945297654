import { Icon } from "@chakra-ui/react";

import { ReactComponent as Amex } from "src/img/cardBrands/amex.inline.svg";
import { ReactComponent as Diners } from "src/img/cardBrands/diners.inline.svg";
import { ReactComponent as Discover } from "src/img/cardBrands/discover.inline.svg";
import { ReactComponent as Generic } from "src/img/cardBrands/generic.inline.svg";
import { ReactComponent as Jcb } from "src/img/cardBrands/jcb.inline.svg";
import { ReactComponent as Mastercard } from "src/img/cardBrands/mastercard.inline.svg";
import { ReactComponent as Unionpay } from "src/img/cardBrands/unionpay.inline.svg";
import { ReactComponent as Visa } from "src/img/cardBrands/visa.inline.svg";

interface ICreditCardIconProps {
  brand: string;
}

export default function CreditCardIcon(props: ICreditCardIconProps) {
  let Card = Generic;

  switch (props.brand) {
    case "American Express":
      Card = Amex;
      break;
    case "Diners Club":
      Card = Diners;
      break;
    case "Discover":
      Card = Discover;
      break;
    case "JCB":
      Card = Jcb;
      break;
    case "MasterCard":
      Card = Mastercard;
      break;
    case "UnionPay":
      Card = Unionpay;
      break;
    case "Visa":
      Card = Visa;
      break;
  }
  return <Icon mr={3} as={Card} width="24px" height="24px" />;
}
