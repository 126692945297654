import {
  Box,
  ModalFooter,
  Button,
  Stack,
  RadioGroup,
  Radio,
  FormLabel,
  Text,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";

import { logError } from "@svix/common/logger";
import Select from "@svix/common/widgets/form/Select";
import TextArea from "@svix/common/widgets/form/TextArea";

import { getApiConfiguration } from "src/api";
import { AuthenticationApi, QuestionnaireIn } from "src/generated/dashboard-openapi";
import { trackEvent } from "../../analytics";

interface WelcomeQuestionnaireProps {
  onClose: () => void;
}

export default function WelcomeQuestionnaire(props: WelcomeQuestionnaireProps) {
  const { handleSubmit, control, register, watch } = useForm<QuestionnaireIn>({
    defaultValues: {
      alreadySending: "",
      howMany: "",
      mainResponsibility: "",
      mainResponsibilityOther: "",
      defineSuccess: "",
      referredBy: "",
    },
  });

  const onSubmit = async (form: QuestionnaireIn) => {
    try {
      const config = await getApiConfiguration();
      const authApi = new AuthenticationApi(config);
      // Don't 'await', so it doesn't block progress in the onboarding
      authApi.setWelcomeQuestionnaireAuthenticationWelcomeQuestionnairePost({
        ...form,
      });
    } catch (e) {
      logError("Error submitting questionnaire");
    }

    trackEvent("Questionnaire submitted");

    props.onClose();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" spacing={4} padding="2em" pb="0em">
        <Box>
          <FormLabel>Are you already sending webhooks?</FormLabel>
          <RadioGroup>
            <Stack direction="row">
              <Radio value="true" {...register("alreadySending", { required: true })}>
                Yes
              </Radio>
              <Radio value="false" {...register("alreadySending", { required: true })}>
                No
              </Radio>
            </Stack>
          </RadioGroup>
        </Box>
        <Box>
          <FormLabel>How many webhooks are you sending or plan on sending?</FormLabel>
          <Select name="howMany" placeholder="Choose one" control={control} isRequired>
            <option value="0-50,000">0-50,000 per month</option>
            <option value="50,000-500,000">50,000-500,000 per month</option>
            <option value="500,000-2,000,000">500,000-2,000,000 per month</option>
            <option value="2,000,000-30,000,000">2,000,000-30,000,000 per month</option>
            <option value="30,000,00+">30,000,00+ per month</option>
          </Select>
        </Box>
        <Box>
          <FormLabel>What is your main responsibility?</FormLabel>
          <Select
            placeholder="Choose one"
            name="mainResponsibility"
            control={control}
            isRequired
          >
            <option value="Product">Product</option>
            <option value="Engineering">Engineering</option>
            <option value="DevOps">DevOps</option>
            <option value="Sales">Sales</option>
            <option value="Marketing">Marketing</option>
            <option value="Other">Other</option>
          </Select>
          {watch("mainResponsibility") === "Other" && (
            <Box mt=".5em">
              <Text>Please specify:</Text>
              <TextArea name="mainResponsibilityOther" control={control} isRequired />
            </Box>
          )}
        </Box>
        <Box>
          <FormLabel>
            What would constitute success for you when evaluating Svix?
          </FormLabel>
          <TextArea name="defineSuccess" control={control} required />
        </Box>
        <Box>
          <FormLabel>How did you hear about us? (Optional)</FormLabel>
          <TextArea name="referredBy" control={control} isRequired={false} />
        </Box>
      </Stack>
      <ModalFooter>
        <Button colorScheme="gray" onClick={props.onClose} mr=".5em">
          Skip
        </Button>
        <Button colorScheme="blue" type="submit">
          Submit
        </Button>
      </ModalFooter>
    </form>
  );
}
