/**
 * Dashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { BorderRadiusConfig } from './BorderRadiusConfig';
import { FontSizeConfig } from './FontSizeConfig';
import { HttpFile } from '../http/http';

export class CustomThemeOverride {
    'borderRadius'?: BorderRadiusConfig;
    'fontSize'?: FontSizeConfig;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "borderRadius",
            "baseName": "borderRadius",
            "type": "BorderRadiusConfig",
            "format": ""
        },
        {
            "name": "fontSize",
            "baseName": "fontSize",
            "type": "FontSizeConfig",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return CustomThemeOverride.attributeTypeMap;
    }
    
    public constructor() {
    }
}

