import { Box, HStack, Flex } from "@chakra-ui/react";
import { produce, Draft } from "immer";
import size from "lodash/size";

import Button from "@svix/common/widgets/Button";
import { JSONSchema7 } from "@svix/common/widgets/JsonSchema/types";

import SchemaItem from "./SchemaItem";
import { findAvailableFieldName, getDefaultSchema, UI_LEVEL_PX_OFFSET } from "./utils";

export interface ISchemaObjectProps {
  definitions: JSONSchema7["definitions"];
  level: number;
  schema: JSONSchema7;
  setSchema: (mutations: (draft: Draft<JSONSchema7>) => void) => void;
}

const renameObjKey = (oldObj: any, oldKey: string, newKey: string) => {
  const keys = Object.keys(oldObj);
  const newObj = keys.reduce((acc, val) => {
    if (val === oldKey) {
      acc[newKey] = oldObj[oldKey];
    } else {
      acc[val] = oldObj[val];
    }
    return acc;
  }, {} as any);

  return newObj;
};

export default function SchemaObject(props: ISchemaObjectProps) {
  const { definitions, level, schema, setSchema } = props;
  const offsetPx = `${level * UI_LEVEL_PX_OFFSET}px`;
  const lineOffsetPx = `${(level - 1) * UI_LEVEL_PX_OFFSET}px`;

  const addChild = () => {
    setSchema((draft) => {
      const fieldName = findAvailableFieldName(draft);
      if (!draft.properties) {
        draft.properties = {};
      }
      draft.properties[fieldName] = getDefaultSchema("string");
      if (!draft.required) {
        draft.required = [];
      }
      draft.required.push(fieldName);
    });
  };

  return (
    <Flex flexDir="column" data-schema-level={level} w="100%" position="relative">
      <Box
        my={2}
        height="calc(100% - 12px)"
        position="absolute"
        borderLeft="1px solid"
        borderColor="background.modifier.border"
        left={lineOffsetPx}
      />
      {Object.entries(schema.properties || {}).map(([name, itemSchema], idx) => (
        <SchemaItem
          definitions={definitions}
          level={level}
          key={`${size(schema.properties)}-${idx}`} // rerender the list if item is deleted to avoid stale data
          schema={itemSchema}
          deleteItem={() => {
            setSchema((draft) => {
              delete draft.properties![name];
              draft.required = draft.required?.filter((req) => req !== name);
            });
          }}
          toggleRequired={(required: boolean) => {
            setSchema((draft) => {
              if (required) {
                if (!draft.required) {
                  draft.required = [];
                }
                draft.required.push(name);
              } else {
                draft.required = draft.required?.filter((req) => req !== name);
              }
            });
          }}
          renameItem={(newName: string) => {
            setSchema((draft) => {
              if (draft.properties?.[newName] !== undefined) {
                newName = findAvailableFieldName(draft, newName);
              }
              const requiredIdx = draft.required?.indexOf(name);
              if (requiredIdx !== undefined && requiredIdx !== -1) {
                draft.required![requiredIdx] = newName;
              }

              draft.properties = renameObjKey(draft.properties, name, newName);
            });
          }}
          setSchema={(mutations) => {
            setSchema((draft) => {
              draft.properties![name] = produce(itemSchema, mutations);
            });
          }}
          changeType={(itemName: string, newType: string) => {
            setSchema((draft) => {
              if (!draft.properties) {
                draft.properties = {};
              }
              draft.properties[itemName] = getDefaultSchema(newType);
            });
          }}
          name={name}
          required={schema.required}
        />
      ))}
      <HStack ml={offsetPx} my={2}>
        <Button variant="outline" size="xs" onClick={addChild}>
          Add Child
        </Button>
      </HStack>
    </Flex>
  );
}
