import { useState } from "react";
import {
  Alert,
  AlertIcon,
  AlertDescription,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Heading,
} from "@chakra-ui/react";
import { useQueryClient } from "react-query";
import { useParams, Link, Redirect } from "react-router-dom";

import { useSearch } from "@svix/common/hooks/search";
import { getApiError } from "@svix/common/utils";
import Card from "@svix/common/widgets/Card";
import LoadingIndicator from "@svix/common/widgets/LoadingIndicator";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";

import { getApiConfiguration } from "src/api";
import { routeResolver } from "src/App";
import { BillingApi, SubscriptionEnum } from "src/generated/dashboard-openapi";
import { useLoading } from "src/utils";
import { trackEvent } from "../../analytics";

export default function PaymentCompleteScreen() {
  const queryClient = useQueryClient();
  const setupIntentId = useSearch("setup_intent") ?? "";
  const { planName } = useParams<{ planName: SubscriptionEnum }>();
  const [error, setError] = useState("");

  const [subscription, _, isLoading] = useLoading(async () => {
    const config = await getApiConfiguration();
    const billingApi = new BillingApi(config);

    try {
      const res = await billingApi.changeSubscriptionBillingSubscriptionPatch(
        {
          planName,
        },
        setupIntentId
      );
      await queryClient.invalidateQueries("billing");
      trackEvent("Successful Payment Form submission");
      return res;
    } catch (err) {
      setError(getApiError(err));
    }

    return undefined;
  }, []);

  if (error) {
    // rebase to get resource error
    return <Box />;
  }

  if (setupIntentId === "") {
    return <Redirect to={routeResolver.getRoute("billing")} />;
  }

  return (
    <>
      <MetaTitle path={["Payment"]} />
      <Breadcrumb fontSize="md" mb={8}>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to={routeResolver.getRoute("billing")}>
            Billing
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to={routeResolver.getRoute("billing.payment")}>
            Payment Info
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>Finalizing</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Card mx="auto" mt="20vh" padding="2em" maxW="24em">
        {error && (
          <Alert status="error">
            <AlertIcon />
            <AlertDescription>Something went wrong.</AlertDescription>
          </Alert>
        )}

        {isLoading && (
          <Box textAlign="center">
            <Heading as="h2" size="md" mb={8}>
              Upgrading your subscription...
            </Heading>
            <LoadingIndicator />
          </Box>
        )}

        {!!subscription && !error && (
          <Box textAlign="center">
            <Heading as="h2" size="md" mb={8}>
              Successfully upgraded your subscription
            </Heading>
            <LoadingIndicator />
            <Redirect to={routeResolver.getRoute("billing")} />
          </Box>
        )}
      </Card>
    </>
  );
}
