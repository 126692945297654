import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AnyAction, Dispatch } from "redux";

import { logWarning } from "@svix/common/logger";

import {
  AuthenticationApi,
  Configuration,
  LoginFinishedOut,
  OrganizationEEApi,
  OrganizationOut,
  OrganizationRole,
} from "src/generated/dashboard-openapi";
import { Feature } from "src/generated/dashboard-openapi/models/Feature";

const isEE = import.meta.env.MODE === "ee";

export interface IAuth {
  isLoggedIn: boolean;
  features: Set<Feature>;
  isNewCustomer: boolean;
  role: OrganizationRole | null;
  orgGroupId: string | null;
  activeEnvId: string | null;
  environments: OrganizationOut[];
  emailVerified: boolean;
  userId: string | null;
  fullName?: string;
  companyName?: string;

  // The injected JWTs for the ee dashboard
  eeAdminToken?: string;
  eeOrgTokens?: Record<string, string>;
}

const initialState: IAuth = {
  isLoggedIn: false,
  features: new Set(),
  isNewCustomer: false,
  role: null,
  orgGroupId: null,
  activeEnvId: null,
  emailVerified: false,
  environments: [],
  userId: null,
  eeOrgTokens: {},
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    switchEnvironment(state: IAuth, action: PayloadAction<OrganizationOut>) {
      state.activeEnvId = action.payload.orgId;
    },
    setLoginInfo(state: IAuth, action: PayloadAction<LoginFinishedOut>) {
      const {
        emailVerified,
        orgGroupRole,
        orgGroupId,
        orgs,
        created,
        features,
        userId,
        fullName,
        companyName,
      } = action.payload;

      state.isLoggedIn = true;
      state.role = orgGroupRole;
      state.orgGroupId = orgGroupId;
      state.environments = orgs;
      state.isNewCustomer = created;
      state.features = new Set(features);
      state.emailVerified = emailVerified;
      state.userId = userId;
      state.fullName = fullName;
      state.companyName = companyName;

      if (
        !state.activeEnvId ||
        !state.environments.find((env) => env.orgId === state.activeEnvId)
      ) {
        state.activeEnvId = state.environments[0].orgId;
      }
    },
    switchOrganization(
      state: IAuth,
      action: PayloadAction<{ newOrgGroupId: string; newRole: OrganizationRole }>
    ) {
      state.orgGroupId = action.payload.newOrgGroupId;
      state.role = action.payload.newRole;
      state.activeEnvId = null;
      state.environments = [];
    },
    updateUserInfo(
      state: IAuth,
      action: PayloadAction<{ fullName: string; companyName?: string }>
    ) {
      const { fullName, companyName } = action.payload;
      state.fullName = fullName;
      state.companyName = companyName;
    },
    logoutEE(state: IAuth, _action: PayloadAction<void>) {
      state.isLoggedIn = false;
      state.features = new Set();
      state.eeAdminToken = undefined;
      state.eeOrgTokens = {};
    },
    setEEAdminToken(state: IAuth, action: PayloadAction<string | undefined>) {
      state.eeAdminToken = action.payload;
    },
    setEEOrgTokens(
      state: IAuth,
      action: PayloadAction<Record<string, string> | undefined>
    ) {
      state.eeOrgTokens = action.payload;
    },
  },
});

export const {
  setLoginInfo,
  updateUserInfo,
  switchOrganization,
  setEEOrgTokens,
  setEEAdminToken,
  logoutEE,
} = slice.actions;
export default slice.reducer;

export async function switchEnvironment(
  org: OrganizationOut,
  dispatch: Dispatch<AnyAction>
) {
  dispatch(slice.actions.switchEnvironment(org));
  refreshLoginInfo(dispatch);
}

export async function refreshLoginInfo(dispatch: Dispatch<AnyAction>) {
  // FIXME: lazy import to avoid circular dependency. We need a better solution.
  if (!isEE) {
    const { getApiConfiguration } = await import("src/api");
    const config = await getApiConfiguration();
    await sendLoginFinished(config, dispatch);
  } else {
    const { getEEAdminApiConfiguration } = await import("src/api");
    const config = await getEEAdminApiConfiguration();
    const orgApi = new OrganizationEEApi(config);
    const orgs = await orgApi.listOrgsEeOrganizationGet();
    dispatch(
      setLoginInfo({
        orgs: orgs.data,
        created: false,
        emailVerified: true,
        features: [],
        orgGroupId: "self_host_org",
        orgGroupRole: "Admin",
        userId: "admin",
      })
    );
    dispatch(
      setEEOrgTokens(Object.fromEntries(orgs.data.map((org) => [org.orgId, org.envJwt])))
    );
  }
}

export async function sendLoginFinished(
  config: Configuration,
  dispatch: Dispatch<AnyAction>
): Promise<LoginFinishedOut> {
  let anonymousId;
  try {
    anonymousId = window.analytics?.user().anonymousId() || undefined;
  } catch (e) {
    logWarning(e);
  }
  const authApi = new AuthenticationApi(config);
  const res = await authApi.getOrCreateUserAuthenticationLoginFinishedPost({
    anonymousId,
  });
  dispatch(setLoginInfo(res));
  return res;
}
