import { useEffect } from "react";
import {
  Box,
  Code,
  Divider,
  Heading,
  Link as StyledLink,
  Stack,
  Text,
  useToast,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import * as yup from "yup";

import { stripEmptyFields, setErrors } from "@svix/common/formUtils";
import Card from "@svix/common/widgets/Card";
import Form, { GeneralFormErrors } from "@svix/common/widgets/Form";
import TextField from "@svix/common/widgets/form/TextField";
import Toggle from "@svix/common/widgets/form/Toggle";
import Link from "@svix/common/widgets/Link";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
} from "@svix/common/widgets/PageToolbar";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getApiConfiguration } from "src/api";
import { routeResolver } from "src/App";
import {
  OrganizationSettingsApi,
  SettingsInternalIn,
} from "src/generated/dashboard-openapi";
import { useAppSelector } from "src/hooks/store";
import { useRegion } from "src/store/selectors";
import { trackEvent } from "../../../analytics";

const schema = yup.object().shape({
  eventCatalogPublished: yup.boolean().nullable(),
  displayName: yup
    .string()
    .nullable()
    .when("eventCatalogPublished", {
      is: true,
      then: (schema) =>
        schema.required("Display name is required when making the event catalog public"),
    }),
});

export default function GeneralOrganizationSettings() {
  const region = useRegion();
  const activeEnvId = useAppSelector((store) => store.auth.activeEnvId);
  const toast = useToast();
  const catalogLink = `https://www.svix.com/event-types/${region}/${activeEnvId}/`;
  const { data: orgSettings, isLoading } = useQuery(
    ["environments", activeEnvId, "orgSettings"],
    async () => {
      const config = await getApiConfiguration();
      const orgSettingsApi = new OrganizationSettingsApi(config);
      return orgSettingsApi.settingsGetOrganizationSettingsGet();
    }
  );

  const formCtx = useForm({
    resolver: yupResolver(schema),
    defaultValues: orgSettings || {
      eventCatalogPublished: false,
      displayName: "",
    },
    mode: "onBlur",
  });

  const { reset } = formCtx;
  useEffect(() => {
    reset(orgSettings);
  }, [reset, orgSettings]);

  async function saveSettings(values: SettingsInternalIn) {
    const config = await getApiConfiguration();
    const orgSettingsApi = new OrganizationSettingsApi(config);
    try {
      const settings = await orgSettingsApi.settingsUpdateOrganizationSettingsPut(
        stripEmptyFields<SettingsInternalIn>(values)
      );

      if (formCtx.formState.dirtyFields.eventCatalogPublished) {
        if (values.eventCatalogPublished) {
          trackEvent("Make Event Catalog Public");
        } else {
          trackEvent("Make Event Catalog Private");
        }
      }

      reset(settings);
      toast({ status: "success", title: "Configuration saved" });
    } catch (e) {
      setErrors(formCtx.setError, e.body);
    }
  }

  return (
    <>
      <MetaTitle path={["General Settings"]} />
      <PageToolbar>
        <Breadcrumbs>
          <BreadcrumbItem to={routeResolver.getRoute("settings")}>
            Settings
          </BreadcrumbItem>
          <BreadcrumbItem>Event Catalog configuration</BreadcrumbItem>
        </Breadcrumbs>
      </PageToolbar>
      <Card>
        <Heading as="h2" size="md" mb={4}>
          Event Catalog configuration
        </Heading>
        <Divider />
        <Heading as="h3" fontSize="md" my={4}>
          Visibility
        </Heading>
        <Text size="md" variant="caption">
          Allow your users to view your configured event types{" "}
          <i>(unique per environment)</i>.
        </Text>
        <Form onSubmit={saveSettings} {...formCtx}>
          <Box maxW="32em" my={2}>
            <GeneralFormErrors />
          </Box>
          <Stack spacing={5} mt={4}>
            <Stack spacing={4}>
              <Toggle
                label="Make Public"
                control={formCtx.control}
                name="eventCatalogPublished"
                helpText={
                  <>
                    <Text mb={1}>
                      Enable this to make your Event Catalog available at:
                    </Text>
                    <StyledLink
                      border="1px solid"
                      borderColor="gray.200"
                      borderRadius="lg"
                      color="blue.600"
                      display="inline-block"
                      fontWeight="semibold"
                      href={catalogLink}
                      target="_blank"
                      mt={2}
                      p={2}
                    >
                      {catalogLink}
                    </StyledLink>
                  </>
                }
                isLoading={isLoading}
              />
              <TextField
                maxW="32em"
                label="Display Name"
                control={formCtx.control}
                name="displayName"
                placeholder="My Company"
                helperText={
                  <>
                    This name will be displayed at the top of your event catalog. It will
                    also be used in the App Portal.
                  </>
                }
                isRequired={false}
              />
            </Stack>
            <Box>
              <SubmitButton
                type="submit"
                variant="solid"
                loadingText="Saving"
                showLoading
              >
                Save
              </SubmitButton>
            </Box>
            <Box>
              <Divider />
              <Heading as="h3" fontSize="md" my={4}>
                Custom domain
              </Heading>
              <Stack spacing={2}>
                <Text variant="caption">
                  To serve the Event Catalog from your own domain, set the following
                  record on your DNS provider:
                </Text>
                <Box>
                  <UnorderedList>
                    <ListItem>
                      {"Type: "}
                      <Code>CNAME</Code>
                    </ListItem>
                    <ListItem>
                      {"Name: "}
                      <Code>webhooks</Code>
                      (or whatever subdomain you would like to use)
                    </ListItem>
                    <ListItem>
                      {"Value: "}
                      <Code>event-types.svix.com</Code>
                    </ListItem>
                  </UnorderedList>
                </Box>
                <Text variant="caption">
                  And <Link href="https://www.svix.com/contact/">contact us</Link> once
                  you do so that we can activate it on our end.
                </Text>
              </Stack>
            </Box>
          </Stack>
        </Form>
      </Card>
    </>
  );
}
