import {
  Box,
  Flex,
  Input,
  Select,
  IconButton,
  Text,
  useBoolean,
  useColorModeValue,
  BoxProps,
  Collapse,
} from "@chakra-ui/react";
import { ArrowRight } from "@material-ui/icons";
import { Draft } from "immer";

import { JSONSchema7, JSONSchema7TypeName } from "@svix/common/widgets/JsonSchema/types";

import SchemaArray from "./SchemaArray";
import SchemaObject from "./SchemaObject";

export interface ISchemaRootProps {
  setSchema: (mutations: (draft: Draft<JSONSchema7>) => void) => void;
  schema: JSONSchema7;
  isReadOnly?: boolean;
}

function Heading(props: BoxProps) {
  const { children, ...rest } = props;
  const color = useColorModeValue("gray.600", "gray.500");
  return (
    <Box p={1} {...rest}>
      <Text
        fontSize="xs"
        color={color}
        fontWeight="bold"
        letterSpacing="wider"
        textTransform="uppercase"
      >
        {children}
      </Text>
    </Box>
  );
}

function SchemaRoot(props: ISchemaRootProps) {
  const { schema, setSchema, isReadOnly } = props;
  const [isExpanded, setExpanded] = useBoolean(true);

  return (
    <>
      <Box w="100%">
        <Flex
          alignItems="center"
          borderBottom="1px solid"
          borderColor="background.modifier.border"
          direction="row"
          wrap="nowrap"
          size="sm"
          mt={2}
          sx={{ gap: 12 }}
          w="100%"
        >
          <Heading width="18em" p={1}>
            Field Name
          </Heading>
          <Heading width="15em" p={1}>
            Type
          </Heading>
          <Heading p={1} mr="auto">
            Title
          </Heading>
          <Heading width="3.5em" p={1}>
            Req?
          </Heading>
        </Flex>
        <Box mt={2} data-schema-level={0} w="100%">
          <Flex
            alignItems="center"
            direction="row"
            wrap="nowrap"
            size="sm"
            my={1}
            sx={{ gap: 12 }}
          >
            {schema.type === "object" && (
              <IconButton
                aria-label="toggle expand"
                variant="ghost"
                color="gray.600"
                position="absolute"
                left={-4}
                size="xs"
                onClick={setExpanded.toggle}
              >
                <ArrowRight
                  fontSize="small"
                  style={{ transform: isExpanded ? "rotate(90deg)" : "rotate(0deg)" }}
                />
              </IconButton>
            )}
            <Input
              name="schema-root-name"
              size="sm"
              variant="filled"
              readOnly
              isDisabled
              value="Root"
              w="18em"
              flexShrink={0}
            />
            <Select
              variant="filled"
              isDisabled={isReadOnly}
              value={schema.type}
              w="15em"
              size="sm"
              placeholder="Choose root data type"
              flexShrink={0}
              onChange={(evt) => {
                setSchema((draft) => {
                  draft.items = undefined;
                  draft.required = undefined;
                  draft.type = evt.target.value as JSONSchema7TypeName;
                  if (draft.properties) {
                    draft.properties = {};
                  }
                });
              }}
            >
              <option value="object">object</option>
              <option value="array">array</option>
            </Select>
            <Input
              name="schema-root-title"
              value={schema.title || ""}
              isDisabled={isReadOnly}
              size="sm"
              variant="filled"
              placeholder="Add Title"
              onChange={(evt) => {
                setSchema((draft) => {
                  draft.title = evt.target.value;
                });
              }}
            />
            <Box w="3.5em" flexShrink={0} />
          </Flex>
        </Box>
      </Box>

      <Collapse in={isExpanded}>
        {schema.type === "object" && (
          <SchemaObject
            level={1}
            schema={schema}
            setSchema={setSchema}
            definitions={schema.definitions}
          />
        )}
        {schema.type === "array" && (
          <SchemaArray
            level={1}
            schema={schema}
            setSchema={setSchema}
            definitions={schema.definitions}
          />
        )}
      </Collapse>
    </>
  );
}

export default SchemaRoot;
