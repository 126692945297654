import { useState } from "react";
import { Box, Alert, Heading, Text, useBoolean } from "@chakra-ui/react";

import Button from "@svix/common/widgets/Button";
import Card from "@svix/common/widgets/Card";
import ConfirmationDialog from "@svix/common/widgets/ConfirmationDialog";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
} from "@svix/common/widgets/PageToolbar";

import { getApiConfiguration } from "src/api";
import { routeResolver } from "src/App";
import { AuthenticationApi } from "src/generated/dashboard-openapi";
import { prettyError, useSvixAuth } from "src/utils";
import ChangePassword from "./ChangePassword";
import ToggleTwoFactorAuth from "./ToggleTwoFactorAuth";

export default function UserManagement() {
  const { logout } = useSvixAuth();
  const [error, setError] = useState("");
  const [deleteUserOpen, setDeleteUserOpen] = useBoolean();

  async function deleteUserClicked() {
    try {
      const config = await getApiConfiguration();
      const authApi = new AuthenticationApi(config);
      await authApi.deleteUserAuthenticationDeleteUserPost();
      logout({ logoutParams: { returnTo: window.location.origin } });
      setDeleteUserOpen.off();
    } catch (e) {
      setError(prettyError(e));
    }
  }

  return (
    <>
      <MetaTitle path={["Account Management"]} />
      <PageToolbar>
        <Breadcrumbs>
          <BreadcrumbItem to={routeResolver.getRoute("settings")}>
            Settings
          </BreadcrumbItem>
          <BreadcrumbItem>Account Management</BreadcrumbItem>
        </Breadcrumbs>
      </PageToolbar>
      <Card mt={5}>
        <Heading as="h2" size="md" mb={4}>
          Profile
        </Heading>
        <ChangePassword />
      </Card>
      <Card mt={5}>
        <Heading as="h2" size="md" mb={4}>
          Multi-Factor Authentication
        </Heading>
        <ToggleTwoFactorAuth />
      </Card>
      <Card mt={5}>
        <Heading as="h2" size="md">
          Delete Account
        </Heading>
        <Text variant="caption" my={4}>
          Deleting your account is permanent. If you are the last user in your
          organization, all organization data will be removed.
        </Text>
        <Button
          size="sm"
          variant="outline"
          colorScheme="red"
          onClick={setDeleteUserOpen.on}
        >
          Delete User
        </Button>
        <ConfirmationDialog
          title="Are you sure?"
          isOpen={deleteUserOpen}
          onCancel={setDeleteUserOpen.off}
          onOk={deleteUserClicked}
          labelOk="Delete"
          colorScheme="red"
        >
          <Text>
            Are you sure you would like to delete your user and all of its data? This
            cannot be undone!
          </Text>
          {error && (
            <Box mt={2}>
              <Alert status="error">{error}</Alert>
            </Box>
          )}
        </ConfirmationDialog>
      </Card>
    </>
  );
}
