/**
 * Dashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { OrganizationMessageStatisticsData } from './OrganizationMessageStatisticsData';
import { StatisticsPeriod } from './StatisticsPeriod';
import { HttpFile } from '../http/http';

export class OrganizationMessageStatisticsResponse {
    'data': OrganizationMessageStatisticsData;
    'endDate': Date;
    'period': StatisticsPeriod;
    'startDate': Date;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "data",
            "baseName": "data",
            "type": "OrganizationMessageStatisticsData",
            "format": ""
        },
        {
            "name": "endDate",
            "baseName": "endDate",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "period",
            "baseName": "period",
            "type": "StatisticsPeriod",
            "format": ""
        },
        {
            "name": "startDate",
            "baseName": "startDate",
            "type": "Date",
            "format": "date-time"
        }    ];

    static getAttributeTypeMap() {
        return OrganizationMessageStatisticsResponse.attributeTypeMap;
    }
    
    public constructor() {
    }
}

