import { Draft } from "immer";

import { JSONSchema7 } from "@svix/common/widgets/JsonSchema/types";

import ArrayFieldSettings from "./ArrayFieldSettings";
import BooleanFieldSettings from "./BooleanFieldSettings";
import IntegerFieldSettings from "./IntegerFieldSettings";
import StringFieldSettings from "./StringFieldSettings";

export interface IConfigurationSwitcherProps {
  schema: JSONSchema7;
  setSchema: (mutations: (draft: Draft<JSONSchema7>) => void) => void;
}

export default function ConfigurationSwitcher(props: IConfigurationSwitcherProps) {
  const { schema, setSchema } = props;

  switch (schema.type) {
    case "array":
      return <ArrayFieldSettings schema={schema} setSchema={setSchema} />;
    case "boolean":
      return <BooleanFieldSettings schema={schema} setSchema={setSchema} />;
    case "integer":
    case "number":
      return <IntegerFieldSettings schema={schema} setSchema={setSchema} />;
    case "string":
      return <StringFieldSettings schema={schema} setSchema={setSchema} />;
    case "object":
      return null; // no settings for `object`
    default:
      return null;
  }
}
