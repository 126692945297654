import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  BreadcrumbItem,
  Breadcrumbs,
  PageToolbar,
} from "@svix/common/widgets/PageToolbar";

import { routeResolver } from "src/App";
import { TransformationTemplateForm } from "./TransformationTemplateForm";

export function TransformationTemplateCreateScreen() {
  return (
    <>
      <MetaTitle path={["New Transformation Template"]} />
      <PageToolbar>
        <Breadcrumbs>
          <BreadcrumbItem to={routeResolver.getRoute("transformations")}>
            Transformation Templates
          </BreadcrumbItem>
          <BreadcrumbItem>New</BreadcrumbItem>
        </Breadcrumbs>
      </PageToolbar>

      <TransformationTemplateForm isUpdate={false} />
    </>
  );
}
