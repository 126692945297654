import { Text } from "@chakra-ui/react";
import EmailIcon from "@material-ui/icons/Email";
import { useQuery } from "react-query";

import Button from "@svix/common/widgets/Button";

import { getApiConfiguration } from "src/api";
import { AuthenticationApi } from "src/generated/dashboard-openapi";
import { useAppSelector } from "src/hooks/store";
import GettingStartedCard from "../GettingStartedCard";

export default function MembersCard() {
  const isEmailVerified = useAppSelector((app) => app.auth.emailVerified);

  const { isFetched, refetch: resendVerificationEmail } = useQuery(
    "verification",
    async () => {
      const api = new AuthenticationApi(await getApiConfiguration());
      return api.resendVerificationEmailAuthenticationResendVerificationEmailPost();
    },
    { enabled: false }
  );

  return (
    <GettingStartedCard
      cta={
        !isEmailVerified && (
          <Button
            fontWeight="400"
            disabled={isFetched}
            fontSize="sm"
            variant="link"
            onClick={() => resendVerificationEmail()}
          >
            Resend Email
          </Button>
        )
      }
      done={isEmailVerified}
      icon={<EmailIcon style={{ fontSize: 24 }} />}
      title="Verify your email"
    >
      <Text variant="caption">
        You must verify your email before you can invite your teammates or create
        production environments.
      </Text>
    </GettingStartedCard>
  );
}
