import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/**
 * To add a feature flag:
 * - add the flag to the FEATURE_FLAGS array
 * - give it a default value within the `initialState`
 */

const FEATURE_FLAGS = <const>["whitelabelBorderRadius"];

export type IFeatureFlag = typeof FEATURE_FLAGS[number];
export type IFeatureFlags = {
  [flag in IFeatureFlag]: boolean;
};

export function isFlag(flagName: string): flagName is IFeatureFlag {
  return (FEATURE_FLAGS as readonly string[]).includes(flagName);
}

const initialState: IFeatureFlags = {
  whitelabelBorderRadius: false,
};

const slice = createSlice({
  name: "featureFlags",
  initialState,
  reducers: {
    enableFlag(state: IFeatureFlags, action: PayloadAction<IFeatureFlag>) {
      state[action.payload] = true;
    },
    disableFlag(state: IFeatureFlags, action: PayloadAction<IFeatureFlag>) {
      state[action.payload] = false;
    },
  },
});

export const { enableFlag, disableFlag } = slice.actions;
export default slice.reducer;
