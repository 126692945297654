/**
 * Dashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { OrganizationRole } from './OrganizationRole';
import { HttpFile } from '../http/http';

export class OrganizationUser {
    'createdAt': Date;
    'email': string;
    'orgGroupRole'?: OrganizationRole;
    'providerUserId': string;
    'updatedAt': Date;
    'userId': string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "createdAt",
            "baseName": "createdAt",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "email",
            "baseName": "email",
            "type": "string",
            "format": "email"
        },
        {
            "name": "orgGroupRole",
            "baseName": "orgGroupRole",
            "type": "OrganizationRole",
            "format": ""
        },
        {
            "name": "providerUserId",
            "baseName": "providerUserId",
            "type": "string",
            "format": ""
        },
        {
            "name": "updatedAt",
            "baseName": "updatedAt",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "userId",
            "baseName": "userId",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return OrganizationUser.attributeTypeMap;
    }
    
    public constructor() {
    }
}

