import { useLocation } from "react-router";

export function setSearch(
  attrs: Record<string, string | number | boolean | undefined>
): void;
export function setSearch(key: string, value: string | number | undefined): void;
export function setSearch(keyOrAttrs: unknown, value?: string | number): void {
  const params = new URLSearchParams(location.search);
  if (typeof keyOrAttrs === "string") {
    if (value === undefined) {
      params.delete(keyOrAttrs);
    } else {
      params.set(keyOrAttrs, String(value));
    }
  } else if (keyOrAttrs && typeof keyOrAttrs === "object") {
    Object.entries(keyOrAttrs).forEach(([k, v]) => {
      if (v === undefined || v === "") {
        params.delete(k);
      } else {
        params.set(k, v);
      }
    });
  }

  window.history.replaceState(null, "", `?${params.toString()}`);
}

export function useSearch(key: string): string | undefined {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  return params.get(key) ?? undefined;
}

function isTruthy(val: string | null) {
  return !!val && ["true", "1"].includes(val);
}

export function useSearchBool(key: string): boolean {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  return isTruthy(params.get(key));
}
