import { FormControl, FormLabel, Heading, Switch } from "@chakra-ui/react";

import Card from "@svix/common/widgets/Card";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
} from "@svix/common/widgets/PageToolbar";

import { routeResolver } from "src/App";
import { useAppDispatch, useAppSelector } from "src/hooks/store";
import { setSettings } from "src/store/settings";

export default function DisplayPreferences() {
  const dispatch = useAppDispatch();
  const settings = useAppSelector((state) => state.settings);

  return (
    <>
      <MetaTitle path={["Display Preferences"]} />
      <PageToolbar>
        <Breadcrumbs>
          <BreadcrumbItem to={routeResolver.getRoute("settings")}>
            Settings
          </BreadcrumbItem>
          <BreadcrumbItem>Display Preferences</BreadcrumbItem>
        </Breadcrumbs>
      </PageToolbar>
      <Card mt={5}>
        <Heading as="h2" size="md" mb={4}>
          Display
        </Heading>
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="dark-mode" mb="0">
            Dark Mode?
          </FormLabel>
          <Switch
            id="dark-mode"
            defaultChecked={settings.darkMode}
            onChange={() => {
              dispatch(setSettings({ darkMode: !settings.darkMode }));
            }}
          />
        </FormControl>
      </Card>
    </>
  );
}
