import { useEffect, useState } from "react";
import { ModalFooter, Button, Stack } from "@chakra-ui/react";
import { ArrowForward } from "@material-ui/icons";
import { useForm } from "react-hook-form";

import { logError } from "@svix/common/logger";
import TextField from "@svix/common/widgets/form/TextField";

import { getApiConfiguration } from "src/api";
import { AuthenticationApi } from "src/generated/dashboard-openapi";
import { useAppDispatch, useAppSelector } from "src/hooks/store";
import { updateUserInfo } from "src/store/auth";

interface INewUserForm {
  fullName: string;
  companyName: string;
}

export default function NewUserForm(props: { onNext: () => void }) {
  const dispatch = useAppDispatch();
  const { onNext } = props;
  const { fullName, companyName } = useAppSelector((app) => app.auth);

  const { handleSubmit, control } = useForm<INewUserForm>({
    defaultValues: {
      fullName,
      companyName,
    },
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // When signing up with SSO, we already have this information. We can skip this step.
    if (fullName && companyName && fullName.length > 0 && companyName.length > 0) {
      onNext();
    }
  }, [fullName, companyName, onNext]);

  const onSubmit = async (form: INewUserForm) => {
    setLoading(true);
    try {
      dispatch(
        updateUserInfo({ fullName: form.fullName, companyName: form.companyName })
      );

      const config = await getApiConfiguration();
      const authApi = new AuthenticationApi(config);
      await authApi.updateMetadataAuthenticationMetadataPatch({
        name: form.fullName,
        company: form.companyName,
      });
    } catch (err) {
      // Ignore and continue, we can try again later
      logError(err);
    }
    setLoading(false);
    onNext();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" spacing={6} padding="2em">
        <TextField
          autoFocus
          control={control}
          name="fullName"
          label="Full name"
          placeholder="John Doe"
          isRequired
          size="lg"
        />
        <TextField
          autoFocus
          control={control}
          name="companyName"
          label="Company name (optional)"
          placeholder="Acme Inc."
          size="lg"
        />
      </Stack>
      <ModalFooter>
        <Button
          colorScheme="blue"
          type="submit"
          rightIcon={<ArrowForward />}
          isLoading={loading}
        >
          Continue
        </Button>
      </ModalFooter>
    </form>
  );
}
