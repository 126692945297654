import * as React from "react";
import { Box, Text, useColorModeValue, useToken } from "@chakra-ui/react";
import { ResponsiveBar } from "@nivo/bar";
import { format } from "d3-format";

import { formatDate } from "../../utils";

const NUM_Y_TICKS = 10;

export function shortDate(date: Date): string {
  // `undefined` uses the default locale
  return date.toLocaleString(undefined, {
    day: "2-digit",
    timeZone: "UTC",
  });
}
export function getMonthName(date: Date): string {
  return date.toLocaleString(undefined, { month: "short", timeZone: "UTC" });
}

interface IDateCount {
  count: number;
  date: Date;
}

interface IResponsiveBarChartProps {
  data: IDateCount[];
  emptyState: string | React.ReactNode;
}

function transformData(data: IDateCount[]) {
  return data.map((date) => ({
    date: date.date,
    count: date.count,
  }));
}

function getPlaceholderData() {
  return Array(28)
    .fill(0)
    .map((_, i) => ({
      date: i,
      count: Math.floor(Math.random() * 100),
    }));
}

function formatYAxis(value: number) {
  if (value < 1000 && value % 1 === 0) {
    return format(",d")(value);
  }
  return format(",.2s")(value);
}

export default function BarChart({ data, emptyState }: IResponsiveBarChartProps) {
  const emptyColorName = useColorModeValue("gray.100", "gray.800");
  const tickColorName = useColorModeValue("gray.500", "gray.600");

  const color = useToken("colors", "blue.300");
  const emptyColor = useToken("colors", emptyColorName);
  const tickColor = useToken("colors", tickColorName);

  const hasData = data.some((record) => record.count > 0);
  const maxValue = Math.max(...data.map((record) => record.count));

  return (
    <>
      <ResponsiveBar
        borderRadius={4}
        data={hasData ? (transformData(data) as any) : getPlaceholderData()}
        keys={["count"]}
        indexBy="date"
        colors={[hasData ? color : emptyColor]}
        margin={{ top: 50, right: 20, bottom: 50, left: 40 }}
        minValue={0}
        maxValue={hasData ? Math.max(NUM_Y_TICKS, maxValue) : "auto"}
        padding={0.25}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        enableLabel={false}
        labelSkipWidth={12}
        labelSkipHeight={12}
        axisLeft={hasData ? { format: formatYAxis } : null}
        theme={{
          axis: {
            ticks: {
              line: {
                stroke: "transparent",
              },
              text: {
                fill: tickColor,
                letterSpacing: "loose",
                fontWeight: "bold",
              },
            },
          },
          grid: {
            line: {
              stroke: emptyColor,
            },
          },
        }}
        axisBottom={
          hasData
            ? {
                format: (s: Date) => {
                  if (s.getUTCDate() === 1) {
                    return getMonthName(s);
                  }
                  return shortDate(s);
                },
              }
            : null
        }
        tooltip={(input) => (
          <Box py={2} px={4} rounded="xl" bg="gray.700" color="white" boxShadow="2xl">
            <Text fontSize="xs" fontWeight="semibold" my={1}>
              {formatDate(input.data.date as unknown as Date)}
            </Text>
            <Text letterSpacing="wider" fontSize="xl">
              {format(",")(input.value)}
            </Text>
          </Box>
        )}
      />
      {!hasData && (
        <Box
          display="flex"
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          px={4}
          position="absolute"
          h="100%"
          top={0}
          bottom={0}
          right={0}
          left={0}
          zIndex={1}
        >
          {emptyState}
        </Box>
      )}
    </>
  );
}
