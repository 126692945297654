import * as React from "react";
import {
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  FormHelperText,
  SkeletonText,
} from "@chakra-ui/react";
import ErrorIcon from "@material-ui/icons/Error";
import { FieldError, useController } from "react-hook-form";

import { Lang } from "@svix/common/widgets/form/CodeEditor/Lang";
import { JSONSchema7 } from "@svix/common/widgets/JsonSchema/types";

import SchemaEditor from "./SchemaEditor";

const CodeEditor = React.lazy(() => import("@svix/common/widgets/form/CodeEditor"));

function getErrorMessage(error: FieldError | undefined): string {
  if (!error) {
    return "";
  }
  if (error.message) {
    return error.message;
  }
  if (error.type === "required") {
    return "Required";
  }
  if (error.type === "maxLength") {
    return "Max length exceeded";
  }
  return "Invalid";
}

export interface ISchemaFieldProps {
  control: any;
  name: string;
  label?: React.ReactNode | string;
  isDisabled?: boolean;
  helperText?: string;
  required?: boolean;
}

export default function SchemaField({
  control,
  helperText,
  label,
  name,
  required,
}: ISchemaFieldProps) {
  const {
    field: { value, onChange },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules: {
      required,
      validate: () => {
        if (hasParseError) {
          return "Failed to parse JSONSchema";
        }
        return undefined;
      },
    },
  });

  const errorMessage = getErrorMessage(error);
  const [hasParseError, setParseError] = React.useState(false);
  const [stringifiedValue, setStringifiedValue] = React.useState("");
  const editorFocused = React.useRef(false);

  React.useEffect(() => {
    if (!editorFocused.current) {
      // Keep code tab in-sync with visual editor
      setStringifiedValue(JSON.stringify(value, null, 2));
    }
  }, [value]);

  const trySavingEditorVersion = (newValue: string) => {
    try {
      const parsedValue = newValue ? JSON.parse(newValue) : undefined;
      onChange(parsedValue);
      setParseError(false);
    } catch (error) {
      setParseError(true);
    }
  };

  const onEditorChange = (newValue: string) => {
    setStringifiedValue(newValue || "");
    trySavingEditorVersion(newValue || "");
  };

  const onVisualSchemaChange = (schema: JSONSchema7 | undefined) => {
    onChange(schema);
  };

  return (
    <FormControl isInvalid={invalid} id={name} isRequired={required}>
      <FormLabel>{label}</FormLabel>
      <Tabs colorScheme="brand" lazyBehavior="keepMounted">
        <TabList>
          <Tab>Visual</Tab>
          <Tab>
            Code
            {hasParseError && <ErrorIcon style={{ marginLeft: 4, fontSize: 18 }} />}
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel data-cy="visual-tab">
            <SchemaEditor onChange={onVisualSchemaChange} data={value} />
          </TabPanel>
          <TabPanel data-cy="editor-tab">
            <Box
              bg="background.secondary"
              py={4}
              border="1px solid"
              borderColor="background.modifier.border"
              borderRadius="lg"
            >
              <React.Suspense fallback={<SkeletonText noOfLines={2} />}>
                <CodeEditor
                  value={stringifiedValue}
                  onChange={onEditorChange}
                  onFocus={() => (editorFocused.current = true)}
                  onBlur={() => (editorFocused.current = false)}
                  lang={Lang.Json}
                />
              </React.Suspense>
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
      {helperText && !error && <FormHelperText>{helperText}</FormHelperText>}
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
}
