import {
  Box,
  Collapse,
  Checkbox,
  HStack,
  Stack,
  Tag,
  Text,
  useBoolean,
  useToast,
  Tooltip,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Info } from "@material-ui/icons";
import cloneDeep from "lodash/cloneDeep";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { EventTypeIn } from "svix";
import * as yup from "yup";

import * as C from "@svix/common/constants";
import { setErrors } from "@svix/common/formUtils";
import Button from "@svix/common/widgets/Button";
import Form, { GeneralFormErrors } from "@svix/common/widgets/Form";
import TextArea from "@svix/common/widgets/form/TextArea";
import TextField from "@svix/common/widgets/form/TextField";
import { isSchemaConfigured } from "@svix/common/widgets/JsonSchema/SchemaPreviewer/utils";
import StyledLink from "@svix/common/widgets/Link";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
} from "@svix/common/widgets/PageToolbar";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getSvix } from "src/api";
import { routeResolver } from "src/App";
import { useAppSelector } from "src/hooks/store";
import { trackEvent } from "../../analytics";
import SchemaField from "../EventType/SchemaField";
import { getDefaultSchema, transformSchema } from "../EventType/SchemaField/utils";

interface EventTypeForm {
  name: string;
  description: string;
  featureFlag: string;
  schema: any | null;
}

const defaultValues = {
  name: "",
  description: "",
  featureFlag: "",
  schema: getDefaultSchema("object", true),
};

const schema = yup.object().shape({
  name: yup
    .string()
    .matches(
      /^[a-zA-Z0-9\-_.]*$/,
      "Name must only contain alphanumeric characters, '-', '_' or '.'"
    ),
  description: yup.string(),
  featureFlag: yup
    .string()
    .matches(
      /^[a-zA-Z0-9\-_.]*$/,
      "Feature flag must only contain alphanumeric characters, '-', '_' or '.'"
    ),
  schema: yup.object(),
});

export default function EventTypeCreateScreen() {
  const activeEnvId = useAppSelector((store) => store.auth.activeEnvId);
  const queryClient = useQueryClient();
  const toast = useToast();
  const [hasSchema, setHasSchema] = useBoolean();
  const formCtx = useForm<EventTypeForm>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  async function onAddEventType(form: EventTypeForm) {
    const api = await getSvix();
    const eventType: EventTypeIn = {
      name: form.name,
      featureFlag: form.featureFlag.trim() || undefined,
      description: form.description,
    };

    if (hasSchema && isSchemaConfigured(form.schema)) {
      const schema = cloneDeep(form.schema);
      transformSchema(schema);
      eventType.schemas = {
        1: schema,
      };
    }

    try {
      await api.eventType.create(eventType);
      toast({ status: "success", title: `Added ${form.name}` });
      queryClient.invalidateQueries(["environments", activeEnvId, "eventTypes"]);
      trackEvent("Add Event Type", {
        label: "form",
      });
      formCtx.reset(defaultValues);
    } catch (e) {
      setErrors(formCtx.setError, e.body);
    }
  }

  return (
    <>
      <MetaTitle path={["New Event Type"]} />
      <PageToolbar>
        <Breadcrumbs>
          <BreadcrumbItem to={routeResolver.getRoute("event-types")}>
            Event Types
          </BreadcrumbItem>
          <BreadcrumbItem>New Event Type</BreadcrumbItem>
        </Breadcrumbs>
      </PageToolbar>

      <Form onSubmit={onAddEventType} {...formCtx}>
        <GeneralFormErrors />
        <Stack spacing={5}>
          <TextField
            maxW="40em"
            control={formCtx.control}
            name="name"
            label="Name"
            autoFocus
            required
            placeholder="e.g. invoice.paid"
          />
          <TextArea
            maxW="40em"
            control={formCtx.control}
            name="description"
            label="Description"
            placeholder="When does this event occur?"
          />
          <TextField
            maxW="40em"
            control={formCtx.control}
            name="featureFlag"
            label={
              <Tooltip
                label={
                  "Feature flags allow you to control which event types are visible to which users. " +
                  "When set, only users with the same feature flag enabled will be able to see this event type."
                }
              >
                <Box>
                  Feature Flag
                  <Info
                    style={{
                      marginBottom: "0.2rem",
                      marginLeft: "0.4rem",
                      fontSize: "1rem",
                      opacity: 0.3,
                    }}
                  />
                </Box>
              </Tooltip>
            }
            placeholder="Limit who can see this event type (optional)"
          />
          <Box>
            <Checkbox
              name="showSchema"
              isChecked={hasSchema}
              onChange={setHasSchema.toggle}
            >
              Add a schema?
              <Tag ml={2} colorScheme="green">
                Recommended
              </Tag>
            </Checkbox>
            <Text variant="caption" mt={1}>
              Adding a schema lets you define the shape that messages of this event type
              follow.
              <StyledLink
                isExternal
                ml={1}
                href={C.docs.eventTypes.schema}
                color="brand.500"
                fontWeight="medium"
              >
                Learn More
              </StyledLink>
            </Text>
          </Box>
          <Collapse in={hasSchema} animateOpacity>
            <SchemaField
              isDisabled={!hasSchema}
              label="Schema"
              name="schema"
              control={formCtx.control}
            />
          </Collapse>
        </Stack>
        <HStack
          mt={4}
          pt={4}
          spacing={4}
          borderTop="1px"
          borderColor="background.modifier.border"
        >
          <Button colorScheme="gray" as={Link} to={routeResolver.getRoute("event-types")}>
            Cancel
          </Button>

          <SubmitButton isLoading={formCtx.formState.isSubmitting}>Create</SubmitButton>
        </HStack>
      </Form>
    </>
  );
}
