import { lazy, Suspense, useEffect } from "react";
import {
  Box,
  Divider,
  HStack,
  Image,
  SkeletonText,
  Stack,
  Text,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  AccordionItem,
  Heading,
  Link as StyledLink,
  useRadioGroup,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { useForm, Control, useController } from "react-hook-form";
import { useQuery, useQueryClient } from "react-query";
import { Link, useHistory } from "react-router-dom";
import { TemplateIn, TemplateOut } from "svix";
import { TransformationTemplateApi, TransformationTemplateKind } from "svix/dist/openapi";

import * as C from "@svix/common/constants";
import { setErrors } from "@svix/common/formUtils";
import Button from "@svix/common/widgets/Button";
import EventsList from "@svix/common/widgets/EventsList";
import Form, { GeneralFormErrors } from "@svix/common/widgets/Form";
import { Lang } from "@svix/common/widgets/form/CodeEditor/Lang";
import TextArea from "@svix/common/widgets/form/TextArea";
import TextField from "@svix/common/widgets/form/TextField";
import IntegrationTile from "@svix/common/widgets/IntegrationTile";
import SubmitButton from "@svix/common/widgets/SubmitButton";
import TestTransformation from "@svix/common/widgets/TestTransformation";

import { trackEvent } from "src/analytics";
import { getSvix } from "src/api";
import { routeResolver } from "src/App";
import { useAppSelector } from "src/hooks/store";
import AIModalButton from "./AIGenerateButton";
import { templateTypes } from "./constants";

const CodeEditor = lazy(() => import("@svix/common/widgets/form/CodeEditor"));

export function TransformationTemplateForm(props: {
  isUpdate?: boolean;
  template?: TemplateOut;
}) {
  const { isUpdate = false, template } = props;
  const activeEnvId = useAppSelector((store) => store.auth.activeEnvId)!;
  const queryClient = useQueryClient();
  const history = useHistory();
  const formCtx = useForm<TemplateIn>({
    defaultValues: {
      ...template,
      name: template?.name ?? "",
      logo: template?.logo ?? "",
      kind: template?.kind,
      transformation: template?.transformation ?? DEFAULT_CODE,
    },
  });
  const { watch, reset, setValue } = formCtx;
  const [name, logo, description, kind] = watch(["name", "logo", "description", "kind"]);

  useEffect(() => {
    reset({
      ...template,
      transformation: template?.transformation ?? DEFAULT_CODE,
    });
  }, [reset, template]);

  useEffect(() => {
    if (kind) {
      if (kind === "Custom") {
        setValue("name", "");
        setValue("logo", "");
      } else {
        setValue("name", kind);
        setValue("logo", `${window.location.origin}${templateTypes[kind].icon}`);
      }
    }
  }, [kind, setValue]);

  const { data: availableEvents } = useQuery(
    ["environments", activeEnvId, "eventTypes"],
    async () => {
      const api = await getSvix();
      return await api.eventType.list({ limit: 250, includeArchived: false });
    }
  );

  const onSubmit = async (form: TemplateIn) => {
    deleteEmptyFields(form);
    try {
      trackEvent("Add Transformation Template");
      const svix = await getSvix();
      const api = new TransformationTemplateApi(svix._configuration);
      await api.v1TransformationTemplateCreate({ templateIn: form });
      await queryClient.invalidateQueries([
        "environments",
        activeEnvId,
        "transformationTemplates",
      ]);
      history.push(routeResolver.getRoute("transformations"));
    } catch (e) {
      setErrors(formCtx.setError, e.body);
    }
  };

  const onUpdate = async (form: TemplateIn) => {
    deleteEmptyFields(form);
    try {
      const svix = await getSvix();
      const api = new TransformationTemplateApi(svix._configuration);
      await api.v1TransformationTemplateUpdate({
        transformationTemplateId: template!.id,
        templateUpdate: form,
      });
      await queryClient.refetchQueries([
        "environments",
        activeEnvId,
        "transformationTemplates",
      ]);
      history.push(routeResolver.getRoute("transformations"));
    } catch (e) {
      setErrors(formCtx.setError, e.body);
    }
  };

  // TODO: on first render name and logo are undefined and I don't understand why. Fix it.
  const showPreview = !!name && !!logo && name.length > 0 && logo.length > 0;

  const isCustom = kind === "Custom";

  return (
    <>
      <Box maxW="60em">
        <Form onSubmit={isUpdate ? onUpdate : onSubmit} {...formCtx}>
          <Stack spacing={5}>
            <Text variant="caption">
              Learn more about how to create and use Transformation Templates{" "}
              <StyledLink
                color="blue.500"
                href={C.docs.advanced.transformations.templates}
                isExternal
              >
                in our docs.
              </StyledLink>
            </Text>
            <Divider />
            <TemplateTypeSelector control={formCtx.control} />
            <Divider />
            {isCustom && (
              <TextField
                control={formCtx.control}
                name="name"
                label="Name"
                required
                autoFocus
                placeholder="e.g. Send message to Slack"
              />
            )}
            {isCustom && (
              <TextField
                control={formCtx.control}
                name="logo"
                label="Logo URL"
                required
                placeholder="https://example.com/logo.png"
              />
            )}
            <TextArea
              control={formCtx.control}
              name="description"
              label="Description"
              placeholder="What does this template do?"
            />

            <Divider />

            {isCustom && (
              <>
                <TextArea
                  control={formCtx.control}
                  name="instructions"
                  label="Instructions"
                  placeholder="Let your users know how to use this integration"
                />
                <TextField
                  control={formCtx.control}
                  name="instructionsLink"
                  label="Learn more link"
                  placeholder="https://example.com/integration/learn-more"
                  helperText="Link to your site where users can learn more about this template (optional)"
                />
                <Divider />
              </>
            )}
            {showPreview && (
              <Stack data-testid="preview">
                <Text fontWeight="semibold" fontSize="md">
                  Preview
                </Text>

                <Box
                  width="24em"
                  borderWidth="1px"
                  borderRadius="lg"
                  overflow="hidden"
                  p={4}
                  boxSizing="border-box"
                  _checked={{
                    outlineColor: "teal.600",
                    outlineWidth: "3px",
                    outlineStyle: "solid",
                  }}
                >
                  <HStack spacing={4}>
                    <Image src={logo} alt={name} width="36px" height="36px" />
                    <Stack spacing={0}>
                      <Text textAlign="left" fontWeight="semibold">
                        {name}
                      </Text>
                      <Text textAlign="left" fontSize="sm" mt={0}>
                        {description}
                      </Text>
                    </Stack>
                  </HStack>
                </Box>
              </Stack>
            )}
            <EventsList
              control={formCtx.control}
              name="filterTypes"
              label="Supported event types"
              availableEvents={availableEvents?.data || []}
              emptyState="Using all events."
            />
            <TextField
              control={formCtx.control}
              name="featureFlag"
              label="Feature flag"
              placeholder="Limit who can see this transformation template (optional)"
              helperText="Feature flags allow you to control which templates are visible to which users. When set, only users with the same feature flag enabled will be able to see this transformation template."
            />
            <Box>
              <Text fontWeight="semibold" fontSize="md">
                Transformation code
              </Text>
              <Text mb={2}>
                The default transformation that will be applied to the event after
                creating the integration. This code needs to work for all the event types
                selected above. It can be customized by your users.
              </Text>
              <Suspense fallback={<SkeletonText noOfLines={2} />}>
                <Box
                  border="1px solid"
                  borderColor="background.modifier.border"
                  borderRadius="lg"
                  position="relative"
                >
                  <Box position="absolute" zIndex={5} right={1} bottom={1}>
                    <AIModalButton
                      eventTypes={watch("filterTypes") ?? []}
                      templateType={watch("kind") ?? "Custom"}
                      onGenerate={(code) => {
                        setValue("transformation", code);
                      }}
                    />
                  </Box>
                  <CodeEditor
                    lang={Lang.Javascript}
                    value={watch("transformation")}
                    onChange={(value) => setValue("transformation", value)}
                  />
                </Box>
              </Suspense>
            </Box>
            <Accordion allowMultiple>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Heading size="sm" as="div" flex="1" textAlign="left">
                        Test transformation
                      </Heading>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <TestTransformation code={watch("transformation")} getSvix={getSvix} />
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            <GeneralFormErrors />
          </Stack>
          <HStack
            mt={4}
            pt={4}
            spacing={4}
            borderTop="1px"
            borderColor="background.modifier.border"
          >
            <Button
              colorScheme="gray"
              as={Link}
              to={routeResolver.getRoute("transformations")}
            >
              Cancel
            </Button>

            {isUpdate ? (
              <SubmitButton isLoading={formCtx.formState.isSubmitting}>
                Update
              </SubmitButton>
            ) : (
              <SubmitButton isLoading={formCtx.formState.isSubmitting}>
                Create
              </SubmitButton>
            )}
          </HStack>
        </Form>
      </Box>
    </>
  );
}

function deleteEmptyFields(form: TemplateIn) {
  const nullableFields: (keyof TemplateIn)[] = ["featureFlag", "instructionsLink"];
  nullableFields.forEach((key) => {
    const field = form[key];
    if (typeof field === "string" && field.trim() === "") {
      delete form[key];
    }
  });
}

const TemplateTypes: TransformationTemplateKind[] = [
  "Custom",
  "Slack",
  "Discord",
  "Hubspot",
  "Inngest",
  "Teams",
  "Zapier",
];

function TemplateTypeSelector(props: { control: Control<TemplateIn> }) {
  const { control } = props;
  const { field } = useController({ control, name: "kind" });
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "kind",
    onChange: field.onChange,
    value: field.value,
  });
  const group = getRootProps();

  return (
    <Stack spacing={4} {...group}>
      <Text as="label" fontWeight="semibold" fontSize="md">
        Template type
      </Text>
      <Wrap mt={0} spacing={2}>
        {TemplateTypes.map((tt) => {
          const radio = getRadioProps({ value: tt });

          return (
            <WrapItem key={tt}>
              <IntegrationTile
                key={tt}
                {...radio}
                icon={templateTypes[tt].icon}
                name={templateTypes[tt].name}
              />
            </WrapItem>
          );
        })}
      </Wrap>
      <Text>{field.value ? templateTypes[field.value].description : ""}</Text>
    </Stack>
  );
}

const DEFAULT_CODE = `/**
 * @param webhook the webhook object
 * @param webhook.method destination method. Allowed values: "POST", "PUT"
 * @param webhook.url current destination address
 * @param webhook.eventType current webhook Event Type
 * @param webhook.payload JSON payload
 * @param webhook.cancel whether to cancel dispatch of the given webhook
 */
function handler(webhook) {
  // modify the webhook object...
  
  // and return it
  return webhook
}
`;
