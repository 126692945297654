import { Box, Skeleton, Stat, StatLabel, StatNumber } from "@chakra-ui/react";

import { ApplicationCountResponse } from "src/generated/dashboard-openapi/models/ApplicationCountResponse";

interface ITotalMessagesProps {
  stats: ApplicationCountResponse | undefined;
}

export default function TotalMessages(props: ITotalMessagesProps) {
  const appCount = props.stats?.data ?? 0;

  return (
    <Box pr={12}>
      <Stat>
        <StatLabel>Total Apps</StatLabel>
        <Skeleton isLoaded={!!props.stats}>
          <StatNumber>{appCount}</StatNumber>
        </Skeleton>
      </Stat>
    </Box>
  );
}
