/**
 * Dashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class MfaEnrollment {
    'authenticatorType': string;
    'barcodeUri': string;
    'recoveryCodes'?: Array<string>;
    'secret': string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "authenticatorType",
            "baseName": "authenticatorType",
            "type": "string",
            "format": ""
        },
        {
            "name": "barcodeUri",
            "baseName": "barcodeUri",
            "type": "string",
            "format": ""
        },
        {
            "name": "recoveryCodes",
            "baseName": "recoveryCodes",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "secret",
            "baseName": "secret",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return MfaEnrollment.attributeTypeMap;
    }
    
    public constructor() {
    }
}

