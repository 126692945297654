import {
  Box,
  Fade,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Grid,
  Heading,
  Skeleton,
  useBoolean,
  useColorModeValue,
  useToken,
} from "@chakra-ui/react";
import { Elements } from "@stripe/react-stripe-js";
import { useParams, Link } from "react-router-dom";

import Card from "@svix/common/widgets/Card";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import ResourceError from "@svix/common/widgets/ResourceError";

import { getApiConfiguration } from "src/api";
import { routeResolver } from "src/App";
import { BillingApi } from "src/generated/dashboard-openapi";
import { getStripe } from "src/stripe";
import { useLoading } from "src/utils";
import PaymentForm from "./PaymentForm";
import { usePaymentPlans } from "..";
import PaymentPlanCard from "../PaymentPlanCard";

function useColorModeValueToken(
  themeSection: string,
  lightValue: string,
  darkValue: string
) {
  const color = useColorModeValue(lightValue, darkValue);
  const token = useToken(themeSection, color);
  return token;
}

export default function PaymentNewScreen() {
  const [isStripeReady, setStripeReady] = useBoolean();
  const { planName } = useParams<{ planName: string }>();

  const {
    data: plans,
    error: planError,
    isLoading: loadingPlan,
    refetch,
  } = usePaymentPlans();
  const plan = plans?.find((x) => x.planName === planName);

  const [intent] = useLoading(async () => {
    const config = await getApiConfiguration();
    const billingApi = new BillingApi(config);
    return billingApi.startBillingFlowBillingIntentPost();
  }, []);

  const colorPrimary = useColorModeValueToken("colors", "brand.500", "brand.400");
  const colorText = useColorModeValueToken("colors", "gray.900", "white");
  const colorBackground = useColorModeValueToken("colors", "gray.100", "gray.800");
  const colorDanger = useColorModeValueToken("colors", "red.500", "red.400");

  if (planError || (!loadingPlan && !plans)) {
    return <ResourceError resourceName="payment plans" onClick={refetch} />;
  }

  return (
    <>
      <MetaTitle path={["Payment"]} />
      <Breadcrumb fontSize="md" mb={8}>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to={routeResolver.getRoute("billing")}>
            Billing
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>Payment Info</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      {plan && (
        <Card borderColor={plan.accent} padding="0px" overflow="hidden">
          <Grid
            gridTemplateColumns={{
              sm: "minmax(0, 1fr)",
              md: "minmax(0, 4fr) minmax(320px, 5fr)",
            }}
          >
            <PaymentPlanCard actionLabel="Subscribing to" plan={plan} />
            <Box padding="2em">
              <Heading as="h3" size="md" mt={2} mb={4}>
                Pay with card
              </Heading>
              {intent && (
                <Elements
                  stripe={getStripe()}
                  options={{
                    clientSecret: intent.clientSecret,
                    appearance: {
                      theme: "flat",
                      variables: {
                        colorPrimary,
                        colorBackground,
                        colorText,
                        colorDanger,
                        spacingUnit: "3px",
                        borderRadius: "8px",
                        spacingGridColumn: "12px",
                        spacingGridRow: "24px",
                      },
                    },
                  }}
                >
                  <PaymentForm
                    isStripeReady={isStripeReady}
                    onReady={setStripeReady.on}
                  />
                </Elements>
              )}
              <Fade in={!isStripeReady && !intent} unmountOnExit>
                <Box>
                  <Skeleton height="40px" borderRadius="lg" />
                  <Skeleton height="40px" mt="3.24em" borderRadius="lg" />
                  <Skeleton height="16px" mt="2.5em" w="90%" borderRadius="lg" />
                </Box>
              </Fade>
            </Box>
          </Grid>
        </Card>
      )}
    </>
  );
}
