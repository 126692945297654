import { HStack, Text, Tr, Tag, MenuItem } from "@chakra-ui/react";
import { useHistory } from "react-router";

import { getAuth0ProviderName } from "@svix/common/utils";
import SSOAccountTag from "@svix/common/widgets/SSOAccountTag";
import TableCell from "@svix/common/widgets/TableCell";
import TableRowMenu from "@svix/common/widgets/TableRowMenu";

import { routeResolver } from "src/App";
import { OrganizationUserOut } from "src/generated/dashboard-openapi/models/OrganizationUserOut";
import { useAppSelector } from "src/hooks/store";
import RolesSummaryTag from "./RolesSummaryTag";
import RoleTag from "./RoleTag";

interface IMemberRowProps {
  member: OrganizationUserOut;
  filterByOrgId?: string;
}

export default function MemberRow(props: IMemberRowProps) {
  const { member, filterByOrgId } = props;
  const userId = useAppSelector((state) => state.auth.userId);
  const isCurrentUser = member.userId === userId;
  const provider = getAuth0ProviderName(member.providerUserId);
  const envs = useAppSelector((state) => state.auth.environments);
  const history = useHistory();

  const memberRoute = routeResolver.getRoute(
    "settings.organizationGroup.members._userId",
    {
      userId: member.userId,
    }
  );

  return (
    <Tr key={member.userId}>
      <TableCell>
        <HStack spacing={3}>
          <Text fontWeight={isCurrentUser ? "semibold" : "normal"}>{member.email}</Text>
          {isCurrentUser && <Tag size="sm">You</Tag>}
          <SSOAccountTag provider={provider} />
        </HStack>
      </TableCell>
      <TableCell w="20%">
        {filterByOrgId ? (
          <RoleTag role={getRoleInEnv(member, filterByOrgId)!} inherit={false} />
        ) : (
          <>
            {member.orgGroupRole && (
              <RolesSummaryTag
                defaultRole={member.orgGroupRole}
                envs={envs}
                overrides={member.roleOverrides}
              />
            )}
          </>
        )}
      </TableCell>
      <TableRowMenu>
        <MenuItem onClick={() => history.push(memberRoute)}>Member details...</MenuItem>
      </TableRowMenu>
    </Tr>
  );
}

const getRoleInEnv = (member: OrganizationUserOut, orgId?: string) => {
  const override = member.roleOverrides.find((o) => o.org === orgId);
  if (override) {
    return override.role;
  } else {
    return member.orgGroupRole;
  }
};
