import { Fragment } from "react";
import { Heading, Box, Divider, Grid, GridItem } from "@chakra-ui/react";
import { EventTypeFromOpenApi } from "svix/dist/openapi";

import { OpenAPIEventTypeRow } from "./OpenAPIEventTypeRow";

interface EventTypeImportListProps {
  uploadedEventTypes: EventTypeFromOpenApi[];
}

export function EventTypesImportList(props: EventTypeImportListProps) {
  const { uploadedEventTypes } = props;

  return (
    <Box maxW="90em">
      <Grid templateColumns="1fr 1fr" pb={4}>
        <GridItem colSpan={1}>
          <Heading as="h3" size="sm">
            OpenAPI Operation Id
          </Heading>
        </GridItem>
        <GridItem>
          <Heading as="h3" size="sm" textAlign="right">
            Svix Event Type name
          </Heading>
        </GridItem>
      </Grid>
      {uploadedEventTypes.map((et) => {
        return (
          <Fragment key={et.name}>
            <Divider />
            <OpenAPIEventTypeRow eventType={et} />
          </Fragment>
        );
      })}
      <Divider />
    </Box>
  );
}
