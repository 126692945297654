import { MenuItem, useToast } from "@chakra-ui/react";
import { useQueryClient } from "react-query";
import { EventTypeOut } from "svix";

import TableRowMenu from "@svix/common/widgets/TableRowMenu";

import { getSvix } from "src/api";
import { useAppDispatch, useAppSelector } from "src/hooks/store";
import { pushError } from "src/store/errors";

interface IEventTypeMenuProps {
  eventType: EventTypeOut;
}

export default function EventTypeMenu(props: IEventTypeMenuProps) {
  const { eventType } = props;
  const activeEnvId = useAppSelector((store) => store.auth.activeEnvId);
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const toast = useToast();

  async function archiveEventType() {
    const api = await getSvix();

    try {
      await api.eventType.delete(eventType.name);
      toast({
        status: "info",
        title: `Deleted ${eventType.name}`,
        duration: 2000,
      });
      await queryClient.invalidateQueries(["environments", activeEnvId, "eventTypes"]);
    } catch (error) {
      // Shows the error response in an overlay
      dispatch(pushError(error));
    }
  }
  return (
    <TableRowMenu>
      <MenuItem onClick={archiveEventType} color="text.danger">
        Archive
      </MenuItem>
    </TableRowMenu>
  );
}
