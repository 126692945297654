import { useEffect } from "react";
import { Box, Divider, Heading, Stack } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";

import { setErrors } from "@svix/common/formUtils";
import Card from "@svix/common/widgets/Card";
import Form, { GeneralFormErrors } from "@svix/common/widgets/Form";
import Toggle from "@svix/common/widgets/form/Toggle";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  BreadcrumbItem,
  Breadcrumbs,
  PageToolbar,
} from "@svix/common/widgets/PageToolbar";
import ResourceError from "@svix/common/widgets/ResourceError";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getApiConfiguration } from "src/api";
import { routeResolver } from "src/App";
import {
  BillingApi,
  OrganizationGroupApi,
  SubscriptionOut,
  HttpErrorOut,
} from "src/generated/dashboard-openapi";
import { OrganizationGroupSettingUpdate } from "src/generated/dashboard-openapi/models/OrganizationGroupSettingUpdate";

export default function OrganizationGroupSettings() {
  const {
    data: orgGroupSettings,
    isLoading,
    error,
    refetch,
  } = useQuery("orgGroupSettings", async () => {
    const config = await getApiConfiguration();
    const orgGroupApi = new OrganizationGroupApi(config);
    return await orgGroupApi.getOrgGroupSettingsOrganizationGroupGet();
  });

  const { data: billing, isLoading: loadingBilling } = useQuery<
    SubscriptionOut,
    { body: HttpErrorOut }
  >(["billing", "subscription"], async () => {
    const config = await getApiConfiguration();
    const billingApi = new BillingApi(config);
    return billingApi.getSubscriptionDetailsBillingSubscriptionGet();
  });

  const formCtx = useForm<OrganizationGroupSettingUpdate>({
    defaultValues: {
      requireAllMembersMfa: false,
      requireEmailpasswordMembersMfa: false,
      requireMembersSso: false,
      requireMembersSameEnterpriseConnection: true,
    },
  });
  const { reset } = formCtx;

  useEffect(() => {
    reset(orgGroupSettings);
  }, [reset, orgGroupSettings]);

  async function onSave(values: OrganizationGroupSettingUpdate) {
    const config = await getApiConfiguration();
    const orgGroupApi = new OrganizationGroupApi(config);
    try {
      const settings = await orgGroupApi.updateOrgGroupSettingsOrganizationGroupPut(
        values
      );
      reset(settings);
    } catch (e) {
      setErrors(formCtx.setError, e.body);
    }
  }

  if (error) {
    return <ResourceError resourceName="organization settings" onClick={refetch} />;
  }

  return (
    <>
      <MetaTitle path={["Organization Settings"]} />
      <PageToolbar>
        <Breadcrumbs>
          <BreadcrumbItem to={routeResolver.getRoute("settings")}>
            Settings
          </BreadcrumbItem>
          <BreadcrumbItem>Organization Settings</BreadcrumbItem>
        </Breadcrumbs>
      </PageToolbar>
      <Card>
        <Heading as="h2" size="md" mb={2}>
          Organization Settings
        </Heading>
        <Form onSubmit={onSave} {...formCtx}>
          <Stack spacing={4} mt={4} width="100%" maxW="40em">
            <Divider />
            <Heading as="h3" size="sm" pb={1}>
              Security
            </Heading>
            <Toggle
              label="Require Multi-Factor Authentication"
              control={formCtx.control}
              name="requireAllMembersMfa"
              helpText={
                <>
                  Require all members of this organization to use Multi-Factor
                  Authentication.
                </>
              }
              isLoading={isLoading}
            />
            <Toggle
              label="Require Multi-Factor Authentication for email and password members"
              control={formCtx.control}
              name="requireEmailpasswordMembersMfa"
              helpText={
                <>
                  Require all members of this organization that use email and password to
                  use Multi-factor Authentication.
                </>
              }
              isLoading={isLoading}
            />
            <Toggle
              label="Require Single-Sign-On"
              control={formCtx.control}
              name="requireMembersSso"
              helpText={
                <>
                  Require all members of this organization to use Single-Sign-On to log in
                  to Svix.
                </>
              }
              isLoading={isLoading}
            />
            {billing?.planName === "enterprise" && (
              <Toggle
                label="Require Enterprise SSO"
                control={formCtx.control}
                name="requireMembersSameEnterpriseConnection"
                helpText={
                  <>
                    Require all members of the organization to only use the Enterprise
                    provider configured for the account.
                  </>
                }
                isLoading={isLoading || loadingBilling}
              />
            )}
          </Stack>
          <Box maxW="32em" my={2}>
            <GeneralFormErrors />
          </Box>
          <Box mt={3}>
            <SubmitButton
              type="submit"
              variant="solid"
              loadingText="Saving"
              showLoading
              isDisabled={isLoading}
            >
              Save
            </SubmitButton>
          </Box>
        </Form>
      </Card>
    </>
  );
}
