import { useState, useEffect } from "react";
import { VStack, Button, StackDivider, Stack, Box, Text, Flex } from "@chakra-ui/react";
import { Check } from "@material-ui/icons";
import uniqBy from "lodash/uniqBy";
import { useForm, useWatch } from "react-hook-form";
import { useQueryClient } from "react-query";

import Select from "@svix/common/widgets/form/Select";
import LoadingIndicator from "@svix/common/widgets/LoadingIndicator";

import { getApiConfiguration } from "src/api";
import { getRegionConfig } from "src/constants";
import { OrganizationOut, VercelProjectOut } from "src/generated/dashboard-openapi";
import { PromiseIntegrationApi as IntegrationApi } from "src/generated/dashboard-openapi/types/PromiseAPI";

interface ProjectEnvironmentMapperProps {
  environments: OrganizationOut[];
}

export default function ProjectEnvironmentMapper(props: ProjectEnvironmentMapperProps) {
  const [vercelLoading, setVercelLoading] = useState(false);
  const [vercelProjects, setVercelProjects] = useState<Array<VercelProjectOut>>();
  const [nextIterator, setNextIterator] = useState<string>();
  const [iterator, setIterator] = useState<string>();

  useEffect(() => {
    const getVercelRes = async () => {
      setVercelLoading(true);
      const config = await getApiConfiguration();
      const integrationApi = new IntegrationApi(config);
      const res = await integrationApi.getVercelProjectsIntegrationsVercelProjectGet(
        5,
        iterator
      );

      setVercelProjects((vercelProjects) => {
        const currentProjects = vercelProjects || [];
        const combined = [...currentProjects, ...res.data];
        const deduped = uniqBy(combined, "id");
        return deduped;
      });
      setNextIterator(res.iterator || undefined);
      setVercelLoading(false);
    };
    getVercelRes();
  }, [iterator]);

  return (
    <>
      <VStack
        spacing="1em"
        width="100%"
        alignItems="flex-start"
        divider={<StackDivider />}
      >
        {vercelProjects === undefined && <LoadingIndicator />}
        {vercelProjects &&
          vercelProjects.map((project, index) => (
            <VercelSvixRow
              key={index}
              project={project}
              environments={props.environments}
              loading={false}
            />
          ))}
      </VStack>
      {nextIterator !== undefined && (
        <Button
          size="sm"
          onClick={() => setIterator(nextIterator)}
          isLoading={vercelLoading}
          mt="1em"
        >
          Load More
        </Button>
      )}
    </>
  );
}

interface VercelSvixRowProps {
  project: VercelProjectOut;
  environments: Array<OrganizationOut>;
  loading: boolean;
}

function VercelSvixRow(props: VercelSvixRowProps) {
  const queryClient = useQueryClient();

  const [saved, setSaved] = useState<boolean>();
  const [loading, setLoading] = useState(false);

  const formCtx = useForm({
    defaultValues: {
      envId: props.project.svixEnvId ?? "",
    },
  });

  const envId = useWatch({
    control: formCtx.control,
    name: "envId",
    defaultValue: props.project.svixEnvId ?? "",
  });

  // update the Vercel project when the user chooses a new Svix environment
  useEffect(() => {
    const updateVercelProject = async () => {
      setSaved(undefined);
      setLoading(true);
      const config = await getApiConfiguration();
      const integrationApi = new IntegrationApi(config);
      await integrationApi.mapVercelProjectToSvixEnvironmentIntegrationsVercelProjectProjectIdMapToOrgIdPut(
        props.project.id,
        envId
      );
      setSaved(true);
      setLoading(false);
    };

    if (envId !== "" && envId !== props.project.svixEnvId) {
      updateVercelProject();
    }
  }, [queryClient, envId, props.project]);

  // remove Saved indicator after 5s
  useEffect(() => {
    if (saved === true) {
      setTimeout(() => setSaved(undefined), 5000);
    }
  }, [saved]);

  return (
    <Stack direction={["column", "row"]} alignItems="top">
      <Flex direction="column" width="20em">
        <Text fontSize="smaller" fontWeight="semibold" color="gray.500">
          Vercel Project
        </Text>
        <Flex flexGrow={1} alignItems="center">
          <Text fontSize="md" fontWeight="semibold">
            {props.project.name}
          </Text>
        </Flex>
      </Flex>
      <Box>
        <Text fontSize="smaller" fontWeight="semibold" color="gray.500">
          Svix environment
        </Text>
        <Flex alignItems="center">
          <Select
            isDisabled={props.loading || loading}
            control={formCtx.control}
            name="envId"
            label=""
            isRequired
          >
            <option disabled value="">
              Select Environment
            </option>
            {props.environments.map((env) => (
              <option value={env.orgId} key={env.orgId}>
                {env.orgName} ({getRegionConfig(env.region).shortLabel})
              </option>
            ))}
          </Select>
          {loading && (
            <Box ml="1em" color="gray.500" fontSize="smaller" display="inline-block">
              Configuring...
            </Box>
          )}
          {saved === true && (
            <Box
              ml="1em"
              rounded="full"
              bgColor="green.500"
              color="white"
              fontSize="sm"
              display="inline-block"
            >
              <Check />
            </Box>
          )}
        </Flex>
      </Box>
    </Stack>
  );
}
