import RadioButtons from "@svix/common/widgets/form/RadioButtons";

import { OrganizationEnvironment } from "src/generated/dashboard-openapi";

interface IEnvTagToggleProps {
  isDisabled?: boolean;
  control: any;
  name: string;
}

export default function EnvTagRadio(props: IEnvTagToggleProps) {
  const buttons = Object.freeze([
    {
      title: "Development",
      description: "This environment should only be used for development and testing.",
      value: "Test" as OrganizationEnvironment,
    },
    {
      title: "Production",
      description:
        "This environment is a production configuration of your application, and should be used for anything that's user-facing. This environment is considered sensitive and contains real user data.",
      value: "Prod" as OrganizationEnvironment,
    },
  ]);

  return <RadioButtons<OrganizationEnvironment> buttons={buttons} {...props} />;
}
