import { useEffect } from "react";
import {
  Breadcrumb,
  Box,
  BreadcrumbItem,
  BreadcrumbLink,
  Grid,
  Heading,
  Skeleton,
} from "@chakra-ui/react";
import { ChevronRight } from "@material-ui/icons";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { Link, useHistory, useParams } from "react-router-dom";

import Card from "@svix/common/widgets/Card";
import Form from "@svix/common/widgets/Form";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getApiConfiguration } from "src/api";
import { routeResolver } from "src/App";
import { BillingApi, PaymentMethodOut } from "src/generated/dashboard-openapi";
import { getStripe } from "src/stripe";
import CardSelect from "./CardSelect";
import { usePaymentPlans } from "../Billing";
import PaymentPlanCard, { Plan } from "../Billing/PaymentPlanCard";

interface IForm {
  card: string | null;
}

interface IPaymentListFormProp {
  paymentMethods: PaymentMethodOut[];
}

function PaymentListForm(props: IPaymentListFormProp) {
  const { paymentMethods } = props;
  const history = useHistory();
  const stripe = useStripe();
  const { planName } = useParams<{ planName: string }>();
  const formCtx = useForm({ defaultValues: { card: null } });

  async function onCardSelect(form: IForm) {
    if (form.card === "new") {
      history.push(routeResolver.getRoute("billing.payment.new._planName", { planName }));
    } else if (stripe && form.card) {
      const config = await getApiConfiguration();
      const billingApi = new BillingApi(config);
      const intentRes = await billingApi.startBillingFlowBillingIntentPost();
      await stripe.confirmCardSetup(intentRes.clientSecret, {
        payment_method: form.card,
      });
      const dest = routeResolver.getRoute("billing.paymentComplete._planName", {
        planName,
      });
      history.push(`${dest}?setup_intent=${intentRes.id}`);
    }
  }

  return (
    <Form onSubmit={onCardSelect} {...formCtx}>
      <Heading as="h3" size="md" mt={2} mb={6}>
        Choose an existing payment method
      </Heading>
      {paymentMethods.length > 0 && (
        <CardSelect
          paymentMethods={paymentMethods}
          name="card"
          control={formCtx.control}
        />
      )}
      <SubmitButton mt={6} rightIcon={<ChevronRight fontSize="inherit" />}>
        Select
      </SubmitButton>
    </Form>
  );
}

export default function PaymentListScreen() {
  const { replace: historyReplace } = useHistory();
  const { planName } = useParams<{ planName: string }>();
  const { data: plans } = usePaymentPlans();
  const plan = plans?.find((x) => x.planName === planName);

  const { data: paymentMethods } = useQuery(["billing", "paymentMethods"], async () => {
    const config = await getApiConfiguration();
    const billingApi = new BillingApi(config);
    return billingApi.getPaymentMethodsBillingPaymentMethodsGet();
  });

  useEffect(() => {
    if (paymentMethods && paymentMethods.data.length === 0 && planName) {
      historyReplace(
        routeResolver.getRoute("billing.payment.new._planName", { planName })
      );
    }
  }, [historyReplace, paymentMethods, planName]);

  return (
    <>
      <MetaTitle path={["Payment"]} />
      <Breadcrumb fontSize="md" mb={8}>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to={routeResolver.getRoute("billing")}>
            Billing
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>Payment Info</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Skeleton isLoaded={!!plan}>
        <Card
          borderColor={plan?.accent ?? loadingPlaceholder.accent}
          padding="0px"
          overflow="hidden"
        >
          <Grid
            gridTemplateColumns={{
              sm: "minmax(0, 1fr)",
              md: "minmax(0, 4fr) minmax(320px, 5fr)",
            }}
          >
            <PaymentPlanCard
              actionLabel="Switching to"
              plan={plan ?? loadingPlaceholder}
            />
            <Box padding="2em">
              <Elements stripe={getStripe()}>
                <PaymentListForm paymentMethods={paymentMethods?.data ?? []} />
              </Elements>
            </Box>
          </Grid>
        </Card>
      </Skeleton>
    </>
  );
}

const loadingPlaceholder: Plan = {
  planName: "free",
  accent: "#000000",
  title: "Loading...",
  price: "$0",
  lineItems: [],
};
