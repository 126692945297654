import { Flex, HStack } from "@chakra-ui/react";

import Button from "@svix/common/widgets/Button";

export interface ISchemaToolbarProps {
  clearSchema: () => void;
}

export default function SchemaToolbar(props: ISchemaToolbarProps) {
  const { clearSchema } = props;

  return (
    <HStack
      mt={3}
      pt={3}
      spacing={4}
      borderTop="1px solid"
      borderColor="background.modifier.border"
    >
      <Flex flexGrow={1} />
      <Button
        size="sm"
        type="button"
        colorScheme="gray"
        color="text.danger"
        onClick={clearSchema}
      >
        Clear schema
      </Button>
    </HStack>
  );
}
