import { format } from "d3-format";
import camelCase from "lodash/camelCase";
import upperFirst from "lodash/upperFirst";
import { ApiException, HttpErrorOut } from "svix/dist/openapi";

export function last<T>(elems: T[] | undefined): T | undefined {
  if (!elems || elems.length === 0) {
    return undefined;
  }
  return elems[elems.length - 1];
}

interface IFormatDateOptions {
  utc?: boolean;
}

export function capitalize(text: string | undefined): string {
  if (!text) {
    return "";
  }
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function formatDate(date: Date, opts?: IFormatDateOptions): string {
  // `undefined` uses the default locale
  return date.toLocaleString(undefined, {
    month: "long",
    day: "numeric",
    year: "numeric",

    ...(opts?.utc && {
      timeZone: "UTC",
    }),
  });
}

export function formatTime(date: Date, opts?: IFormatDateOptions): string {
  // `undefined` uses the default locale
  return date.toLocaleString(undefined, {
    hour12: false,
    hour: "numeric",
    minute: "2-digit",

    ...(opts?.utc && {
      timeZone: "UTC",
    }),
  });
}

interface IFormatDateTimeOptions extends IFormatDateOptions {
  condensed?: boolean;
  withMs?: boolean;
}

export function formatDateTime(date: Date, opts?: IFormatDateTimeOptions): string {
  // `undefined` uses the default locale
  return date.toLocaleString(undefined, {
    month: opts?.condensed ? "2-digit" : "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",

    ...(opts?.withMs && {
      seconds: "numeric",
      fractionalSecondDigits: 3,
    }),

    ...(opts?.utc && {
      timeZone: "UTC",
      timeZoneName: "short",
    }),
  });
}

export function humanize(id: string | null | undefined): string {
  return id?.substring(id.length - 6) ?? "";
}

export function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export interface DotNotationGroup {
  key: string;
  path: string;
  items: DotNotationGroup[];
}

export function groupDotNotation(items: string[]): DotNotationGroup[] {
  return groupDotNotationWithGroups(items.map((i) => [i, undefined]));
}

export function groupDotNotationWithGroups(
  items: [string, string?][]
): DotNotationGroup[] {
  const groups = items.reduce(
    (groups: DotNotationGroup[], [s, gr]: [string, string?]) => {
      const n = `${gr ? `${gr}.` : ""}${s}`;
      const parts = n.split(".");
      parts.reduce((groups2: DotNotationGroup[], key: string, idx: number) => {
        let obj = groups2.find((group: DotNotationGroup) => group.key === key);
        if (!obj) {
          let path = parts.slice(0, idx + 1).join(".");
          if (gr) {
            path = path.replace(`${gr}.`, "");
          }
          groups2.push((obj = { key, items: [], path }));
        }
        return obj.items;
      }, groups);
      return groups;
    },
    []
  );

  return addEventTypesWithSameNameAsGroup(groups, items);
}

// There are cases in which an event type has the same name as a group.
// For example, if we have the event types: [foo, foo.bar, foo.bar.baz], we also need entries for
// foo and foo.bar, or they will get mixed up with a group with the same name.
const addEventTypesWithSameNameAsGroup = (
  groups: DotNotationGroup[],
  eventTypeList: [string, string?][]
) => {
  for (const [ev, groupName] of eventTypeList) {
    let groupedEventType: DotNotationGroup | undefined;
    if (groupName) {
      const parentGroup = findGroup(groupName, groups);
      groupedEventType = parentGroup && findGroup(ev, [parentGroup]);
    } else {
      groupedEventType = findGroup(ev, groups);
    }
    if (groupedEventType && groupedEventType.items.length > 0) {
      groupedEventType.items.unshift({
        items: [],
        key: ev,
        path: ev,
      });
    }
  }

  return groups;
};

function findGroup(
  path: string,
  groups: DotNotationGroup[]
): DotNotationGroup | undefined {
  let found;
  found = groups.find((gr) => gr.path === path);
  if (found) {
    return found;
  }

  for (const gr of groups) {
    found = findGroup(path, gr.items);
    if (found) {
      return found;
    }
  }

  return undefined;
}

export function compactEventTypes(
  groupedEventType: DotNotationGroup,
  groupIds: string[]
): DotNotationGroup {
  if (groupedEventType.items.length === 1 && !groupIds.includes(groupedEventType.path)) {
    // merge with next group
    const next = groupedEventType.items[0];
    return compactEventTypes(
      {
        key: `${groupedEventType.key}.${next.key}`,
        path: next.path,
        items: next.items,
      },
      groupIds
    );
  } else {
    return {
      ...groupedEventType,
      items: groupedEventType.items.map((i) => compactEventTypes(i, groupIds)),
    };
  }
}

export function wrapAround(idx: number, size: number): number {
  return ((idx % size) + size) % size;
}

export function isMacos(): boolean {
  return navigator.platform.indexOf("Mac") > -1;
}

export function toPascalCase(slug: string): string {
  return upperFirst(camelCase(slug));
}

export function isApiError(err: unknown): err is ApiException<HttpErrorOut> {
  if (err instanceof ApiException && err.body instanceof HttpErrorOut) {
    return true;
  }

  // XXX: Add duck-typing since this also needs to support the _HttpErrorOut coming from dashboard-api
  return (
    Boolean(err) &&
    typeof err === "object" &&
    typeof (err as any).body?.detail === "string" &&
    typeof (err as any).body?.code === "string"
  );
}

export function getApiError(err: unknown): string {
  if (isApiError(err)) {
    return err.body.detail;
  }

  if (err instanceof ApiException && Array.isArray(err.body.detail)) {
    if (typeof err.body.detail[0].msg === "string") {
      return err.body.detail[0].msg;
    }
    return JSON.stringify(err.body);
  }

  return "Something went wrong";
}

export async function withRetries<T>(func: () => Promise<T>, tries = 3): Promise<T> {
  if (tries <= 0) {
    throw Error(`max retries exceeded for ${func.name}`);
  }
  try {
    const res = await func();
    return res;
  } catch (err: any) {
    if (err.code >= 500) {
      return withRetries(func, tries - 1);
    } else {
      throw err;
    }
  }
}

export function formatNumber(amount: number) {
  return format(",.0f")(amount);
}

export function formatCurrency(amountInCents: number) {
  return format("$,.2f")(amountInCents / 100);
}

export type Auth0ProviderName = "Google" | "OIDC" | "Okta" | "SAML" | "Email" | "Unknown";

const auth0ProviderNames: { [k: string]: Auth0ProviderName | undefined } = {
  "google-oauth2": "Google",
  oidc: "OIDC",
  okta: "Okta",
  samlp: "SAML",
  email: "Email",
};

export function getAuth0ProviderName(sub: string) {
  const prov = sub.split("|")[0];
  return auth0ProviderNames[prov] ?? "Unknown";
}
