/**
 * Dashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class CustomColorPalette {
    'backgroundHover'?: string;
    'backgroundPrimary'?: string;
    'backgroundSecondary'?: string;
    'buttonPrimary'?: string;
    'interactiveAccent'?: string;
    'navigationAccent'?: string;
    'primary'?: string;
    'textDanger'?: string;
    'textPrimary'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "backgroundHover",
            "baseName": "backgroundHover",
            "type": "string",
            "format": "color"
        },
        {
            "name": "backgroundPrimary",
            "baseName": "backgroundPrimary",
            "type": "string",
            "format": "color"
        },
        {
            "name": "backgroundSecondary",
            "baseName": "backgroundSecondary",
            "type": "string",
            "format": "color"
        },
        {
            "name": "buttonPrimary",
            "baseName": "buttonPrimary",
            "type": "string",
            "format": "color"
        },
        {
            "name": "interactiveAccent",
            "baseName": "interactiveAccent",
            "type": "string",
            "format": "color"
        },
        {
            "name": "navigationAccent",
            "baseName": "navigationAccent",
            "type": "string",
            "format": "color"
        },
        {
            "name": "primary",
            "baseName": "primary",
            "type": "string",
            "format": "color"
        },
        {
            "name": "textDanger",
            "baseName": "textDanger",
            "type": "string",
            "format": "color"
        },
        {
            "name": "textPrimary",
            "baseName": "textPrimary",
            "type": "string",
            "format": "color"
        }    ];

    static getAttributeTypeMap() {
        return CustomColorPalette.attributeTypeMap;
    }
    
    public constructor() {
    }
}

