import { Box, Skeleton, Stat, StatLabel, StatNumber } from "@chakra-ui/react";
import { format } from "d3-format";

import { OrganizationMessageStatisticsResponse } from "src/generated/dashboard-openapi";

interface ITotalMessagesProps {
  stats: OrganizationMessageStatisticsResponse | undefined;
}

function formatNumber(value: number): string {
  return format(",d")(value);
}

export default function TotalMessages(props: ITotalMessagesProps) {
  const msgCounts: number[] = props.stats?.data.messageDestinationCount ?? [];
  const totalMessages = msgCounts.reduce((a, b) => a + b, 0);

  return (
    <Box pr={12}>
      <Stat>
        <StatLabel>Total Messages</StatLabel>
        <Skeleton isLoaded={!!props.stats}>
          <StatNumber>{formatNumber(totalMessages)}</StatNumber>
        </Skeleton>
      </Stat>
    </Box>
  );
}
