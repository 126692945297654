import { Box, Button, Heading, Text } from "@chakra-ui/react";
import { Launch } from "@material-ui/icons";

import * as C from "@svix/common/constants";

import { ReactComponent as BlankCanvas } from "src/img/blank_canvas.inline.svg";
import ApplicationsPageToolbar from "../Applications/Toolbar";

export default function ApplicationsEmptyState() {
  return (
    <>
      <ApplicationsPageToolbar />
      <Box
        maxWidth={800}
        margin="0 auto"
        p={4}
        textAlign="center"
        alignItems="center"
        display="flex"
        flexDirection="column"
      >
        <Box width="80%" maxW="240px">
          <BlankCanvas width="100%" height="100%" />
        </Box>
        <Heading as="h2" size="md" mt={4} mb={2}>
          No Applications
        </Heading>
        <Text variant="caption">Add an Application to get started.</Text>
        <Button
          as="a"
          mt={4}
          size="sm"
          variant="outline"
          rightIcon={<Launch fontSize="small" />}
          target="_blank"
          href={C.docs.quickstart.application}
        >
          Learn more about Applications
        </Button>
      </Box>
    </>
  );
}
