import { StrictMode } from "react";
import * as Sentry from "@sentry/react";
// eslint-disable-next-line import/default
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/es/integration/react";

import * as C from "@svix/common/constants";
import { sentryErrorFilter } from "@svix/common/logger";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store, persistor } from "./store";
import { isEE } from "./utils";

import "./analytics";
import "./index.css";

// Polyfills
import "url-search-params-polyfill";

Sentry.init({
  dsn: C.sentry.dashboardDsn,
  environment: C.envConfig.name,
  integrations: [new Sentry.BrowserTracing()],
  beforeSend: sentryErrorFilter,
  // Performance Monitoring
  tracesSampleRate: 0.5,
});

ReactDOM.render(
  <StrictMode>
    <Sentry.ErrorBoundary>
      {/* FIXME: this can be moved further down in the tree to avoid having to check isEE here */}
      <BrowserRouter basename={isEE ? "/admin" : "/"}>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  </StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
