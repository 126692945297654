import { Box, Heading, Skeleton, Stack, StackDivider, Text } from "@chakra-ui/react";
import { useQuery } from "react-query";

import BarChart from "@svix/common/widgets/BarChart";
import Card from "@svix/common/widgets/Card";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";

import { getApiConfiguration } from "src/api";
import { OrganizationMessageStatisticsResponse } from "src/generated/dashboard-openapi";
import { PromiseStatisticsApi as StatisticsApi } from "src/generated/dashboard-openapi/types/PromiseAPI";
import { useActiveEnv } from "src/store/selectors";
import TotalApplications from "./stats/TotalApplications";
import TotalEndpoints from "./stats/TotalEndpoints";
import TotalMessages from "./stats/TotalMessages";

const ONE_DAY = 24 * 60 * 60 * 1000;

function transformStats(stats: OrganizationMessageStatisticsResponse) {
  const startDate = new Date(stats.startDate);
  const transformedData = (stats.data.messageDestinationCount ?? []).map((count, idx) => {
    return {
      date: new Date(startDate.getTime() + idx * ONE_DAY),
      count,
    };
  });

  return transformedData;
}

export function getStatsPeriod(stats?: OrganizationMessageStatisticsResponse): string {
  if (!stats) {
    return "";
  }

  const from = stats.startDate.toLocaleString(undefined, {
    month: "short",
    day: "numeric",
    year: "numeric",
    timeZone: "UTC",
  });

  const to = stats.endDate.toLocaleString(undefined, {
    month: "short",
    day: "numeric",
    year: "numeric",
    timeZone: "UTC",
  });

  return `${from} - ${to}`;
}

export default function UsageScreen() {
  const activeEnv = useActiveEnv();
  const rootQueryKey = ["environments", activeEnv, "usage"];
  const { data: appStats } = useQuery([...rootQueryKey, "apps"], async () => {
    const config = await getApiConfiguration();
    const statsApi = new StatisticsApi(config);
    return statsApi.getAppCountStatsAppCountGet();
  });
  const { data: endpointStats } = useQuery([...rootQueryKey, "endpoints"], async () => {
    const config = await getApiConfiguration();
    const statsApi = new StatisticsApi(config);
    return statsApi.getEndpointCountStatsEndpointCountGet();
  });

  const { data: stats } = useQuery([...rootQueryKey, "messages"], async () => {
    const config = await getApiConfiguration();
    const statsApi = new StatisticsApi(config);
    return statsApi.getOrgMessagesStatsStatsOrgMessagesGet();
  });

  return (
    <>
      <MetaTitle path={["Usage"]} />
      <Heading as="h1" my={2} size="lg">
        Environment Usage
      </Heading>
      <Text mb={8} variant="caption">
        {getStatsPeriod(stats)}
      </Text>
      <Stack
        divider={<StackDivider borderColor="background.modifier.border" />}
        direction="row"
        mb={12}
        spacing={5}
      >
        <TotalApplications stats={appStats} />
        <TotalEndpoints stats={endpointStats} />
        <TotalMessages stats={stats} />
      </Stack>
      <Card title="Overall Usage" w="100%">
        <Skeleton isLoaded={!!stats?.data}>
          <Box height="320px" w="100%" position="relative">
            {stats?.data && (
              <BarChart
                data={transformStats(stats)}
                emptyState={
                  <>
                    <Heading textAlign="center" as="h3" size="md" mb={2}>
                      No messages have been sent from this environment in the last 28
                      days.
                    </Heading>
                    <Text>
                      Check out our docs to see how you can start sending messages now.
                    </Text>
                  </>
                }
              />
            )}
          </Box>
        </Skeleton>
      </Card>
    </>
  );
}
