import * as React from "react";
import { LogoutOptions, RedirectLoginOptions, useAuth0 } from "@auth0/auth0-react";
import { useAuth as useOIDCAuth } from "react-oidc-context";

import { isOIDCEE } from "@svix/common/constants";
import * as C from "@svix/common/constants";
import { logError } from "@svix/common/logger";

import { ApiException, OrganizationOut } from "./generated/dashboard-openapi";
import { useAppDispatch, useAppSelector } from "./hooks/store";
import { persistor } from "./store";
import { pushError } from "./store/errors";

export function useLoadingManual<T>(
  func_: (...args: any[]) => Promise<T>,
  deps: React.DependencyList
): [
  boolean,
  T | undefined,
  (...args: any[]) => Promise<T | undefined>,
  (val: T) => void
] {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState<T>();

  const func = React.useCallback(
    async (...args: any[]): Promise<T | undefined> => {
      setLoading(true);
      try {
        const ret = await func_(...args);
        setValue(ret);
        return ret;
      } catch (e) {
        dispatch(pushError(e));
        return undefined;
      } finally {
        setLoading(false);
      }
    },
    deps // eslint-disable-line react-hooks/exhaustive-deps
  );

  return [loading, value, func, setValue];
}

export function useLoading<T>(
  func_: () => Promise<T>,
  deps: React.DependencyList
): [T | undefined, () => Promise<T | undefined>, boolean] {
  const activeEnvId = useAppSelector((store) => store.auth.activeEnvId);
  const [isLoading, value, func] = useLoadingManual(func_, [...deps, activeEnvId]);

  React.useEffect(() => {
    func();
  }, [func]);

  return [value, func, isLoading];
}

export function prettyError(e: Error): string {
  if (e instanceof ApiException) {
    return e.body.detail;
  } else {
    return e.message;
  }
}

export function handleInputChange(func: (value: string) => void) {
  return (event: React.ChangeEvent<any>) => {
    func(event.target.value);
  };
}

export interface SvixUser {
  name?: string;
  email: string;
  email_verified?: boolean;
  sub?: string;
  [key: string]: any;
}

// FIXME: This is a bit ugly, but it'll get much simpler once we get rid of Auth0 completely.
export const useSvixAuth = import.meta.env.VITE_INJECTED_JWT
  ? useAuthInjected
  : isOIDCEE
  ? useSvixAuthOIDC
  : useSvixAuthAuth0;

function useAuthInjected() {
  return {
    error: null,
    isLoading: false,
    isAuthenticated: true,
    logout: (_options?: LogoutOptions) => {
      throw Error("This method is not supported when injecting JWT");
    },
    loginWithRedirect: async (_options?: RedirectLoginOptions) => {
      throw Error("This method is not supported when injecting JWT");
    },
    user: {
      email: "liam+integration@svix.com",
    } as SvixUser,
  };
}

function useSvixAuthAuth0() {
  const { error, isLoading, isAuthenticated, logout, loginWithRedirect, user } =
    useAuth0();

  async function logoutAndPurgePersistor() {
    try {
      window.analytics!.reset();
    } catch (e) {
      logError(e);
    }
    await persistor.purge();
    logout();
  }

  return {
    error,
    isLoading,
    isAuthenticated,
    logout: logoutAndPurgePersistor,
    loginWithRedirect,
    user: user as SvixUser | undefined,
  };
}

function useSvixAuthOIDC() {
  const {
    isLoading: isOIDCLoading,
    isAuthenticated: isOIDCAuthenticated,
    signinRedirect,
    signoutRedirect,
    user: OIDCUser,
    error: OIDCError,
  } = useOIDCAuth();

  // Clear redux-persist data on log out
  // to avoid trying to rehydrate bad data
  async function logoutAndPurgePersistor() {
    try {
      window.analytics!.reset();
    } catch (e) {
      logError(e);
    }
    await persistor.purge();
    signoutRedirect({
      // Required to work with Auth0, and not automatically added by oidc-client-ts
      // https://auth0.com/docs/api/authentication#auth0-logout
      extraQueryParams: {
        returnTo: `${window.location.origin}/admin`,
        client_id: C.envConfig.oidc?.client_id ?? "",
      },
    });
  }

  return {
    error: OIDCError,
    isLoading: isOIDCLoading,
    isAuthenticated: isOIDCAuthenticated,
    logout: logoutAndPurgePersistor,
    loginWithRedirect: signinRedirect,
    user: OIDCUser?.profile as SvixUser | undefined,
  };
}

export function getEnvTag(env: OrganizationOut | null): string {
  if (env?.orgEnv === "Prod") {
    return "Production";
  }
  return "Development";
}

export function genericCmp(a: any, b: any, direction: 1 | -1 = 1): number {
  if (a < b) {
    return 1 * direction;
  } else if (a > b) {
    return -1 * direction;
  } else {
    return 0;
  }
}

export const isEE = import.meta.env.MODE === "ee";
