import {
  ButtonGroup,
  Box,
  Button,
  Collapse,
  Text,
  Stack,
  Tooltip,
} from "@chakra-ui/react";
import { UseFormReturn } from "react-hook-form";

import Select from "@svix/common/widgets/form/Select";

interface BorderRadiusPickerProps {
  name: string;
  label: string;
  formCtx: UseFormReturn<any>; // FIXME: Add stricter typing
}

const BorderRadiusPicker = (props: BorderRadiusPickerProps) => {
  const { name, label, formCtx } = props;
  const { control, setValue, watch } = formCtx;
  const value = watch(name);

  const roundedBorderRadiusOptions = [
    {
      label: "Small",
      value: "sm",
    },
    {
      label: "Medium",
      value: "md",
    },
    {
      label: "Large",
      value: "lg",
    },
  ];
  const isRoundedCorner =
    roundedBorderRadiusOptions.map((o) => o.value).includes(value) || !value;

  return (
    <Stack>
      <Text fontSize="md" fontWeight="medium">
        {label}
      </Text>
      <ButtonGroup isAttached size="sm" variant="outline" w="100%">
        <Tooltip label="Squared" hasArrow>
          <Button
            py={5}
            onClick={() => setValue(name, "none")}
            variant={value === "none" ? "filterGroupActive" : "filterGroupInactive"}
            w="100%"
          >
            <BorderIcon borderRadius={0} />
          </Button>
        </Tooltip>
        <Tooltip label="Rounded" hasArrow>
          <Button
            py={5}
            onClick={() => setValue(name, "md")}
            variant={isRoundedCorner ? "filterGroupActive" : "filterGroupInactive"}
            w="100%"
          >
            <BorderIcon borderRadius={6} />
          </Button>
        </Tooltip>
        <Tooltip label="Pill-shaped" hasArrow>
          <Button
            py={5}
            onClick={() => setValue(name, "full")}
            variant={value === "full" ? "filterGroupActive" : "filterGroupInactive"}
            w="100%"
          >
            <BorderIcon borderRadius={12} />
          </Button>
        </Tooltip>
      </ButtonGroup>
      <Collapse in={isRoundedCorner} animateOpacity>
        {isRoundedCorner && (
          <Select
            maxW="24em"
            control={control}
            name={name}
            label="Border radius"
            placeholder="Default"
          >
            {roundedBorderRadiusOptions.map((option) => (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        )}
      </Collapse>
    </Stack>
  );
};

const BorderIcon = (props: { borderRadius: number }) => {
  return (
    <Box
      border="2px solid"
      borderColor="gray.700"
      height={5}
      width={5}
      borderLeftRadius={props.borderRadius}
      borderRightStyle="dashed"
    />
  );
};

export default BorderRadiusPicker;
