import * as React from "react";
import { Flex, IconButton, Input, Select, useBoolean } from "@chakra-ui/react";
import { ArrowRight } from "@material-ui/icons";
import produce, { Draft } from "immer";

import { JSONSchema7, JSONSchema7TypeName } from "@svix/common/widgets/JsonSchema/types";

import SchemaObject from "./SchemaObject";
import { UI_LEVEL_PX_OFFSET } from "./utils";

export interface ISchemaArrayProps {
  definitions: JSONSchema7["definitions"];
  level: number;
  schema: JSONSchema7;
  setSchema: (mutations: (draft: Draft<JSONSchema7>) => void) => void;
}

const SCHEMA_TYPES = ["string", "number", "array", "object", "boolean", "integer"];

export default function SchemaArray(props: ISchemaArrayProps) {
  const { definitions, level, schema, setSchema } = props;
  const [isExpanded, setExpanded] = useBoolean(true);

  const itemSchema = (schema.items ?? {}) as JSONSchema7;
  const setItemSchema = (mutations: (draft: Draft<JSONSchema7>) => void) => {
    setSchema((draft) => {
      draft.items = produce(itemSchema, mutations);
    });
  };

  const offsetPx = `${level * UI_LEVEL_PX_OFFSET}px`;

  return (
    <Flex flexDir="column" data-schema-level={level} w="100%">
      <Flex
        alignItems="center"
        direction="row"
        wrap="nowrap"
        className="schema-item"
        w={`calc(100% - ${offsetPx})`}
        ml={offsetPx}
        my={1}
        sx={{ gap: 12 }}
      >
        {itemSchema.type === "object" && (
          <IconButton
            aria-label="toggle expand"
            variant="ghost"
            size="sm"
            color="gray.600"
            position="absolute"
            left={`${level * UI_LEVEL_PX_OFFSET - 24}px`}
            onClick={setExpanded.toggle}
          >
            <ArrowRight
              fontSize="small"
              style={{ transform: isExpanded ? "rotate(90deg)" : "rotate(0deg)" }}
            />
          </IconButton>
        )}
        <Input
          isReadOnly
          isDisabled
          name="schema-array-property-name"
          size="sm"
          variant="filled"
          value="Items"
          flexShrink={0}
          w={`calc(18em - ${offsetPx})`}
        />
        <Select
          name="schema-array-datatype"
          isDisabled={false}
          variant="filled"
          size="sm"
          flexShrink={0}
          placeholder="Choose data type"
          value={itemSchema.type}
          onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
            setItemSchema((draft) => {
              draft.items = undefined;
              draft.required = undefined;
              draft.type = evt.target.value as JSONSchema7TypeName;

              if (draft.properties) {
                draft.properties = {};
              }
            });
          }}
          w="15em"
        >
          {SCHEMA_TYPES.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </Select>
        <Input
          name="schema-array-title"
          value={itemSchema.title || ""}
          size="sm"
          variant="filled"
          placeholder="Add Title"
          flexShrink={1}
          onChange={(evt) => {
            setItemSchema((draft) => {
              draft.title = evt.target.value;
            });
          }}
        />
        <Flex flexShrink={0} w="3.5em" />
      </Flex>

      {isExpanded && (
        <>
          {itemSchema.type === "object" && (
            <SchemaObject
              level={level + 1}
              schema={itemSchema}
              setSchema={setItemSchema}
              definitions={definitions}
            />
          )}
          {itemSchema.type === "array" && (
            <SchemaArray
              level={level + 1}
              schema={itemSchema}
              setSchema={setItemSchema}
              definitions={definitions}
            />
          )}
        </>
      )}
    </Flex>
  );
}
