import { useAppSelector } from "src/hooks/store";

export function useActiveEnv() {
  const { activeEnvId } = useAppSelector((state) => state.auth);

  const envs = useAppSelector((state) => state.auth.environments);
  return envs.find((env) => env.orgId === activeEnvId) ?? null;
}

export function useIsTestMode() {
  const activeEnv = useActiveEnv();
  return activeEnv?.orgEnv === "Test";
}

export function useIsAdmin() {
  const { role } = useAppSelector((state) => state.auth);
  return role === "Admin";
}

export function useIsMemberOrAdmin() {
  const { role } = useAppSelector((state) => state.auth);
  return role === "Admin" || role === "Member";
}

export function useRegion() {
  const activeEnv = useActiveEnv();
  return activeEnv?.region ?? "eu";
}
