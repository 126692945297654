import * as React from "react";
import {
  Input,
  InputGroup,
  InputLeftAddon,
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputProps,
  Tooltip,
} from "@chakra-ui/react";
import { Info } from "@material-ui/icons";
import { useController } from "react-hook-form";

export interface ISwatchFieldProps extends InputProps {
  control: any;
  name: string;
  label: React.ReactNode | string;
  helpText?: string;
}

export default function SwatchField({
  control,
  placeholder,
  name,
  label,
  isRequired,
  helpText,
}: ISwatchFieldProps) {
  const {
    field: { value, ref, ...controlProps },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules: { required: isRequired },
  });

  return (
    <FormControl id={name} isInvalid={invalid}>
      <FormLabel whiteSpace="nowrap">
        {label}
        {helpText && (
          <Tooltip placement="right" label={helpText}>
            <Info
              style={{
                marginBottom: "0.2rem",
                marginLeft: "0.4rem",
                fontSize: "1rem",
                opacity: 0.2,
              }}
            />
          </Tooltip>
        )}
      </FormLabel>
      <InputGroup>
        <InputLeftAddon
          overflow="hidden"
          m={0}
          p={0}
          borderRight="1px solid"
          borderRightColor="background.modifier.border"
        >
          <FormLabel
            p={0}
            m={0}
            cursor="pointer"
            style={{
              backgroundColor: value || placeholder,
            }}
          >
            <Input
              border="none"
              outline="none"
              m={0}
              p={0}
              w="40px"
              h="40px"
              type="color"
              visibility="hidden"
              value={value ?? ""}
              {...controlProps}
            />
          </FormLabel>
        </InputLeftAddon>
        <Input
          border="1px solid"
          borderColor="background.modifier.border"
          marginLeft="-1px"
          placeholder={placeholder}
          variant="filled"
          type="text"
          value={value ?? ""}
          {...controlProps}
        />
      </InputGroup>
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
}
