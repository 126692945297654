import { Text } from "@chakra-ui/react";
import WidgetsIcon from "@material-ui/icons/Widgets";
import { useQuery } from "react-query";

import Link from "@svix/common/widgets/Link";

import { getApiConfiguration } from "src/api";
import { routeResolver } from "src/App";
import { OrganizationSettingsApi } from "src/generated/dashboard-openapi";
import { useAppSelector } from "src/hooks/store";
import GettingStartedCard from "../GettingStartedCard";

export default function CustomizeCard() {
  const activeEnvId = useAppSelector((store) => store.auth.activeEnvId);
  const { data: orgSettings } = useQuery(
    ["environments", activeEnvId, "orgSettings"],
    async () => {
      const config = await getApiConfiguration();
      const orgSettingsApi = new OrganizationSettingsApi(config);
      return orgSettingsApi.settingsGetOrganizationSettingsGet();
    }
  );

  return (
    <GettingStartedCard
      done={!!orgSettings}
      icon={<WidgetsIcon style={{ fontSize: 24 }} />}
      title="Customize your Consumer App Portal"
      cta={
        <Link to={routeResolver.getRoute("settings.organization.whiteLabel")}>
          Configure Consumer App Portal
        </Link>
      }
    >
      <Text variant="caption">
        Your Consumer App Portal is the window you provide your customers to view and
        manage their webhooks. You can white-label the portal so that the design matches
        your company's aesthetic.
      </Text>
    </GettingStartedCard>
  );
}
