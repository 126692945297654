import { Stripe } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";

import * as C from "@svix/common/constants";

let stripePromise: Promise<Stripe | null> | undefined;
export const getStripe = () => {
  if (stripePromise === undefined) {
    stripePromise = loadStripe(C.envConfig.stripe.publishableKey);
  }

  return stripePromise;
};
