import { SvixRegion } from "@svix/common/constants";

import { ReactComponent as EuFlag } from "src/img/flags/eu.inline.svg";
import { ReactComponent as InFlag } from "src/img/flags/in.inline.svg";
import { ReactComponent as UsFlag } from "src/img/flags/us.inline.svg";

interface IRegionConfig {
  shortLabel: string;
  label: string;
  flag: React.FunctionComponent;
}

export const regions: Record<SvixRegion, IRegionConfig> = Object.freeze({
  eu: {
    label: "Europe",
    shortLabel: "EU",
    flag: EuFlag,
  },
  us: {
    label: "United States",
    shortLabel: "US",
    flag: UsFlag,
  },
  in: {
    label: "India",
    shortLabel: "IN",
    flag: InFlag,
  },
  "self-hosted": {
    label: "Self-hosted",
    shortLabel: "SH",
    flag: () => <></>, // No flag for self-hosted, for now
  },
});

export function getRegionConfig(region?: SvixRegion): IRegionConfig {
  return regions[region ?? "eu"];
}

export const loginFailedCode = "login_failed_account_exists";
