import { Flex, Button } from "@chakra-ui/react";
import { Add } from "@material-ui/icons";
import { Link } from "react-router-dom";

import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
} from "@svix/common/widgets/PageToolbar";

import { routeResolver } from "src/App";

export default function Toolbar() {
  return (
    <PageToolbar>
      <Breadcrumbs>
        <BreadcrumbItem>Consumer Applications</BreadcrumbItem>
      </Breadcrumbs>
      <Flex flexGrow={1} />
      <Button
        colorScheme="brand"
        as={Link}
        leftIcon={<Add />}
        to={routeResolver.getRoute("applications.new")}
        size="sm"
      >
        Add Application
      </Button>
    </PageToolbar>
  );
}
