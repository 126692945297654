import { useState } from "react";
import {
  Button,
  Heading,
  Box,
  SkeletonText,
  Th,
  Tbody,
  Thead,
  Tr,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  Icon,
  Text,
  Divider,
} from "@chakra-ui/react";
import { ExpandMore } from "@material-ui/icons";

import usePagination from "@svix/common/hooks/pagination";
import Table from "@svix/common/widgets/Table";
import TableCell from "@svix/common/widgets/TableCell";

import { getApiConfiguration } from "src/api";
import { getRegionConfig } from "src/constants";
import { OrganizationApi } from "src/generated/dashboard-openapi";
import { OrganizationUserOut } from "src/generated/dashboard-openapi/models/OrganizationUserOut";
import { useAppSelector } from "src/hooks/store";
import { getEnvTag } from "src/utils";
import MemberRow from "./MemberRow";

export default function MembersTable() {
  const [members, membersCtx] = usePagination("members", async () => {
    const config = await getApiConfiguration();
    const orgApi = new OrganizationApi(config);
    return orgApi.listUsersOrganizationUsersGet();
  });
  const envs = useAppSelector((state) => state.auth.environments);
  const [envFilter, setEnvFilter] = useState<string>("all");
  const selectedEnv = envs.find((env) => env.orgId === envFilter);

  return (
    <Box mt={2}>
      <Heading as="h2" size="sm" mb={4}>
        <HStack justifyContent="space-between">
          <p>Members</p>
          <Menu>
            <MenuButton as={Button} rightIcon={<ExpandMore />}>
              {!selectedEnv && "All environments"}
              {selectedEnv && (
                <HStack>
                  <Icon boxSize={4} as={getRegionConfig(selectedEnv!.region).flag} />
                  <Text>{selectedEnv!.orgName}</Text>
                </HStack>
              )}
            </MenuButton>
            <MenuList>
              <MenuOptionGroup type="radio" value={envFilter} defaultValue="all">
                <MenuItemOption value="all" onClick={() => setEnvFilter("all")}>
                  <Text fontWeight="semibold" py={1}>
                    All environments
                  </Text>
                </MenuItemOption>
                <Divider my={1} />
                {envs.map((env) => (
                  <MenuItemOption
                    key={env.orgId}
                    value={env.orgId}
                    onClick={() => {
                      setEnvFilter(env.orgId);
                    }}
                  >
                    {env.orgName}
                    <Text
                      variant="caption"
                      fontSize="xs"
                      alignItems="center"
                      display="flex"
                    >
                      <Icon boxSize={4} as={getRegionConfig(env.region).flag} mr={2} />
                      {getEnvTag(env)}
                    </Text>
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
            </MenuList>
          </Menu>
        </HStack>
      </Heading>
      <Table response={members} requestElems={membersCtx} data-cy="members-table">
        <Thead>
          <Tr>
            <Th>Email</Th>
            <Th>{selectedEnv ? "Role" : "Roles"}</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {members?.data
            .filter(memberEnvFilter(selectedEnv?.orgId))
            .map((member: OrganizationUserOut) => (
              <MemberRow
                key={member.userId}
                member={member}
                filterByOrgId={selectedEnv?.orgId}
              />
            ))}
          {membersCtx.isLoading && (
            <Tr>
              <TableCell>
                <SkeletonText noOfLines={1} />
              </TableCell>
              <TableCell>
                <SkeletonText noOfLines={1} />
              </TableCell>
              <TableCell>
                <SkeletonText noOfLines={1} />
              </TableCell>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  );
}

const memberEnvFilter = (orgId?: string) => {
  if (!orgId) {
    return () => true;
  }

  return (member: OrganizationUserOut) => {
    const override = member.roleOverrides.find((o) => o.org === orgId);
    if (override) {
      return override.role !== "NoAccess";
    } else {
      return member.orgGroupRole !== "NoAccess";
    }
  };
};
