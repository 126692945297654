import { lazy, useRef, useState, useEffect, Suspense } from "react";
import {
  Box,
  Grid,
  Heading,
  HStack,
  Text,
  Collapse,
  useBoolean,
  UnorderedList,
  ListItem,
  SkeletonText,
  IconButton,
  Tag,
  Tooltip,
} from "@chakra-ui/react";
import Replay from "@material-ui/icons/Replay";
import Ajv, { ErrorObject } from "ajv";
import addFormats from "ajv-formats";
import { Draft } from "immer";

import Button from "@svix/common/widgets/Button";
import { Lang } from "@svix/common/widgets/form/CodeEditor/Lang";
import { getSampleCodeForSchema } from "@svix/common/widgets/JsonSchema/SchemaPreviewer/utils";
import { JSONSchema7 } from "@svix/common/widgets/JsonSchema/types";

const CodeEditor = lazy(() => import("@svix/common/widgets/form/CodeEditor"));

export interface ISchemaToolbarProps {
  schema: JSONSchema7;
  setSchema: (mutations: (draft: Draft<JSONSchema7>) => void) => void;
}

const ajv = new Ajv({ strictTypes: false, addUsedSchema: false, strictSchema: false });
addFormats(ajv);

function validateExample(schema: JSONSchema7, example: string): [boolean, ErrorObject[]] {
  if (example === "") {
    return [true, []];
  }

  let parsedExample;
  try {
    parsedExample = JSON.parse(example);
  } catch (err) {
    return [false, []];
  }

  try {
    const validateSchema = ajv.compile(schema);
    const isValid = validateSchema(parsedExample);
    return [isValid, validateSchema.errors ?? []];
  } catch (error) {
    // Schema is invalid
    return [false, []];
  }
}

function schemaHasExample(schema: JSONSchema7): boolean {
  return Array.isArray(schema.examples) && schema.examples.length > 0;
}

function getExampleStr(schema: JSONSchema7): string {
  const example = schema.examples?.[0];
  if (example !== undefined) {
    try {
      return JSON.stringify(example, null, 2);
    } catch (error) {
      // could not stringify JSON
    }
  }
  return "{}";
}

export default function ExampleEditor(props: ISchemaToolbarProps) {
  const { schema, setSchema } = props;

  const editorFocused = useRef(false);
  const [showExample, setShowExample] = useBoolean(schemaHasExample(schema));
  const [exampleStr, setExampleStr] = useState(getExampleStr(schema));
  const [isExampleValid, setExampleValid] = useState(false);
  const [validationErrors, setValidationErrors] = useState<ErrorObject[]>([]);

  const saveExample = (value: string) => {
    let parsedExample: any;
    try {
      parsedExample = JSON.parse(value);
    } catch (err) {
      if (value) {
        return;
      }
    }
    if (value && !parsedExample) {
      // Don't save if the value is unparsable
      return;
    }

    setSchema((draft) => {
      draft.examples = parsedExample ? [parsedExample] : undefined;
    });
  };

  const runValidation = (value: string) => {
    const [isValid, validationErrors] = validateExample(schema, value);
    setExampleValid(isValid);
    setValidationErrors(validationErrors);
  };

  const handleChange = (newValue: string | undefined) => {
    const value = newValue ?? "";
    setExampleStr(value);
    runValidation(value);
    saveExample(value);
  };

  useEffect(() => {
    if (!editorFocused.current) {
      // rerun validation in case schema change made example go from invalid -> valid or valid -> invalid
      runValidation(exampleStr);
    }
  }, [schema.properties, schema.items]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!editorFocused.current) {
      // If schema.examples changed externally (user modified schema via 'code' tab), sync
      // the `exampleStr` back up with the schema.
      if (schema.examples) {
        try {
          const newValue = getExampleStr(schema);
          setExampleStr(newValue);
          runValidation(newValue);
        } catch (err) {
          // Could not stringify example
        }
      } else {
        setExampleValid(true);
        setValidationErrors([]);
      }
    }
  }, [schema.examples]); // eslint-disable-line react-hooks/exhaustive-deps

  const configureExample = () => {
    const exampleSchema = getSampleCodeForSchema(
      {
        ...schema,
        examples: [], // We're regenerating the example, so ignore the existing ones in the schema
      },
      schema.definitions
    );
    handleChange(JSON.stringify(exampleSchema, null, 2));
    setShowExample.on();
  };

  return (
    <>
      <Box mt={4}>
        <Heading as="h4" size="sm">
          Custom Example
        </Heading>

        {!schema.examples && !showExample && (
          <>
            <Text variant="caption" mt={3}>
              <strong>No example configured.</strong>
            </Text>
            <Text mt={1} variant="caption">
              It is recommended that you configure your own custom example that reflects a
              typical event your users might see.
            </Text>
            <Text mt={1} variant="caption">
              Your users will be able to use this example to test their endpoint in the
              App Portal.
            </Text>
            <HStack mt={4}>
              <Button
                size="sm"
                type="button"
                colorScheme="gray"
                onClick={configureExample}
              >
                Configure example
              </Button>
              <Tag colorScheme="green">Recommended</Tag>
            </HStack>
          </>
        )}
      </Box>

      <Collapse in={showExample}>
        <Grid gridTemplateColumns="minmax(0, 2fr) 1fr" gap={4}>
          <Box
            position="relative"
            my={4}
            py={4}
            border="1px solid"
            bg="background.secondary"
            borderColor="background.modifier.border"
            borderRadius="lg"
          >
            <Suspense fallback={<SkeletonText noOfLines={2} />}>
              <CodeEditor
                lang={Lang.Json}
                value={exampleStr}
                onChange={handleChange}
                onFocus={() => (editorFocused.current = true)}
                onBlur={() => (editorFocused.current = false)}
              />
            </Suspense>
            <Tooltip label="Regenerate" openDelay={250}>
              <IconButton
                mr={2}
                size="sm"
                aria-label="Refresh"
                variant="toolbar"
                onClick={configureExample}
                position="absolute"
                top="0.8em"
                right="0.5em"
              >
                <Replay style={{ fontSize: 16 }} />
              </IconButton>
            </Tooltip>
          </Box>
          {!isExampleValid && (
            <Box
              bgColor="background.danger"
              p={4}
              my={4}
              status="error"
              borderRadius="lg"
              border="1px solid"
              borderColor="background.modifier.border"
            >
              <Heading my={2} color="text.danger" as="h4" size="xs">
                Example invalid
              </Heading>
              <Text variant="caption" fontSize="sm">
                The example must match the shape of the schema.
              </Text>
              <UnorderedList my={2}>
                {validationErrors.map((error) => (
                  <ListItem fontSize="sm" key={error.schemaPath}>
                    <strong>{error.schemaPath}</strong>
                    <Text>{error.message}</Text>
                  </ListItem>
                ))}
              </UnorderedList>
            </Box>
          )}
        </Grid>
      </Collapse>
    </>
  );
}
