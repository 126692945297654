import { Text } from "@chakra-ui/react";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import { useQuery } from "react-query";

import Link from "@svix/common/widgets/Link";

import { getApiConfiguration } from "src/api";
import { routeResolver } from "src/App";
import { InvitationApi } from "src/generated/dashboard-openapi";
import { useSvixAuth } from "src/utils";
import GettingStartedCard from "../GettingStartedCard";

export default function MembersCard() {
  const { user } = useSvixAuth();
  const { data: invites } = useQuery(["outgoingInvites", "gettingStarted"], async () => {
    const config = await getApiConfiguration();
    const inviteApi = new InvitationApi(config);
    return inviteApi.listOrgGroupInvitesInviteOrgGroupGet(undefined, 2);
  });

  const hasInvitedUser =
    invites?.data &&
    invites.data.filter((inv) => inv.inviteEmail !== user?.email).length > 0;

  return (
    <GettingStartedCard
      done={hasInvitedUser}
      icon={<GroupAddIcon style={{ fontSize: 24 }} />}
      title="Invite your team"
      cta={
        <Link to={routeResolver.getRoute("settings.organizationGroup.members")}>
          Invite Members
        </Link>
      }
    >
      <Text variant="caption">
        Add team members to your Svix organization so everyone has access to manage
        webhooks across all environments.
      </Text>
    </GettingStartedCard>
  );
}
