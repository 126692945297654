import * as React from "react";
import { Td, TableCellProps, Text, Tooltip, TextProps } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export interface ITableCellProps extends TableCellProps {
  isCondensed?: boolean;
  mono?: boolean;
  to?: string;
  tooltip?: React.ReactNode | string;
}

const TableCell = React.forwardRef((props: ITableCellProps, ref: any) => {
  const { children, isCondensed, isTruncated, mono, to, tooltip, ...rest } = props;
  const verticalSpacing = isCondensed ? [1, 1, 2] : [2, 2, 4];

  const sharedProps = {
    children,
    _hover: { textDecoration: "none" },
    display: "block",
    fontFamily: mono ? "mono" : "body",
    isTruncated,
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    ...(tooltip && {
      textUnderlineOffset: "0.2em",
      textDecorationLine: "underline",
      textDecorationStyle: "dotted",
      textDecorationColor: "brand.500",
    }),
  } as TextProps;

  if (to) {
    return (
      <Td {...rest}>
        <Tooltip label={tooltip}>
          <Text as={Link} {...sharedProps} to={to} ref={ref} py={verticalSpacing} />
        </Tooltip>
      </Td>
    );
  }

  return (
    <Td {...rest}>
      <Tooltip label={tooltip}>
        <Text as="div" {...sharedProps} ref={ref} my={verticalSpacing} />
      </Tooltip>
    </Td>
  );
});

export default TableCell;
