import { useEffect } from "react";
import { Alert, AlertIcon, Box, Heading, Text } from "@chakra-ui/react";
import { Location } from "history";
import { Redirect, useLocation, useParams } from "react-router-dom";

import * as C from "@svix/common/constants";
import { logError } from "@svix/common/logger";
import Button from "@svix/common/widgets/Button";
import Card from "@svix/common/widgets/Card";
import EmailLink from "@svix/common/widgets/EmailLink";
import LoadingIndicator from "@svix/common/widgets/LoadingIndicator";

import { persistor } from "src/store";
import svixLogo from "../logo.svg";
import { isEE, useSvixAuth } from "../utils";

interface LocationWithState {
  from?: Location;
}

export default function AuthScreen(props: { isSignup?: boolean }) {
  const location = useLocation<LocationWithState | undefined>();
  const { error, logout, loginWithRedirect, isAuthenticated, isLoading } = useSvixAuth();
  const from = location.state?.from || { pathname: "/" };
  // Enterprise customers can have custom Identity Providers configured in our Auth0.
  // Set this to the name of the connection to use to adjust the auth flow.
  const { auth0ConnectionName } = useParams<{ auth0ConnectionName?: string }>();
  useEffect(() => {
    if (error) {
      const hasTimeoutError = error.message.toLowerCase().includes("timeout");
      const context = hasTimeoutError
        ? "Auth0 timed out"
        : "Auth0 failed to authenticate user";
      logError(error, context);
    }
  }, [error]);

  useEffect(() => {
    if (!isAuthenticated && !isLoading && !error) {
      // Clear redux-persist data to avoid rehydrating data from an expired session
      persistor.purge().then(() => {
        loginWithRedirect({
          authorizationParams: {
            connection: auth0ConnectionName,
            redirect_uri: isEE
              ? `${window.location.origin}/admin/login`
              : `${window.location.origin}/login`,
            action: props.isSignup ? "signup" : undefined,
            screen_hint: props.isSignup ? "signup" : "login", // for New Universal Login
          },
          appState: {
            returnTo: from.pathname,
            ...from,
          },
        });
      });
    }
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isAuthenticated) {
    return <Redirect to={from} />;
  }

  if (isLoading) {
    return <LoadingIndicator style={{ display: "block", margin: "40px auto" }} />;
  }

  if (error) {
    return (
      <Card shadow="xl" borderRadius="2xl" mx="auto" mt="25vh" p={4} maxW="32em">
        <Box textAlign="center">
          <img
            src={svixLogo}
            alt="Logo"
            style={{ maxWidth: "72px", marginLeft: "auto", marginRight: "auto" }}
          />
          <Heading as="h2" size="lg" my={4}>
            Svix
          </Heading>
        </Box>
        <Alert fontSize="md" borderRadius="lg" status="error">
          <AlertIcon />
          There was an issue logging you into your account. Try refreshing your browser.
        </Alert>
        <Box my={6}>
          <Text fontSize="md" mt={2}>
            If the issue persists, reach out to the team at{" "}
            <EmailLink
              fontWeight="medium"
              color="brand.500"
              email={C.supportEmail}
              textDecoration="underline"
            >
              {C.supportEmail}
            </EmailLink>
            .
          </Text>
        </Box>
        <Box textAlign="center">
          <Button
            colorScheme="gray"
            onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
          >
            Back to Login
          </Button>
        </Box>
      </Card>
    );
  }

  return <></>;
}
