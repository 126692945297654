import { useState } from "react";
import {
  Box,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Tooltip,
  Text,
  useToast,
} from "@chakra-ui/react";
import { ArrowForward } from "@material-ui/icons";

import * as C from "@svix/common/constants";
import { useInterval } from "@svix/common/hooks/interval";
import { getSvixPlayRequestUrl } from "@svix/common/play";
import Button from "@svix/common/widgets/Button";
import CodeBlock from "@svix/common/widgets/Code";

import { getSvix } from "src/api";
import { useRegion } from "src/store/selectors";
import { IOnboardingStepProps } from "./types";

const REFRESH_INTERVAL_MS = 2000;

export default function ApplicationStep(props: IOnboardingStepProps) {
  const toast = useToast();
  const region = useRegion();
  const { orgKey, playToken, stepper } = props;

  const [uid] = useState((Math.random() + 1).toString(36).substring(8));

  const CREATE_APPLICATION_CODE_BLOCK = `\
curl -X POST "${C.envConfig.getServerUrl(region)}/api/v1/app/" \\
    -H  "Accept: application/json" \\
    -H  "Content-Type: application/json" \\
    -H  "Authorization: Bearer ${orgKey}" \\
    -d '{"name": "My first app", "uid": "${uid}"}'\
`;

  useInterval(async () => {
    const api = await getSvix();
    const appUid = uid;
    try {
      await api.application.get(appUid);
    } catch (e) {
      // Skip if the app doesn't exist yet
      return;
    }

    props.setAppUid(appUid);
    toast({
      title: "New Application created",
      description: `${appUid}`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });

    await api.endpoint.create(appUid, {
      description: "Svix onboarding endpoint",
      url: getSvixPlayRequestUrl(playToken),
      version: 1,
    });

    props.nextStep();
  }, REFRESH_INTERVAL_MS);

  return (
    <>
      {stepper}
      <ModalHeader>Create a Consumer Application</ModalHeader>
      <ModalBody>
        <Text fontSize="md" mb={4}>
          First, you'll first need a <strong>Consumer Application</strong>. A Consumer
          Application defines where your messages are sent. Usually you'll want one
          Application for each of your customers.
        </Text>

        <Text fontSize="md">
          To create one, run the following snippet from your terminal:
        </Text>
        <Box my={4}>
          <CodeBlock
            language="bash"
            code={CREATE_APPLICATION_CODE_BLOCK}
            copyToClipboard
          />
        </Box>
      </ModalBody>
      <ModalFooter>
        <Tooltip
          label="Create an application to continue"
          shouldWrapChildren
          hasArrow
          placement="top"
        >
          <Button isDisabled rightIcon={<ArrowForward />}>
            Continue
          </Button>
        </Tooltip>
      </ModalFooter>
    </>
  );
}
