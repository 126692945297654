import { Stack, Flex, useBoolean, Tooltip } from "@chakra-ui/react";

import Button from "@svix/common/widgets/Button";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
} from "@svix/common/widgets/PageToolbar";

import { routeResolver } from "src/App";
import { useIsAdmin } from "src/store/selectors";
import InviteModal from "./InviteModal";
import InvitesTable from "./InvitesTable";
import MembersTable from "./MembersTable";

export default function MembersSettings() {
  const [showInviteModal, setInviteModal] = useBoolean();
  const isAdmin = useIsAdmin();

  return (
    <>
      <MetaTitle path={["Organization Members"]} />
      <PageToolbar>
        <Breadcrumbs>
          <BreadcrumbItem to={routeResolver.getRoute("settings")}>
            Settings
          </BreadcrumbItem>
          <BreadcrumbItem>Organization Members</BreadcrumbItem>
        </Breadcrumbs>
        <Flex flexGrow={1} />
        <Tooltip
          label={!isAdmin && "Only admins can invite additional users"}
          shouldWrapChildren
        >
          <Button size="sm" onClick={setInviteModal.on} isDisabled={!isAdmin}>
            Invite Teammates
          </Button>
        </Tooltip>
      </PageToolbar>
      <Stack spacing={4}>
        <InvitesTable />
        <MembersTable />
      </Stack>
      <InviteModal isOpen={showInviteModal} onClose={setInviteModal.off} />
    </>
  );
}
